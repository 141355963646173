import Axios from 'axios';

export const getStatisticsRequest = async (clientCode, dateFrom, dateTo, pageSize, page) => {
    const response = await Axios.post('/estadisticas/search', {
        clientCode,
        dateFrom,
        dateTo,
        pageSize,
        page,
    });
    return response.data;
};
