import {
    ADD_UPDATE_SEGMENT_SUCCESS,
    ADD_UPDATE_SEGMENT,
    AUTOCOMPLETE_SEGMENTS_SUCCESS,
    AUTOCOMPLETE_SEGMENTS,
    CLEAR_SEGMENTS,
    GET_ACTIVE_SEGMENTS,
    GET_SEGMENT,
    GET_SEGMENTS,
    SEARCH_SEGMENTS_SUCCESS,
    SEARCH_SEGMENTS,
} from '../constants/ActionsTypes';

export const addUpdateSegment = (params) => {
    return {
        type: ADD_UPDATE_SEGMENT,
        payload: { params },
    };
};

export const getSegment = (segmentId) => {
    return {
        type: GET_SEGMENT,
        payload: { segmentId },
    };
};

export const getSegments = () => {
    return {
        type: GET_SEGMENTS,
    };
};

export const getActiveSegments = () => {
    return {
        type: GET_ACTIVE_SEGMENTS,
    };
};

export const searchSegments = (params) => {
    return {
        type: SEARCH_SEGMENTS,
        payload: params,
    };
};

export const searchSegmentsSuccess = (data) => {
    return {
        type: SEARCH_SEGMENTS_SUCCESS,
        payload: data,
    };
};

export const addSegmentSuccess = (data) => {
    return {
        type: ADD_UPDATE_SEGMENT_SUCCESS,
        payload: data,
    };
};

export const autocompleteSegments = (params) => {
    return {
        type: AUTOCOMPLETE_SEGMENTS,
        payload: params,
    };
};

export const autocompleteSegmentsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_SEGMENTS_SUCCESS,
        payload: data,
    };
};

export const clearSegments = () => {
    return {
        type: CLEAR_SEGMENTS,
    };
};
