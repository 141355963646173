import {
  ORDER_REQUEST,
  GET_CURRENT_ORDER,
  ADD_ITEM_TO_ORDER,
  REMOVE_ITEM_FROM_ORDER,
  CHANGE_ITEM_QUANTITY_FROM_ORDER,
  SET_DATA_TO_ORDER,
  SAVE_ORDER,
  CANCEL_ORDER,
  CONFIRM_ORDER,
  CONFIRM_ORDER_AS_ADMIN,
  CONFIRM_ORDER_DIALOG_CLOSED,
  ADD_CLIENT_TO_ORDER,
  ADD_ITEM_TO_ORDER_FROM_CODE,
  ADD_TRANSPORT_TO_ORDER,
  APPLY_DISCOUNTS_TO_PRODUCTS,
  APPLY_DISCOUNTS_TO_PRODUCTS_LOADING,
  GET_ORDER_BY_USER_SUCCESS,
  GET_ORDERS_BY_DATE_SUCCESS,
  GET_ORDER_BY_USER,
  GET_ORDERS_BY_USER_PENDING,
  GET_ORDERS_BY_DATE,
  GET_ORDERS_BY_DATE_PENDING,
  CLEAN_ORDERS_BY_DATE,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_BY_ID,
  ADD_PRODUCTS_TO_CURRENT,
  ADD_PRODUCTS_TO_CURRENT_SUCCESS,
  CHANGE_CURRENT_ORDER,
  CHANGE_CURRENT_ORDER_SUCCESS,
  CLEAN_ORDER,
  GET_REFUND_CODES,
  GET_REFUND_CODES_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  CREATE_CREDIT_NOTE,
  CREATE_CREDIT_NOTE_SUCCESS,
  GET_CN_TYPES,
  GET_CN_TYPES_SUCCESS, SET_PARAMS_SNC, SET_CN_TO_SEE
} from '../constants/ActionsTypes';

export const getCurrentOrder = () => {
    return {
        type: GET_CURRENT_ORDER,
    };
};

export const addItemToOrder = (data) => {
    return {
        type: ORDER_REQUEST,
        meta: ADD_ITEM_TO_ORDER,
        payload: data,
    };
};

export const removeItemFromOrder = (data) => {
    return {
        type: ORDER_REQUEST,
        meta: REMOVE_ITEM_FROM_ORDER,
        payload: data,
    };
};

export const changeItemQuantityFromOrder = (data) => {
    return {
        type: CHANGE_ITEM_QUANTITY_FROM_ORDER,
        payload: data,
    };
};

export const setDataToOrder = (data) => {
    return {
        type: ORDER_REQUEST,
        meta: SET_DATA_TO_ORDER,
        payload: data,
    };
};

export const saveOrder = (data) => {
    return {
        type: SAVE_ORDER,
        payload: data,
    };
};

export const cancelOrder = (data) => {
    return {
        type: CANCEL_ORDER,
        payload: data,
    };
};

export const confirmOrder = (data) => {
    return {
        type: ORDER_REQUEST,
        meta: CONFIRM_ORDER,
        payload: data,
    };
};

export const confirmOrderAsAdmin = (data) => {
    return {
        type: ORDER_REQUEST,
        meta: CONFIRM_ORDER_AS_ADMIN,
        payload: data,
    };
};

export const closeConfirmationCompleteDialog = () => {
    return {
        type: CONFIRM_ORDER_DIALOG_CLOSED,
    };
};

export const addClientToOrder = (data) => {
    return {
        type: ADD_CLIENT_TO_ORDER,
        payload: data,
    };
};

export const addItemToOrderFromCode = (data) => {
    return {
        type: ADD_ITEM_TO_ORDER_FROM_CODE,
        payload: data,
    };
};

export const addTransportToOrder = (data) => {
    return {
        type: ADD_TRANSPORT_TO_ORDER,
        payload: data,
    };
};

export const applyDiscountsToProducts = (data) => {
  return {
    type: APPLY_DISCOUNTS_TO_PRODUCTS,
    payload: data
  };
};

export const startLoadingApplyDiscounts = () => ({
  type: APPLY_DISCOUNTS_TO_PRODUCTS_LOADING
});

export const getOrdersByUser = (params) => {
    return {
        type: GET_ORDER_BY_USER,
        payload: params,
    };
};

export const getOrdersByUserSuccess = (params) => {
    return {
        type: GET_ORDER_BY_USER_SUCCESS,
        payload: params,
    };
};

export const getOrdersByUserPending = () => {
  return {
    type: GET_ORDERS_BY_USER_PENDING,
  };
};

export const getOrdersByDate = (params) => {
  return {
    type: GET_ORDERS_BY_DATE,
    payload: params
  };
};

export const cleanOrdersByDate = (params) => {
  return {
    type: CLEAN_ORDERS_BY_DATE,
    payload: params
  };
};

export const getOrdersByDatePending = () => {
  return {
    type: GET_ORDERS_BY_DATE_PENDING,
  };
};

export const getOrdersByDateSuccess = (params) => {
  return {
    type: GET_ORDERS_BY_DATE_SUCCESS,
    payload: params
  };
};

export const getOrderById = (params) => {
    return {
        type: GET_ORDER_BY_ID,
        payload: params,
    };
};

export const getOrderByIdSuccess = (params) => {
    return {
        type: GET_ORDER_BY_ID_SUCCESS,
        payload: params,
    };
};

export const addProductsToCurrent = (params) => {
    return {
        type: ADD_PRODUCTS_TO_CURRENT,
        payload: params,
    };
};

export const addProductsToCurrentSuccess = (response) => {
    return {
        type: ADD_PRODUCTS_TO_CURRENT_SUCCESS,
        payload: response.data,
    };
};

export const changeCurrentOrder = (params) => {
    return {
        type: CHANGE_CURRENT_ORDER,
        payload: params,
    };
};

export const changeCurrentOrderSuccess = (response) => {
    return {
        type: CHANGE_CURRENT_ORDER_SUCCESS,
        payload: response.data,
    };
};

export const cleanOrders = () => {
    return {
        type: CLEAN_ORDER,
    };
};

export const getRefundCodes = () => {
    return {
        type: GET_REFUND_CODES,
    };
};

export const getRefundCodesSuccess = (data) => {
    return {
        type: GET_REFUND_CODES_SUCCESS,
        payload: data,
    };
};

export const getCNTypes = () => {
    return {
        type: GET_CN_TYPES,
    };
};

export const getCNTypesSuccess = (data) => {
    return {
        type: GET_CN_TYPES_SUCCESS,
        payload: data,
    };
};

export const getInvoices = (params) => {
    return {
        type: GET_INVOICES,
        payload: params,
    };
};

export const getInvoicesSuccess = (data) => {
    return {
        type: GET_INVOICES_SUCCESS,
        payload: data,
    };
};

export const getInvoice = (params) => {
    return {
        type: GET_INVOICE,
        payload: params,
    };
};

export const getInvoiceSuccess = (data) => {
    return {
        type: GET_INVOICE_SUCCESS,
        payload: data,
    };
};

export const createCN = (params) => {
    return {
        type: CREATE_CREDIT_NOTE,
        payload: params,
    };
};

export const createCNSuccess = (data) => {
    return {
        type: CREATE_CREDIT_NOTE_SUCCESS,
        payload: data,
    };
};

export const setParamsSNC = (data) => {
    return {
        type: SET_PARAMS_SNC,
        payload: data,
    };
};
