import {
    AUTOCOMPLETE_PRODUCTS_SUCCESS,
    AUTOCOMPLETE_PRODUCTS,
    CREATE_APPLICATION_FROM_PRODUCT_SUCCESS,
    CREATE_APPLICATION_FROM_PRODUCT,
    DELETE_APPLICATION_FROM_PRODUCT_SUCCESS,
    DELETE_APPLICATION_FROM_PRODUCT,
    EDIT_APPLICATION_FROM_PRODUCT_SUCCESS,
    EDIT_APPLICATION_FROM_PRODUCT,
    GET_APPLICATION_BY_PRODUCT_CODE_LOADING,
    GET_APPLICATIONS_BY_PRODUCT_CODE_SUCCESS,
    GET_APPLICATIONS_BY_PRODUCT_CODE,
    GET_APPLICATIONS_BY_PRODUCT_ID_SUCCESS,
    GET_APPLICATIONS_BY_PRODUCT_ID,
    GET_PRODUCT_BY_CODE,
    GET_PRODUCTS_BY_IDS,
    GET_PRODUCT_PRICE_BY_CLIENT_LOADING,
    GET_PRODUCT_PRICE_CLIENT_ERROR,
    GET_PRODUCT_PRICE_CLIENT_SUCCESS,
    GET_PRODUCT_PRICE_CLIENT,
    GET_PRODUCT,
    RESET_SUCCESS,
    GET_PRODUCTS,
    SEARCH_CATALOG_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS,
    SELECT_MAIN_PICTURE,
    SHOW_ONE_PRODUCT,
    SORT_CHANGE,
    UPDATE_PRODUCT_IMAGES,
    UPDATE_PRODUCT,
} from '../constants/ActionsTypes';

export const getProducts = () => {
    return {
        type: GET_PRODUCTS,
    };
};

export const resetSuccess = () => {
    return {
        type: RESET_SUCCESS,
    };
};

export const getProductById = (productId) => {
    return {
        type: GET_PRODUCT,
        payload: { productId },
    };
};

export const updateProductImages = (images) => {
    return {
        type: UPDATE_PRODUCT_IMAGES,
        images,
    };
};

export const updateProduct = (productId, updatable) => {
    return {
        type: UPDATE_PRODUCT,
        payload: { productId, updatable },
    };
};

export const selectMainPicture = (mainPicture) => {
    return {
        type: SELECT_MAIN_PICTURE,
        mainPicture,
    };
};

export const searchProducts = (params) => {
    return {
        type: SEARCH_PRODUCTS,
        payload: params,
    };
};

export const sortChange = (sort) => {
    return {
        type: SORT_CHANGE,
        sort,
    };
};

export const autocompleteProducts = (params) => {
    return {
        type: AUTOCOMPLETE_PRODUCTS,
        payload: params,
    };
};

export const searchProductsSuccess = (products) => {
    return {
        type: SEARCH_PRODUCTS_SUCCESS,
        payload: products,
    };
};

export const autocompleteProductsSuccess = (products) => {
    return {
        type: AUTOCOMPLETE_PRODUCTS_SUCCESS,
        payload: products,
    };
};

export const searchCatalogProductsSuccess = (products) => {
    return {
        type: SEARCH_CATALOG_PRODUCTS_SUCCESS,
        payload: products,
    };
};

export const showOneProduct = (searchParams) => {
    return {
        type: SHOW_ONE_PRODUCT,
        payload: searchParams,
    };
};

export const getProductByCode = (searchParams) => {
    return {
        type: GET_PRODUCT_BY_CODE,
        payload: searchParams,
    };
};

export const getProductsByIds = (productsIds) => {
    return {
        type: GET_PRODUCTS_BY_IDS,
        payload: productsIds,
    };
};

export const getProductPriceByClient = (searchParams) => {
    return {
        type: GET_PRODUCT_PRICE_CLIENT,
        payload: searchParams,
    };
};

export const startLoadingGetProductPriceByClient = () => ({
    type: GET_PRODUCT_PRICE_BY_CLIENT_LOADING
});

export const getProductPriceByClientSuccess = (response) => {
    return {
        type: GET_PRODUCT_PRICE_CLIENT_SUCCESS,
        payload: response,
    };
};

export const getProductPriceByClientError = (response) => {
    return {
        type: GET_PRODUCT_PRICE_CLIENT_ERROR,
        payload: response,
    };
};

export const getApplicationByProductCode = (searchParams) => {
    return {
        type: GET_APPLICATIONS_BY_PRODUCT_CODE,
        payload: searchParams,
    };
};

export const startLoadingGetApplicationByProductCode = () => ({
    type: GET_APPLICATION_BY_PRODUCT_CODE_LOADING
});

export const getApplicationByProductCodeSuccess = (response) => {
    return {
        type: GET_APPLICATIONS_BY_PRODUCT_CODE_SUCCESS,
        payload: response,
    };
};

export const getApplicationByProductId = (searchParams) => {
    return {
        type: GET_APPLICATIONS_BY_PRODUCT_ID,
        payload: searchParams,
    };
};

export const getApplicationByProductIdSuccess = (response) => {
    return {
        type: GET_APPLICATIONS_BY_PRODUCT_ID_SUCCESS,
        payload: response,
    };
};

export const deleteApplicationFromProduct = (params) => {
    return {
        type: DELETE_APPLICATION_FROM_PRODUCT,
        payload: params,
    };
};

export const deleteApplicationFromProductSuccess = () => {
    return {
        type: DELETE_APPLICATION_FROM_PRODUCT_SUCCESS,
    };
};

export const createApplicationFromProduct = (data) => {
    return {
        type: CREATE_APPLICATION_FROM_PRODUCT,
        payload: data,
    };
};

export const createApplicationFromProductSuccess = () => {
    return {
        type: CREATE_APPLICATION_FROM_PRODUCT_SUCCESS,
    };
};

export const editApplicationFromProduct = (data) => {
    return {
        type: EDIT_APPLICATION_FROM_PRODUCT,
        payload: data,
    };
};

export const editApplicationFromProductSuccess = () => {
    return {
        type: EDIT_APPLICATION_FROM_PRODUCT_SUCCESS,
    };
};
