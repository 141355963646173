import {
    GET_STATISTICS,
    GET_STATISTICS_SUCCESS,
} from '../constants/ActionsTypes';

export const getStatistics = (clientCode, dateFrom, dateTo, pageSize, page) => {
    return {
        type: GET_STATISTICS,
        payload: { clientCode, dateFrom, dateTo, pageSize, page },
    };
};

export const getStatisticsSuccess = (result) => {
    return {
        type: GET_STATISTICS_SUCCESS,
        data: result,
    };
};
