import {
    GET_SUB_GROUP,
    GET_SUB_GROUP_BY_CODE_SUCCESS,
    GET_SUB_GROUP_SUCCESS,
    GET_SUB_GROUP_ERROR,
    SEARCH_SUB_GROUPS,
    SEARCH_SUB_GROUPS_SUCCESS,
    AUTOCOMPLETE_SUB_GROUPS,
    AUTOCOMPLETE_SUB_GROUPS_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
    subGroup: undefined,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SUB_GROUP:
            return { ...state, subGroup: null }        
        case GET_SUB_GROUP_BY_CODE_SUCCESS:
            return { ...state, subGroup: action.data }
        case GET_SUB_GROUP_SUCCESS:
            return { ...state, subGroup: action.data }
        case GET_SUB_GROUP_ERROR:
            return { ...state, subGroup: action }
        case SEARCH_SUB_GROUPS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_SUB_GROUPS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_SUB_GROUPS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_SUB_GROUPS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        default:
            return state
    }
}

export default rootReducer