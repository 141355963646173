import { buffers } from 'redux-saga'
import { all, call, fork, put, takeLatest, throttle, select, take, actionChannel } from 'redux-saga/effects';
import Router from 'next/router'

import {
  GET_CURRENT_QUOTATION,
  GET_CURRENT_QUOTATION_SUCCESS,
  GET_CURRENT_QUOTATION_ERROR,
  ADD_ITEM_QUOTATION_FROM_CODE,
  ADD_ITEM_QUOTATION_FROM_CODE_ERROR,
  CHANGE_ITEM_QUANTITY_QUOTATION,
  CHANGE_ITEM_QUANTITY_QUOTATION_ERROR,
  REMOVE_ITEM_QUOTATION,
  REMOVE_ITEM_QUOTATION_ERROR,
  SET_DATA_QUOTATION,
  SET_DATA_QUOTATION_ERROR,
  ADD_ITEM_QUOTATION,
  SAVE_QUOTATION,  
  CANCEL_QUOTATION,
  SAVE_QUOTATION_ERROR,
  CANCEL_QUOTATION_ERROR,
  NEW_QUOTATION,
  NEW_QUOTATION_ERROR,
  GET_QUOTATIONS_BY_USER,
  GET_QUOTATIONS_BY_USER_ERROR,
  GET_QUOTATION_BY_ID,
  GET_QUOTATION_BY_ID_ERROR,
  ADD_PRODUCTS_CURRENT_QUOTATION,
  CHANGE_CURRENT_QUOTATION,
  
} from '../constants/ActionsTypes';

import {
    getCurrentQuotation,
    addItemQuotation,
    addItemQuotationFromCode,
    changeItemQuantityQuotation,
    removeItemQuotation,
    setDataToQuotation,
    saveQuotation,
    cancelQuotation,
    newQuotation,
    getQuotationsByUser,
    getQuotationById,
    addProductsToCurrent

} from '../api/Quotation';

import { 
    addItemQuotationFromCodeSuccess,
    changeItemQuantityQuotationSuccess,
    removeItemFromQuotationSuccess,
    setDataToQuotationSuccess,
    addItemToQuotationSuccess, 
    saveQuotationSuccess,
    cancelQuotationSuccess,
    newQuotationSuccess,
    getQuotationsByUserSuccess,
    getQuotationByIdSuccess,
    addProductsToCurrentQuotationSuccess
} from '../actions';

import {
  updateElectronQuotation,
} from '../electron/tools'

//Selectors
const getOfflineStatus = (state) => state.catalog.offline
const getCurrentQuotationFromState = (state) => state.quotation.current

function* getCurrentQuotationRequest() {
  
  try {
    // Get current quotation id from LS
    const currentQuotationId = localStorage.getItem('currentQuotationId')

    const response = yield call(getCurrentQuotation, currentQuotationId);
    const { data } = response.data;
    yield put({ type: GET_CURRENT_QUOTATION_SUCCESS, data });
    updateElectronQuotation(data, yield select(getOfflineStatus));

    // Save quotation id to LS
    localStorage.setItem('currentQuotationId', data.id)

  } catch (error) {
    yield put({ type: GET_CURRENT_QUOTATION_ERROR, error });
  }
}

function* addItemQuotationRequest({payload}) {

  const { item } = payload;

  try {

    // Get current quotation
    let currentQuotation = yield select(getCurrentQuotationFromState)
    if (!currentQuotation) {
      yield getCurrentQuotationRequest()
      currentQuotation = yield select(getCurrentQuotationFromState)
    }

    const response = yield call(addItemQuotation, currentQuotation.id, { ...item });      
    yield put(addItemToQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_ITEM_QUOTATION_FROM_CODE_ERROR, error });
  }
}

function* addItemQuotationFromCodeRequest({payload}) {
  try {
    // Get current quotation
    let currentQuotation = yield select(getCurrentQuotationFromState)
    if (!currentQuotation) {
      yield getCurrentQuotationRequest()
      currentQuotation = yield select(getCurrentQuotationFromState)
    }
    const response = yield call(addItemQuotationFromCode, currentQuotation.id , payload);      
    yield put(addItemQuotationFromCodeSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_ITEM_QUOTATION_FROM_CODE_ERROR, error });
  }
}

function* changeItemQuantityQuotationRequest({payload}) {
  try {
    const response = yield call(changeItemQuantityQuotation, payload);      
    yield put(changeItemQuantityQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: CHANGE_ITEM_QUANTITY_QUOTATION_ERROR, error });
  }
}

function* removeItemQuotationRequest({payload}) {
  try {
    const response = yield call(removeItemQuotation, payload);      
    yield put(removeItemFromQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: REMOVE_ITEM_QUOTATION_ERROR, error });
  }
}

function* setDataToQuotationRequest({payload}) {
  try {
    const response = yield call(setDataToQuotation, payload);      
    yield put(setDataToQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: SET_DATA_QUOTATION_ERROR, error });
  }
}

function* saveQuotationRequest({payload}) {
  const { cotizacionId } = payload;
  try {
    const response = yield call(saveQuotation, cotizacionId);      
    yield put(saveQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));

    // Remove current quotation id from LS
    localStorage.removeItem('currentQuotationId');

  } catch (error) {
    yield put({ type: SAVE_QUOTATION_ERROR, error });
  }
}

function* cancelQuotationRequest({payload}) {
  const { cotizacionId } = payload;
  try {
    const response = yield call(cancelQuotation, cotizacionId);      
    yield put(cancelQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));

    // Remove current quotation id from LS
    localStorage.removeItem('currentQuotationId');

  } catch (error) {
    yield put({ type: CANCEL_QUOTATION_ERROR, error });
  }
}

function* newQuotationRequest() {  
  try {

    const response = yield call(newQuotation);      
    yield put(newQuotationSuccess(response));
    const { data } = response.data;
    updateElectronQuotation(data, yield select(getOfflineStatus));

    // Save quotation id to LS
    localStorage.setItem('currentQuotationId', data.id)

  } catch (error) {
    yield put({ type: NEW_QUOTATION_ERROR, error });
  }
}

function* getQuotationsByUserRequest({payload}) {  
  try {
    const response = yield call(getQuotationsByUser, payload);      
    yield put(getQuotationsByUserSuccess(response));      
  } catch (error) {
    yield put({ type: GET_QUOTATIONS_BY_USER_ERROR, error });
  }
}

function* getQuotationByIdRequest({payload}) {  
  try {
    const response = yield call(getQuotationById, payload);      
    yield put(getQuotationByIdSuccess(response));      
  } catch (error) {
    yield put({ type: GET_QUOTATION_BY_ID_ERROR, error });
  }
}

function* addProductsToCurrentRequest({payload}) {  
  try {
    const response = yield call(addProductsToCurrent, payload.quotationId);      
    yield put(addProductsToCurrentQuotationSuccess(response));      
  } catch (error) {
    
  }
}

function* changeCurrentRequest({payload}) {  

  localStorage.setItem('currentQuotationId', payload.id);
  Router.push('/catalog');  
}

export function* getCurrentQuotationSaga() {
  yield takeLatest(GET_CURRENT_QUOTATION, getCurrentQuotationRequest);
}

export function* addItemQuotationFromCodeSaga() {
  // Channel as queue
  const requestChan = yield actionChannel(ADD_ITEM_QUOTATION_FROM_CODE)
  while (true) {
    const action = yield take(requestChan)
    yield call(addItemQuotationFromCodeRequest, action)
  }
}

export function* addItemQuotationSaga() {
  // Channel as queue
  const requestChan = yield actionChannel(ADD_ITEM_QUOTATION)
  while (true) {
    const action = yield take(requestChan)
    yield call(addItemQuotationRequest, action)
  }
}

export function* changeItemQuantityQuotationSaga() {
  // Channel as queue, using buffers.sliding to take latest request
  const requestChan = yield actionChannel(CHANGE_ITEM_QUANTITY_QUOTATION, buffers.sliding(1));

  while (true) {
    const action = yield take(requestChan)
    yield call(changeItemQuantityQuotationRequest, action);
  }
}

export function* removeItemQuotationSaga() {
  // Channel as queue
  const requestChan = yield actionChannel(REMOVE_ITEM_QUOTATION)
  while (true) {
    const action = yield take(requestChan)
    yield call(removeItemQuotationRequest, action)
  }
}

export function* setDataToQuotationSaga() {
  yield takeLatest(SET_DATA_QUOTATION, setDataToQuotationRequest);
}

export function* saveQuotationSaga() {
  yield takeLatest(SAVE_QUOTATION, saveQuotationRequest);
}

export function* cancelQuotationSaga() {
  yield takeLatest(CANCEL_QUOTATION, cancelQuotationRequest);
}

export function* newQuotationSaga() {
  yield takeLatest(NEW_QUOTATION, newQuotationRequest);
}

export function* getQuotationsByUserSaga() {
  yield takeLatest(GET_QUOTATIONS_BY_USER, getQuotationsByUserRequest);
}

export function* getQuotationByIdSaga() {
  yield takeLatest(GET_QUOTATION_BY_ID, getQuotationByIdRequest);
}

export function* addProductsToCurrentSaga() {
  yield takeLatest(ADD_PRODUCTS_CURRENT_QUOTATION, addProductsToCurrentRequest);
}

export function* changeCurrentSaga() {
  yield takeLatest(CHANGE_CURRENT_QUOTATION, changeCurrentRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentQuotationSaga),
    fork(addItemQuotationFromCodeSaga),
    fork(addItemQuotationSaga),
    fork(changeItemQuantityQuotationSaga),
    fork(removeItemQuotationSaga),
    fork(setDataToQuotationSaga),      
    fork(saveQuotationSaga),      
    fork(cancelQuotationSaga),
    fork(newQuotationSaga),
    fork(getQuotationsByUserSaga),
    fork(getQuotationByIdSaga),
    fork(addProductsToCurrentSaga),
    fork(changeCurrentSaga),
  ]);
}