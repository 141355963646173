import Axios from 'axios';

export const signInUserRequest = async (username, password) => {
    const response = await Axios.post('/usuarios/login', {
        username,
        password,
    });
    return response.data;
};

export const signOutUserRequest = async () => {
    const response = await Axios.post('/usuarios/logout');
    return response.data;
};
