import Axios from 'axios';

export const getCurrentOrder = async (currentOrderId) => {
    let params = {};

    if (currentOrderId) {
        params.id = currentOrderId;
    }

    const response = await Axios.get('/pedidos/current', { params });
    return response;
};

export const addItemToOrder = async (orderId, item) => {
    const response = await Axios.post(`/pedidos/${orderId}/addItem`, item);
    return response;
};

export const removeItemFromOrder = async (orderId, itemCode) => {
    const response = await Axios.post(`/pedidos/${orderId}/removeItem`, {
        codigodearticulo: itemCode,
    });
    return response;
};

export const changeItemQuantityFromOrder = async (orderId, itemCode, quantity) => {
    const response = await Axios.post(`/pedidos/${orderId}/changeItemQuantity`, {
        codigodearticulo: itemCode,
        cantidad: quantity,
    });
    return response;
};

export const setDataToOrder = async (orderId, type, value) => {
    const response = await Axios.post(`/pedidos/${orderId}/setData`, { type, value });
    return response;
};

export const saveOrder = async (orderId) => {
    const response = await Axios.post(`/pedidos/${orderId}/saveOrder`, {});
    return response;
};

export const cancelOrder = async (orderId) => {
    const response = await Axios.post(`/pedidos/${orderId}/cancelOrder`, {});
    return response;
};

export const confirmOrder = async (orderId, appointment) => {
    const response = await Axios.post(`/pedidos/${orderId}/confirm`, { appointment });
    return response;
};

export const addClientToOrder = async (orderId, clientId) => {
    const response = await Axios.post(`/pedidos/${orderId}/addClient`, { clientId });
    return response;
};

export const addItemToOrderFromCode = async (orderId, params) => {
    const response = await Axios.post(`/pedidos/${orderId}/addItemFromCode`, params);
    return response;
};

export const addTransportToOrder = async (orderId, transportId) => {
    const response = await Axios.post(`/pedidos/${orderId}/addTransport`, { transportId });
    return response;
};

export const applyDiscountsToProducts = async (clientCode, products) => {
    const response = await Axios.post(`/pedidos/applyDiscountsToProducts`, {
        clientCode,
        products,
    });
    return response;
};

export const getOrdersByUser = async (params) => {
    const response = await Axios.post(`/pedidos/mine`, params);
    return response;
};

export const getOrdersByDate = async (params) => {
  const response = await Axios.post(`/pedidos/getOrdersByDate`, params);
  return response;
}

export const getOrderById = async (params) => {
    const response = await Axios.post(`/pedidos/getOrderById`, params);
    return response;
};

export const addProductsToCurrent = async (orderId) => {
    const response = await Axios.post(`/pedidos/${orderId}/addOrderToCurrent`);
    return response;
};

export const changeCurrentOrder = async (orderId) => {
    const response = await Axios.post(`/pedidos/${orderId}/changeCurrentOrder`);
    return response;
};

export const getRefundCodes = async () => {
    const response = await Axios.get(`/pedidos/listRefundCodes`);
    return response;
};

export const getCNTypes = async () => {
    const response = await Axios.get(`/pedidos/listCNTypes`);
    return response;
};

export const getInvoices = async (params) => {
    const response = await Axios.post('/pedidos/listInvoices', params);
    return response;
};

export const getInvoice = async (params) => {
    const response = await Axios.post('/pedidos/getInvoice', params);
    return response;
};

export const createCN = async (params) => {
    const formData = new FormData();
    if (params.file) {
        formData.append('file', params.file);
    }
    delete params.file;
    formData.append('all', JSON.stringify(params));
    const response = await Axios.post('/pedidos/createCreditNote', formData);
    return response;
};
