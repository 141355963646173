import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Modal } from 'antd';
import CatalogItemDetail from './catalog-item-detail';

import {
  hideItemDetail,
} from '../../actions'

class CatalogItemDetailModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { current, item } = this.props

    if (current && current.cliente) {
      this.props.applyDiscountsToProducts({
        clientCode: current.cliente.codigocliente,
        products: [
          item.codigodearticulo
        ]
      });
    }
  }

  handleModalClose = () => {
    this.setState({ showPrices: true });
    this.props.hideItemDetail();
  }

  render() {
    const { itemDetail: { show, item, action, discount, showDealLabel } } = this.props;

    return (
        <div>
            {show && (
                <Modal
                    open={true}
                    maskClosable={true}
                    wrapClassName="catalog-item-detail-modal"
                    onCancel={this.handleModalClose}
                    footer={null}
                    closable={false}
                >
                    <CatalogItemDetail
                        item={item}
                        onOk={this.handleModalClose}
                        onCancel={this.handleModalClose}
                        action={action}
                        discount={discount}
                        showDealLabel={showDealLabel}
                    />
                </Modal>
            )}
        </div>
    );
  }
}

const mapStateToProps = ({ catalog }) => {
  const { itemDetail } = catalog;
  return { itemDetail };
};

export default connect(mapStateToProps, {
  hideItemDetail,
})(withTranslation()(CatalogItemDetailModal));

