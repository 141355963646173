import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_MAIN_GROUP_SUCCESS,
    GET_MAIN_GROUP_ERROR,
    SEARCH_MAIN_GROUPS,
    AUTOCOMPLETE_MAIN_GROUPS,
    GET_MAIN_GROUP,    
} from '../constants/ActionsTypes';

import {    
    getAgrupations,
    searchAgrupations
} from '../api/Agrupations';

import {
    searchMainGroupsSuccess,    
    autocompleteMainGroupsSuccess
} from '../actions/MainGroups';

export const getCommonState = (state) => state.common;


function* callGetMainGroup({ payload }) {
    const { mainGroupId } = payload;  
    try {
        const response = yield call(getAgrupations, mainGroupId, );
        yield put({ type: GET_MAIN_GROUP_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_MAIN_GROUP_ERROR, error });
    }
}


function* searchMainGroupstRequest({payload}) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;
    
    if(showRemove){
        payload.borrado = showRemove;
    }    
    try {
        const transports = yield call(searchAgrupations, payload);
        yield put(searchMainGroupsSuccess(transports));
    } catch (error) {
    }
}

function* autocompleteMainGroupsRequest({payload}) {

    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;   
    
    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(showRemove){
        query.borrado = showRemove;
    } 
  
    try {
        const groups = yield call(searchAgrupations, query);
        yield put(autocompleteMainGroupsSuccess(groups));
    } catch (error) {
    }
}

export function* searchMainGroupsSaga() {
    yield takeEvery(SEARCH_MAIN_GROUPS, searchMainGroupstRequest);
}

export function* getMainGroupSaga() {
    yield takeLatest(GET_MAIN_GROUP, callGetMainGroup);
}

export function* autocompleteMainGroupsSaga() {
    yield throttle(1000, AUTOCOMPLETE_MAIN_GROUPS, autocompleteMainGroupsRequest);
}

export default function* rootSaga() {
    yield all([        
        fork(getMainGroupSaga),
        fork(searchMainGroupsSaga),
        fork(autocompleteMainGroupsSaga),
    ]);
}