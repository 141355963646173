import moment from 'moment';

import {
  GET_CURRENT_ORDER_SUCCESS,
  ADD_ITEM_TO_ORDER_SUCCESS,
  REMOVE_ITEM_FROM_ORDER_SUCCESS,
  CHANGE_ITEM_QUANTITY_FROM_ORDER_SUCCESS,
  SET_DATA_TO_ORDER_SUCCESS,
  ADD_CLIENT_TO_ORDER_SUCCESS,
  ADD_ITEM_TO_ORDER_FROM_CODE_SUCCESS,
  ADD_TRANSPORT_TO_ORDER_SUCCESS,
  SAVE_ORDER_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  GET_ORDER_BY_USER,
  GET_ORDERS_BY_USER_PENDING,
  GET_ORDERS_BY_DATE,
  GET_ORDERS_BY_DATE_PENDING,
  CLEAN_ORDERS_BY_DATE,
  GET_ORDER_BY_USER_SUCCESS,
  GET_ORDERS_BY_DATE_SUCCESS,
  GET_ORDER_BY_ID,
  GET_ORDER_BY_ID_SUCCESS,
  CONFIRM_ORDER,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_ORDER_ERROR,
  CONFIRM_ORDER_DIALOG_CLOSED,
  ADD_PRODUCTS_TO_CURRENT_SUCCESS,
  CANCEL_ORDER,
  CLEAN_ORDER,
  GET_REFUND_CODES,
  GET_REFUND_CODES_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_SUCCESS,
  GET_INVOICE,
  CREATE_CREDIT_NOTE,
  CREATE_CREDIT_NOTE_SUCCESS,
  GET_CN_TYPES,
  GET_CN_TYPES_SUCCESS,
  SET_PARAMS_SNC,
  CHANGE_CURRENT_ORDER_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
  current: undefined,
  search : null,
  order:null,
  currentAddSuccess:false,
  cancelSuccess:false,
  loading: false,
  refundCodes: null,
  cnTypes: null,
  invoices: null,
  invoice: null,
  paramsSNC: null,
  cnToSee: null,
  isCreatedCNSuccessfully: false,
  getOrdersByUserLoading: false,
  getOrdersByDateLoading: false,
}

function rootReducer(state = initialState, action) {
  if(action.meta) {
    switch (action.meta) {
      case CONFIRM_ORDER:
        return { ...state, confirmationStatus: undefined, loading: true }
      case CONFIRM_ORDER_ERROR:
        return { ...state, confirmationStatus: undefined, loading: false}
      default:
        return state
    }
  }
  else {
    switch (action.type) {
      case GET_CURRENT_ORDER_SUCCESS:
        return {...state, current: action.data}
      case CHANGE_CURRENT_ORDER_SUCCESS:
        return {...state, current: action.payload.data}
      case ADD_ITEM_TO_ORDER_SUCCESS:
        return {...state, current: action.data}
      case REMOVE_ITEM_FROM_ORDER_SUCCESS:
        return {...state, current: action.data}
      case CHANGE_ITEM_QUANTITY_FROM_ORDER_SUCCESS:
        return {...state, current: action.data}
      case SET_DATA_TO_ORDER_SUCCESS:
        return {...state, current: action.data}
      case ADD_TRANSPORT_TO_ORDER_SUCCESS:
        return {...state, current: action.data}
      case ADD_CLIENT_TO_ORDER_SUCCESS:
        return {...state, current: action.data}
      case ADD_ITEM_TO_ORDER_FROM_CODE_SUCCESS:
        return {...state, current: action.data}
      case ADD_PRODUCTS_TO_CURRENT_SUCCESS:
        return {...state, current: action.payload.data, currentAddSuccess: true}
      case SAVE_ORDER_SUCCESS:
        return {...state, current: undefined}
      case CANCEL_ORDER:
        return {...state, cancelSuccess: false}
      case CANCEL_ORDER_SUCCESS:
        return {...state, current: undefined, cancelSuccess: true}
      case GET_ORDER_BY_USER:
        return {...state, search: null, orderRequest: action.payload, cancelSuccess: false}
      case GET_ORDERS_BY_USER_PENDING:
        return {...state, getOrdersByUserLoading: true }
      case GET_ORDER_BY_USER_SUCCESS:
        return {...state, search: action.payload.data.response, getOrdersByUserLoading: false}
      case GET_ORDER_BY_ID:
        return {...state, order: null}
      case GET_ORDER_BY_ID_SUCCESS:
        return {...state, order: action.payload.data.response}
      case CONFIRM_ORDER:
        return {...state, confirmationStatus: undefined, loading: true}
      case CONFIRM_ORDER_SUCCESS:
        return {
          ...state,
          confirmationStatus: {
            numeroPedidoSAP: action.data.numeroPedidoSAP,
            idPedido: action.data.idPedido,
            success: action.data.success,
            errorOnSaveEvent: action.data.errorOnSaveEvent
          },
          current: undefined,
          loading: false
        }
      case CONFIRM_ORDER_ERROR: {
        const newState = { ...state };
        if(action.data) {
          newState.confirmationStatus = {
            numeroPedidoSAP: action.data.numeroPedidoSAP,
            idPedido: action.data.idPedido,
            success: action.data.success
          }
        }
        newState.loading = false;
        return newState;
      }
      case CONFIRM_ORDER_DIALOG_CLOSED:
        return {...state, confirmationStatus: undefined, loading: false}

      case CLEAN_ORDER:
        return {...state, ...initialState}

      case GET_REFUND_CODES:
        return {...state, refundCodes: null }
      case GET_REFUND_CODES_SUCCESS:
        return {...state, refundCodes: action.payload.response }

      case GET_CN_TYPES:
        return {...state, cnTypes: null }
      case GET_CN_TYPES_SUCCESS:
        return {...state, cnTypes: action.payload.response }

      case GET_INVOICES:
        return {...state }
      case GET_INVOICES_SUCCESS:
        return {...state, invoices: action.payload.data }

      case GET_INVOICE:
        return {...state, invoice: null }
      case GET_INVOICE_SUCCESS:
        return {...state, invoice: action.payload.data }

      case CREATE_CREDIT_NOTE:
        return {...state, isCreatedCNSuccessfully: false }
      case CREATE_CREDIT_NOTE_SUCCESS:
        return {...state, isCreatedCNSuccessfully: true }

      case  SET_PARAMS_SNC:
        return {...state, paramsSNC: action.payload}

      case GET_ORDERS_BY_DATE:
        return {...state, orderByDateRequest: action.payload, cancelSuccess: false}
      case GET_ORDERS_BY_DATE_PENDING:
        return {...state, getOrdersByDateLoading: true }
      case GET_ORDERS_BY_DATE_SUCCESS:
        if (!state.search || !Array.isArray(state.search.data)) {
            return state;
        }

        if (!action.payload || !action.payload.data || !action.payload.data.response || !Array.isArray(action.payload.data.response.data)) {
          return {...state, getOrdersByDateLoading: false };
        }

        // Check if date value exists
        const ordersData = action.payload.data.response.data[0];
        if (!ordersData || !ordersData.date) {
          return {...state, getOrdersByDateLoading: false };
        }

        // Find the index of the day we want to update
        const dayIndex = state.search.data.findIndex(item => {
          return item.date === ordersData.date;
        });

        // If the day is found in the array
        if (dayIndex !== -1) {
            // Create a copy of the original array
            const newData = [...state.search.data];

            // Replace the object at the found index with the new data
            newData[dayIndex] = action.payload.data.response.data[0];

            return {
              ...state,
              search: {
                  ...state.search,
                  data: newData
              },
              getOrdersByDateLoading: false
          };
        }

        return {...state, getOrdersByDateLoading: false };
      case CLEAN_ORDERS_BY_DATE:
        if (!state.search || !Array.isArray(state.search.data)) {
            return state;
        }

        // Find the index of the day we want to clean
        const cleanDayIndex = state.search.data.findIndex(item => item.date === action.payload.date);

        // If the day is not found in the array, return the current state
        if (cleanDayIndex === -1) {
            return state;
        }

        // Create a copy of the original array and remove the information inside the item that matches the date
        const cleanedData = [...state.search.data];
        cleanedData[cleanDayIndex] = { ...cleanedData[cleanDayIndex], items: undefined };

        return {
            ...state,
            search: {
                ...state.search,
                data: cleanedData
            }
        };

      default:
        return state
    }
  }
}

export default rootReducer
