import Axios from 'axios';

const BASE_URL_END_POINT = '/lineas';

export const getLines = async (subGroupId) => {
    const response = await Axios.get(
        `${BASE_URL_END_POINT}/${subGroupId}?filter={"include":"sublinea"}`
    );
    return response.data;
};

export const getLinesByCode = async (subGroupCode) => {
    const uri = `${BASE_URL_END_POINT}/searchByCode`;
    const response = Axios.get(uri, {
        params: {
            code: subGroupCode,
        },
    });
    return response;
};

export const searchSubGroups = async (searchParams) => {
    const uri = `${BASE_URL_END_POINT}/search`;
    const response = await Axios.post(uri, searchParams);
    return response.data;
};
