import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { Button, Tooltip } from "antd";
import {addProductToFavorites, removeProductToFavorites} from "../../actions";
import { withTranslation } from "react-i18next";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { trackAnalyticsCommerceEvent } from "../../Utils/AnalyticsUtils";

const Favorite = props => {
    const { product, iconClassName = "icon-card-item" } = props;
    const productId = product.id || product._id;
    const [selected, setSelected] = useState(!!props.favoriteProducts[productId])

    useEffect(() => {
        const exist = !!props.favoriteProducts[productId]
        setSelected(exist)
    }, [props.favoriteProducts])

    const onClick = () => {
        const { GA_LABEL, user } = props;

        if(selected) {
            props.removeProductToFavorites({favoriteProductId: productId})
        } else {
            trackAnalyticsCommerceEvent(
                'add_to_wishlist',
                GA_LABEL ? GA_LABEL : null,
                product.codigodearticulo,
                user,
                product.preciolista,
                [{ item_id: product.codigodearticulo, item_name: product.descripcion, price: product.preciolista}]
            );
            props.addProductToFavorites({favoriteProductId: productId})
        }
    }

    const buttonProps = {}
    if(props.hasOwnProperty('tabIndex')) {
        buttonProps.tabIndex = props.tabIndex;
    }

    return (
        <Tooltip key={productId} title={props.t(selected ? 'FAVORITE.REMOVE_FAVORITE' : 'FAVORITE.ADD_FAVORITE')}>
            {props.isButton ? (
                <Button {...buttonProps} onClick={onClick} >
                    {selected ?
                        <StarFilled style={{fontSize: 20}} className={iconClassName}/>
                        :
                        <StarOutlined style={{fontSize: 20}} className={iconClassName} />
                    }
                </Button>
            ) : (
                selected ? <StarFilled className={iconClassName} onClick={onClick} /> : <StarOutlined className={iconClassName} onClick={onClick} />
            )}
        </Tooltip>
    );
}

const mapStateToProps = ({userConfig, auth}) => {
    const { successAddingProductToFavorites, successRemovingProductToFavorites } = userConfig
    let { favoriteProducts } = userConfig.config
    const { user } = auth;

    favoriteProducts = favoriteProducts ? favoriteProducts.reduce((a, v) => ({ ...a, [v._id]: v}), {}) : {}

    return {
        favoriteProducts,
        successAddingProductToFavorites,
        successRemovingProductToFavorites,
        user
    }
}

const mapDispatchToProps = dispatch => ({
    addProductToFavorites: param => {
        dispatch(addProductToFavorites(param));
    },
    removeProductToFavorites: param => {
        dispatch(removeProductToFavorites(param));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Favorite))
