import {
	CHECK_CONNECTION,
	OFFLINE,
	ONLINE,
} from '../constants/ActionsTypes';

export const checkConnection = () => {
	return {
		type: CHECK_CONNECTION
	};
};

export const online = () => {
	return {
		type: ONLINE
	};
};

export const offline = () => {
	return {
		type: OFFLINE
	};
};