import Axios from 'axios';

const BASE_URL_END_POINT = '/sublineas';

export const getSubline = async (sublineaId) => {
    const response = await Axios.get(
        `${BASE_URL_END_POINT}/${sublineaId}?filter={"include":"agrupacion"}`
    );
    return response.data;
};

export const searchSublines = async (searchParams) => {
    const uri = `${BASE_URL_END_POINT}/search`;
    const response = await Axios.post(uri, searchParams);
    return response.data;
};
