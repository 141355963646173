import getConfig from 'next/config'
import { isElectron } from '../electron/tools'
import { delay, fork, race, take } from "redux-saga/effects";
import moment from "moment-business-days";

const { publicRuntimeConfig } = getConfig();

const DEFAULT_IMG_PATH = "/static/images/no-image.jpg";

export function getProductImagePath(product) {
    const imgPath = product && (product.mainImageFileName || product.imagesFiles && product.imagesFiles[0]);

    const basePath = (isElectron()) ? publicRuntimeConfig.productImagesBasePathOffline : publicRuntimeConfig.productImagesBasePath
    return imgPath ? basePath + imgPath : DEFAULT_IMG_PATH;
}

export function getImagesPath() {
    return (isElectron()) ? publicRuntimeConfig.productImagesBasePathOffline : publicRuntimeConfig.productImagesBasePath
}

export function isExternal(url) {
    var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
    if (match != null && typeof match[1] === 'string' &&
        match[1].length > 0 && match[1].toLowerCase() !== location.protocol)
        return true;
    if (match != null && typeof match[2] === 'string' &&
        match[2].length > 0 &&
        match[2].replace(new RegExp(':(' + { 'http:': 80, 'https:': 443 }[location.protocol] + ')?$'), '')
        !== location.host) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray, chunk_size) {
    let results = [];
    const clone = myArray.slice(0);

    while (clone.length) {
        results.push(clone.splice(0, chunk_size))
    }

    return results;
}

/**
 * https://gist.github.com/gordonbrander/2230317
 */
export function getUniqueID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
};

export function debounce(ms, pattern, task, ...args) {
    return fork(function*() {
        while (true) {
            let action = yield take(pattern)

            while (true) {
                const {debounced, latestAction} = yield race({
                    debounced: delay(ms),
                    latestAction: take(pattern)
                })

                if (debounced) {
                    yield fork(task, ...args, action)
                    break
                }

                action = latestAction
            }
        }
    })
}

export function checkDealPrice(product) {
    let result = null;
    if (product.preciooferta) {
        const today = moment();
        if (product.oferta_fechafin && moment(product.oferta_fechafin).isAfter(today) && product.oferta_fechainicio && moment(product.oferta_fechainicio).isBefore(today)) {
            result = product.preciooferta;
        }
    }
    return result;
}

export function isNewProduct(createdAt) {
    const daysAgo = 30;
    const currentDate = new Date();
    const creationDate = new Date(createdAt);
    const limitDate = new Date();

    limitDate.setDate(currentDate.getDate() - daysAgo);

    return creationDate >= limitDate;
}