import {
    SAVE_APPOINTMENT,
    SAVE_APPOINTMENT_ERROR,
    SAVE_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT,
    UPDATE_APPOINTMENT_ERROR,
    UPDATE_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT,
    GET_APPOINTMENT_ERROR,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_STATES,
    GET_APPOINTMENT_STATES_ERROR,
    GET_APPOINTMENT_STATES_SUCCESS,
    GET_APPOINTMENT_ACTIONS,
    GET_APPOINTMENT_ACTIONS_ERROR,
    GET_APPOINTMENT_ACTIONS_SUCCESS,
    SELECT_APPOINTMENT_DATE,
    SELECT_APPOINTMENT_SELLER,
    GET_MY_APPOINTMENTS,
    GET_MY_APPOINTMENTS_SUCCESS,
    GET_MY_APPOINTMENTS_GROUP,
    GET_MY_APPOINTMENTS_GROUP_SUCCESS,
    OPEN_APPOINTMENT_MODAL,
    CLOSE_APPOINTMENT_MODAL,
    OPEN_EXPORT_MODAL,
    CLOSE_EXPORT_MODAL,
    DELETE_APPOINTMENT,
    DELETE_APPOINTMENT_ERROR,
    DELETE_APPOINTMENT_SUCCESS,
    CLEAN_APPOINTMENT,
    SET_APPOINTMENT_TIME,
    GET_APPOINTMENT_EVENTS,
    GET_APPOINTMENT_EVENTS_ERROR,
    GET_APPOINTMENT_EVENTS_SUCCESS,
    SELECT_APPOINTMENT_CLIENT,
    GET_ACTIONS_GROUPED_BY_SELLER,
    GET_ACTIONS_GROUPED_BY_SELLER_SUCCESS, GET_AMOUNT_OF_ACTIONS_BY_SELLER, GET_AMOUNT_OF_ACTIONS_BY_SELLER_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
    success: undefined,
    successEvents: undefined,
    appointment: undefined,
    appointmentStates: undefined,
    appointmentActions: undefined,
    actionGroupedBySeller: undefined,
    amountOfActionsBySeller: undefined,
    selectedClient: undefined,
    selectedSeller: undefined,
    selectedEvent: undefined,
    selectExport: false,
    deleting: undefined,
    message: undefined,
    error: undefined,
    appointmentEvents: undefined
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_APPOINTMENT:
            return { ...state, success: false };
        case SAVE_APPOINTMENT_ERROR:
            return { ...state, success: false };
        case SAVE_APPOINTMENT_SUCCESS:
            return { ...state, success: true, appointment: undefined };

        case UPDATE_APPOINTMENT:
            return { ...state, success: false };
        case UPDATE_APPOINTMENT_ERROR:
            return { ...state, success: false };
        case UPDATE_APPOINTMENT_SUCCESS:
            return { ...state, success: true, appointment: undefined };

        case GET_APPOINTMENT:
            return { ...state, appointment: null, success: false };
        case GET_APPOINTMENT_ERROR:
            return { ...state, success: false };
        case GET_APPOINTMENT_SUCCESS:
            return { ...state, appointment: action.payload };

        case GET_APPOINTMENT_STATES:
            return { ...state, appointmentStates: null, success: false };
        case GET_APPOINTMENT_STATES_ERROR:
            return { ...state, success: false };
        case GET_APPOINTMENT_STATES_SUCCESS:
            return { ...state, appointmentStates: action.payload };

        case GET_APPOINTMENT_ACTIONS:
            return { ...state, appointmentActions: null, success: false };
        case GET_APPOINTMENT_ACTIONS_ERROR:
            return { ...state, success: false };
        case GET_APPOINTMENT_ACTIONS_SUCCESS:
            return { ...state, appointmentActions: action.payload };

        case SELECT_APPOINTMENT_DATE:
            return { ...state, selectedDate: action.payload };

        case SELECT_APPOINTMENT_CLIENT:
            return { ...state, selectedClient: action.payload };

        case SELECT_APPOINTMENT_SELLER:
            return { ...state, selectedSeller: action.payload };

        case GET_MY_APPOINTMENTS:
            return { ...state, monthEvents: [] };

        case GET_MY_APPOINTMENTS_SUCCESS:
            return { ...state, monthEvents: action.payload };

        case GET_MY_APPOINTMENTS_GROUP:
            return { ...state, monthEventsGroup: [] };

        case GET_MY_APPOINTMENTS_GROUP_SUCCESS:
            return { ...state, monthEventsGroup: action.payload };

        case OPEN_APPOINTMENT_MODAL:
            return { ...state, selectedEvent: action.payload };

        case CLOSE_APPOINTMENT_MODAL:
            return { ...state, selectedEvent: null };

        case OPEN_EXPORT_MODAL:
            return { ...state, selectExport: true };
        case CLOSE_EXPORT_MODAL:{
            return { ...state, selectExport: false }
        }

        case DELETE_APPOINTMENT:
            return { ...state, deleting: true };
        case DELETE_APPOINTMENT_SUCCESS:
            return { ...state, message: null, deleting: false };
        case DELETE_APPOINTMENT_ERROR:
            return { ...state, message: action.error, deleting: false, error: true };

        case CLEAN_APPOINTMENT:
            return { ...state, ...initialState };

        case SET_APPOINTMENT_TIME:
            return { ...state, appointmentTime: action.payload };

        case GET_APPOINTMENT_EVENTS:
            return { ...state, appointmentEvents: null, successEvents: false };
        case GET_APPOINTMENT_EVENTS_ERROR:
            return { ...state, successEvents: false };
        case GET_APPOINTMENT_EVENTS_SUCCESS:
            return { ...state, appointmentEvents: action.payload, successEvents: true };

        case GET_ACTIONS_GROUPED_BY_SELLER:
            return { ...state, actionGroupedBySeller: null };
        case GET_ACTIONS_GROUPED_BY_SELLER_SUCCESS:
            return { ...state, actionGroupedBySeller: action.payload };

        case GET_AMOUNT_OF_ACTIONS_BY_SELLER:
            return { ...state, amountOfActionsBySeller: null };
        case GET_AMOUNT_OF_ACTIONS_BY_SELLER_SUCCESS:
            return { ...state, amountOfActionsBySeller: action.payload };

        default:
            return state
    }
}

export default rootReducer
