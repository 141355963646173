import {
    CLEAN_APPOINTMENT,
    CLOSE_APPOINTMENT_MODAL,
    CLOSE_EXPORT_MODAL,
    DELETE_APPOINTMENT_ERROR,
    DELETE_APPOINTMENT_SUCCESS,
    DELETE_APPOINTMENT,
    EXCEL_EXPORT_APPOINTMENT,
    GET_ACTIONS_GROUPED_BY_SELLER_SUCCESS,
    GET_ACTIONS_GROUPED_BY_SELLER,
    GET_AMOUNT_OF_ACTIONS_BY_SELLER,
    GET_AMOUNT_OF_ACTIONS_BY_SELLER_SUCCESS,
    GET_APPOINTMENT_ACTIONS_ERROR,
    GET_APPOINTMENT_ACTIONS_SUCCESS,
    GET_APPOINTMENT_ACTIONS,
    GET_APPOINTMENT_ERROR,
    GET_APPOINTMENT_EVENTS_ERROR,
    GET_APPOINTMENT_EVENTS_SUCCESS,
    GET_APPOINTMENT_EVENTS,
    GET_APPOINTMENT_STATES_ERROR,
    GET_APPOINTMENT_STATES_SUCCESS,
    GET_APPOINTMENT_STATES,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT,
    GET_APPOINTMENTS_ERROR,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS,
    GET_MY_APPOINTMENTS_ERROR,
    GET_MY_APPOINTMENTS_GROUP_ERROR,
    GET_MY_APPOINTMENTS_GROUP_SUCCESS,
    GET_MY_APPOINTMENTS_GROUP,
    GET_MY_APPOINTMENTS_SUCCESS,
    GET_MY_APPOINTMENTS,
    OPEN_APPOINTMENT_MODAL,
    OPEN_EXPORT_MODAL,
    SAVE_APPOINTMENT_ERROR,
    SAVE_APPOINTMENT_SUCCESS,
    SAVE_APPOINTMENT,
    SELECT_APPOINTMENT_CLIENT,
    SELECT_APPOINTMENT_DATE,
    SELECT_APPOINTMENT_SELLER,
    SET_APPOINTMENT_TIME,
    UPDATE_APPOINTMENT_ERROR,
    UPDATE_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT,
} from '../constants/ActionsTypes';

export const getAppointment = (AppointmentId, filter) => {
    return {
        type: GET_APPOINTMENT,
        payload: { AppointmentId, filter },
    };
};

export const getAppointmentSuccess = (data) => {
    return {
        type: GET_APPOINTMENT_SUCCESS,
        payload: data,
    };
};

export const getAppointmentError = (response) => {
    return {
        type: GET_APPOINTMENT_ERROR,
        payload: response,
    };
};

export const getAppointments = (params) => {
    return {
        type: GET_APPOINTMENTS,
        payload: params,
    };
};

export const getAppointmentsSuccess = (data) => {
    return {
        type: GET_APPOINTMENTS_SUCCESS,
        payload: data,
    };
};

export const getAppointmentsError = (response) => {
    return {
        type: GET_APPOINTMENTS_ERROR,
        payload: response,
    };
};

export const getAppointmentStates = (params) => {
    return {
        type: GET_APPOINTMENT_STATES,
        payload: params,
    };
};

export const getAppointmentStatesSuccess = (data) => {
    return {
        type: GET_APPOINTMENT_STATES_SUCCESS,
        payload: data,
    };
};

export const getAppointmentStatesError = (response) => {
    return {
        type: GET_APPOINTMENT_STATES_ERROR,
        payload: response,
    };
};

export const getAppointmentActions = (params) => {
    return {
        type: GET_APPOINTMENT_ACTIONS,
        payload: params,
    };
};

export const getAppointmentActionsSuccess = (data) => {
    return {
        type: GET_APPOINTMENT_ACTIONS_SUCCESS,
        payload: data,
    };
};

export const getAppointmentActionsError = (response) => {
    return {
        type: GET_APPOINTMENT_ACTIONS_ERROR,
        payload: response,
    };
};

export const saveAppointment = (params) => {
    return {
        type: SAVE_APPOINTMENT,
        payload: params,
    };
};

export const saveAppointmentError = (response) => {
    return {
        type: SAVE_APPOINTMENT_ERROR,
        payload: response,
    };
};

export const saveAppointmentSuccess = (data) => {
    return {
        type: SAVE_APPOINTMENT_SUCCESS,
        payload: data,
    };
};

export const updateAppointment = (appointmentId, params) => {
    return {
        type: UPDATE_APPOINTMENT,
        payload: { appointmentId, params },
    };
};

export const updateAppointmentError = (response) => {
    return {
        type: UPDATE_APPOINTMENT_ERROR,
        payload: response,
    };
};

export const updateAppointmentSuccess = (data) => {
    return {
        type: UPDATE_APPOINTMENT_SUCCESS,
        payload: data,
    };
};

export const selectAppointmentDate = (data) => {
    return {
        type: SELECT_APPOINTMENT_DATE,
        payload: data,
    };
};

export const selectAppointmentClient = (data) => {
    return {
        type: SELECT_APPOINTMENT_CLIENT,
        payload: data,
    };
};

export const selectAppointmentSeller = (data) => {
    return {
        type: SELECT_APPOINTMENT_SELLER,
        payload: data,
    };
};

export const getMyAppointments = (params) => {
    return {
        type: GET_MY_APPOINTMENTS,
        payload: params,
    };
};

export const getMyAppointmentsError = (response) => {
    return {
        type: GET_MY_APPOINTMENTS_ERROR,
        payload: response,
    };
};

export const getMyAppointmentsSuccess = (data) => {
    return {
        type: GET_MY_APPOINTMENTS_SUCCESS,
        payload: data,
    };
};

export const getMyAppointmentsGroup = (params) => {
    return {
        type: GET_MY_APPOINTMENTS_GROUP,
        payload: params,
    };
};

export const getMyAppointmentsGroupError = (response) => {
    return {
        type: GET_MY_APPOINTMENTS_GROUP_ERROR,
        payload: response,
    };
};

export const getMyAppointmentsGroupSuccess = (data) => {
    return {
        type: GET_MY_APPOINTMENTS_GROUP_SUCCESS,
        payload: data,
    };
};

export const openAppointmentModal = (data) => {
    return {
        type: OPEN_APPOINTMENT_MODAL,
        payload: data,
    };
};

export const setAppointmentTime = (data) => {
    return {
        type: SET_APPOINTMENT_TIME,
        payload: data,
    };
};

export const closeAppointmentModal = () => {
    return {
        type: CLOSE_APPOINTMENT_MODAL,
        payload: null,
    };
};

export const openExportModal = () => {
    return {
        type: OPEN_EXPORT_MODAL,
    };
};

export const closeExportModal = () => {
    return {
        type: CLOSE_EXPORT_MODAL,
    };
};

export const deleteAppointment = (appointmentId) => {
    return {
        type: DELETE_APPOINTMENT,
        payload: { appointmentId },
    };
};

export const deleteAppointmentError = (response) => {
    return {
        type: DELETE_APPOINTMENT_ERROR,
        payload: response,
    };
};

export const deleteAppointmentSuccess = (data) => {
    return {
        type: DELETE_APPOINTMENT_SUCCESS,
        payload: data,
    };
};

export const cleanAppointment = () => {
    return {
        type: CLEAN_APPOINTMENT,
    };
};

export const excelExportAppointment = (data) => {
    return {
        type: EXCEL_EXPORT_APPOINTMENT,
        payload: data,
    };
};

export const getAppointmentEvents = (params) => {
    return {
        type: GET_APPOINTMENT_EVENTS,
        payload: params,
    };
};

export const getAppointmentEventsSuccess = (data) => {
    return {
        type: GET_APPOINTMENT_EVENTS_SUCCESS,
        payload: data,
    };
};

export const getAppointmentEventsError = (response) => {
    return {
        type: GET_APPOINTMENT_EVENTS_ERROR,
        payload: response,
    };
};

export const getActionsGroupedBySeller = (params) => {
    return {
        type: GET_ACTIONS_GROUPED_BY_SELLER,
        payload: params,
    };
};

export const getActionsGroupedBySellerSuccess = (data) => {
    return {
        type: GET_ACTIONS_GROUPED_BY_SELLER_SUCCESS,
        payload: data,
    };
};

export const getAmountOfActionsBySeller = (params) => {
    return {
        type: GET_AMOUNT_OF_ACTIONS_BY_SELLER,
        payload: params,
    };
};

export const getAmountOfActionsBySellerSuccess = (data) => {
    return {
        type: GET_AMOUNT_OF_ACTIONS_BY_SELLER_SUCCESS,
        payload: data,
    };
};
