import Axios from 'axios';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const testConnection = async () => {
    return await new Promise(async (resolve) => {
        await Axios({ url: publicRuntimeConfig.apiCheckUrl, baseURL: '' }).then(
            (res) => {
                resolve(true);
            },
            (err) => {
                resolve(false);
            }
        );
    });
};
