import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Modal, Table, Tooltip } from 'antd'
import { getDealProductById, showItemDetail, getProductsByIds } from '../../actions'
import { EyeOutlined } from '@ant-design/icons'

class dealShowImageSvg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      shouldShowModal: false,
      selectedCoords: [],
    }
  }

  getProduct(productId, dealId) {
    this.props.getDealProductById({
      productId,
      dealId,
    })
  }

  showModal = (coords) => {
    this.setState({
      modalVisible: true,
      selectedCoords: coords,
    })
  }

  handleModalClose = () => {
    this.setState({
      modalVisible: false,
    })
  }

  showProductSelectionTable = () => {
    const { t } = this.props
    Modal.info({
      title: t('products'),
      width: '600px',
      content: (
        <div>
          <Table
            dataSource={this.props.productsList}
            columns={[
              {
                title: t('productCode'),
                dataIndex: 'codigodearticulo',
                key: 'codigodearticulo',
                width: '35%',
                render: (text, record) => {
                  return (
                    <a onClick={() => this.getProduct(record.id, this.props.dealId)}>
                      {record.codigodearticulo}
                    </a>
                  )
                },
              },
              {
                title: t('description'),
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '65%',
                render: (text, record) => {
                  return (
                    <a onClick={() => this.getProduct(record.id, this.props.dealId)}>
                      {record.descripcion}
                    </a>
                  )
                },
              },
              {
                title: t('action'),
                key: 'action',
                width: 25,
                align: 'center',
                render: (text, record) => {
                  return (
                    <div>
                      <a
                        ref={this.saveRef}
                        onClick={() => {
                          this.getProduct(record.id, this.props.dealId)
                        }}
                      >
                        <Tooltip title={t('View detail')}>
                          <EyeOutlined />
                        </Tooltip>
                      </a>
                    </div>
                  )
                },
              },
            ]}
            pagination={{ pageSize: 7 }}
          />
        </div>
      ),
      className: 'deal-products-table',
      okText: t('Close'),
      onOk: this.handleModalClose,
      maskClosable: true,
    })
  }

  renderCoords() {
    const { dealItem } = this.props
    const { coords } = dealItem
    const result = []
    const renderedCoords = new Set() // Store coordinates (x-y pairs) that have already been rendered

    if (coords && Array.isArray(coords)) {
      coords.forEach((coord, index) => {
        // Create a unique key for each coordinate pair
        const coordKey = `${coord.x}-${coord.y}`

        // Check if this x-y pair has already been processed
        if (!renderedCoords.has(coordKey)) {
          result.push(
            <g className='hover_group_view' key={index}>
              <rect
                className='rect_products_view'
                x={coord.x}
                y={coord.y}
                fill='none'
                onClick={() => this.handleCoordClick(coord, dealItem)}
                width={coord.width}
                height={coord.height}
              />
            </g>,
          )
          renderedCoords.add(coordKey) // Mark this x-y pair as rendered
        }
      })
    }

    return result
  }

  handleCoordClick = (selectedCoord, dealItem) => {
    const { coords } = dealItem
    const matchingCoords = coords.filter(
      (coord) =>
        coord.x === selectedCoord.x &&
        coord.y === selectedCoord.y &&
        coord.width === selectedCoord.width &&
        coord.height === selectedCoord.height,
    )

    if (matchingCoords.length === 1) {
      this.getProduct(matchingCoords[0].productId, this.props.dealId)
    } else {
      const productsIds = matchingCoords.map((coord) => coord.productId)
      this.props.getProductsByIds(productsIds)
      this.setState({ shouldShowModal: true })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check if products are loaded and modal should be shown
    if (this.state.shouldShowModal && this.props.productsList.length >= 1) {
      this.showProductSelectionTable()
      this.setState({ shouldShowModal: false }) // Reset flag after showing the modal
    }

    if (prevProps.product === null && prevProps.product != this.props.product) {
      const payload = {
        show: true,
        action: 'cart',
        item: this.props.product.data, // This is the added to cart product
      }
      this.props.showItemDetail(payload)
    }
  }

  render() {
    const { image, sizeImg } = this.props

    if (!image || !sizeImg) {
      return null
    }

    return (
      <div id='dealDetails' style={{ paddingTop: '18px' }}>
        <svg
          ref={(svg) => (this.svg = svg)}
          viewBox={`0 0 ${sizeImg.width} ${sizeImg.height}`}
          preserveAspectRatio='xMinYMin meet'
        >
          <image id='ImageDealProduct' width='100%' xlinkHref={image.url} />
          {this.renderCoords()}
        </svg>
      </div>
    )
  }
}

const mapStateToProps = ({ deals, products }) => {
  const { productsList } = products
  const { product } = deals
  return { product, productsList }
}

export default connect(mapStateToProps, { getDealProductById, showItemDetail, getProductsByIds })(
  withTranslation()(dealShowImageSvg),
)
