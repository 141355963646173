import {
    AUTOCOMPLETE_CLIENTS_SUCCESS,
    AUTOCOMPLETE_CLIENTS,
    AUTOCOMPLETE_MY_CLIENTS_SUCCESS,
    AUTOCOMPLETE_MY_CLIENTS,
    CLEAR_AUTOCOMPLETE_CLIENTS,
    SEARCH_CLIENTS_SUCCESS,
    SEARCH_CLIENTS,
} from '../constants/ActionsTypes';

export const searchClients = (params) => {
    return {
        type: SEARCH_CLIENTS,
        payload: params,
    };
};

export const searchClientsSuccess = (data) => {
    return {
        type: SEARCH_CLIENTS_SUCCESS,
        payload: data,
    };
};

export const autocompleteClients = (params) => {
    return {
        type: AUTOCOMPLETE_CLIENTS,
        payload: params,
    };
};

export const autocompleteClientsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_CLIENTS_SUCCESS,
        payload: data,
    };
};

export const autocompleteMyClients = (params) => {
    return {
        type: AUTOCOMPLETE_MY_CLIENTS,
        payload: params,
    };
};

export const autocompleteMyClientsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_MY_CLIENTS_SUCCESS,
        payload: data,
    };
};

export const clearAutocompleteClients = () => {
    return {
        type: CLEAR_AUTOCOMPLETE_CLIENTS,
    };
};
