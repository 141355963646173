export const ROLES = {
    ANY: "*",
    UNAUTHENTICATED: "_UNAUTHENTICATED",
    ADMIN: "admin",
    REPORT: "report",
    CLIENT: "cliente",
    SALESMAN: "vendedor",
    SAP: "sap",
    ADMIN_COMERCIAL: 'administrador_comercial'
};

export const ROLE_TO_HOME_MAPPING = {
    [ROLES.ADMIN]: "/main",
    [ROLES.REPORT]: "/appointments",
    [ROLES.CLIENT]: "/catalog",
    [ROLES.SALESMAN]: "/catalog",
    [ROLES.ADMIN_COMERCIAL]: "/main",
    [ROLES.ANY]: "/catalog"
}

export const PERMISSIONS = {
    PAGE: {
        ADMIN: 'page:admin',
        DOWNLOAD_PRICE_LIST: 'page:download_price_list',
        MY_PROFILE: 'page:my_profile',

        USUARIO: 'page:usuario',
        USUARIOS: 'page:usuarios',

        CLIENTE: 'page:cliente',
        CLIENTES: 'page:clientes',

        VENDEDOR: 'page:vendedor',
        VENDEDORES: 'page:vendedores',

        SUCURSAL: 'page:sucursal',
        SUCURSALES: 'page:sucursales',

        TRANSPORTE: 'page:transporte',
        TRANSPORTES: 'page:transportes',

        LINEA_DE_CATALOGO: 'page:linea_de_catalogo',
        LINEAS_DE_CATALOGO: 'page:lineas_de_catalogo',

        PRODUCTO: 'page:producto',
        PRODUCTOS: 'page:productos',

        MARCA: 'page:marca',
        MARCAS: 'page:marcas',

        AGRUPACION: 'page:agrupacion',
        AGRUPACIONES: 'page:agrupaciones',

        SUB_LINEA: 'page:sub_linea',
        SUB_LINEAS: 'page:sub_lineas',

        LINEA: 'page:linea',
        LINEAS: 'page:lineas',

        SEGMENTO: 'page:segmento',
        SEGMENTOS: 'page:segmentos',

        BANNER: 'page:banner',
        BANNERS: 'page:banners',

        OFERTA: 'page:oferta',
        OFERTAS: 'page:ofertas',

        CATEGORIA: 'page:categoria',
        CATEGORIAS: 'page:categorias',

        BLOQUEO_MARCA: 'page:bloqueo_marca',
        BLOQUEO_MARCAS: 'page:bloqueo_marcas',

        CARACTERISTICA: 'page:caracteristica',
        CARACTERISTICAS: 'page:caracteristicas',

        APLICACION: 'page:aplicacion',
        APLICACIONES: 'page:aplicaciones',

        DESTINATARIO: 'page:destinatario',
        DESTINATARIOS: 'page:destinatarios',

        ACCOUNT_FORM: 'page:account_form',
        ACCOUNT_FORMS: 'page:account_forms',

        CATALOGO: 'page:catalogo',

        CATALOGO_DEALS: 'page:catalogo_deals',

        CATALOGO_ORDERS: 'page:catalogo_orders',

        CATALOGO_QUOTATIONS: 'page:catalogo_quotations',

        CATALOGO_CURRENT_ACCOUNTS: 'page:catalogo_current_accounts',

        CATALOGO_STATISTICS: 'page:catalogo_statistics',

        APPOINTMENTS: 'page:appointments',

        DASHBOARD: 'page:dashboard',

        FAVORITE: 'page:favorite',

        PROVISIONAL_RECEIPTS: 'page:provisionalReceipts',
        
        BILLING_DETAIL: 'page:billingDetail',
        
        CREDIT_LIMIT: 'page:creditLimit'
    }
}
