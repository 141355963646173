import Axios from 'axios';

const BASE_URL_END_POINT = '/tipoCaracteristicas';

export const getAllCharacteristics = async () => {
    const url = `${BASE_URL_END_POINT}`;
    const response = await Axios.get(url);
    return response.data;
};

export const searchCharacteristics = async (searchParams) => {
    const url = `${BASE_URL_END_POINT}/search`;
    const response = await Axios.post(url, searchParams);
    return response.data;
};

export const getCharacteristic = async (characteristicId) => {
    const url = `${BASE_URL_END_POINT}/${characteristicId}`;
    const response = await Axios.get(url);
    return response.data;
};

export const saveCharacteristic = async (characteristic) => {
    const url = `${BASE_URL_END_POINT}/createCharacteristic`;
    const response = await Axios.post(url, characteristic);
    return response.data;
};

export const updateCharacteristic = async (id, characteristic) => {
    const url = `${BASE_URL_END_POINT}/editCharacteristic/${id}`;
    const response = await Axios.put(url, characteristic);
    return response.data;
};
