import {
    GET_BRAND,
    GET_ALL_BRANDS,
    GET_ALL_BRANDS_SUCCESS,
    ADD_UPDATE_BRAND,
    ADD_UPDATE_BRAND_SUCCESS,
    AUTOCOMPLETE_BRANDS_ERROR,
    AUTOCOMPLETE_BRANDS_SUCCESS,
    AUTOCOMPLETE_BRANDS,
    SEARCH_BRANDS_SUCCESS,
    SEARCH_BRANDS,
} from '../constants/ActionsTypes';

export const addUpdateBrand = (params, token) => {
    return {
        type: ADD_UPDATE_BRAND,
        payload: { params, token },
    };
};

export const getBrand = (brandId, token, filter) => {
    return {
        type: GET_BRAND,
        payload: { brandId, token, filter },
    };
};

export const searchBrands = (params) => {
    return {
        type: SEARCH_BRANDS,
        payload: params,
    };
};

export const getAllBrands = () => {
    return {
        type: GET_ALL_BRANDS,
    };
};

export const getAllBrandsSuccess = (data) => {
    return {
        type: GET_ALL_BRANDS_SUCCESS,
        payload: data
    }
};

export const searchBrandsSuccess = (data) => {
    return {
        type: SEARCH_BRANDS_SUCCESS,
        payload: data,
    };
};

export const addBrandSuccess = (data) => {
    return {
        type: ADD_UPDATE_BRAND_SUCCESS,
        payload: data,
    };
};

export const autocompleteBrands = (params) => {
    return {
        type: AUTOCOMPLETE_BRANDS,
        payload: params,
    };
};

export const autocompleteBrandsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_BRANDS_SUCCESS,
        payload: data,
    };
};

export const autocompleteBrandsError = (data) => {
    return {
        type: AUTOCOMPLETE_BRANDS_ERROR,
        payload: data,
    };
};
