import {
    GET_GROUP,
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR,
    SEARCH_GROUPS,
    SEARCH_GROUPS_SUCCESS,
    AUTOCOMPLETE_GROUPS,
    AUTOCOMPLETE_GROUPS_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {    
    group: undefined,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GROUP:
            return { ...state, group: null }
        case GET_GROUP_SUCCESS:
            return { ...state, group: action.data }
        case GET_GROUP_ERROR:
            return { ...state, group: action }
        case SEARCH_GROUPS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_GROUPS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_GROUPS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_GROUPS_SUCCESS:
            return { ...state, autocompleteData: action.payload }  
        default:
            return state
    }
}

export default rootReducer