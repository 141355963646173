import {
    AUTOCOMPLETE_GROUPS_SUCCESS,
    AUTOCOMPLETE_GROUPS,
    GET_GROUP,
    SEARCH_GROUPS_SUCCESS,
    SEARCH_GROUPS,
} from '../constants/ActionsTypes';

export const getGroup = (groupId, token) => {
    return {
        type: GET_GROUP,
        payload: { groupId, token },
    };
};

export const searchGroups = (params) => {
    return {
        type: SEARCH_GROUPS,
        payload: params,
    };
};

export const searchGroupsSuccess = (data) => {
    return {
        type: SEARCH_GROUPS_SUCCESS,
        payload: data,
    };
};

export const autocompleteGroups = (params) => {
    return {
        type: AUTOCOMPLETE_GROUPS,
        payload: params,
    };
};

export const autocompleteGroupsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_GROUPS_SUCCESS,
        payload: data,
    };
};
