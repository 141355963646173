import {
    SEARCH_CLIENTS,
    SEARCH_CLIENTS_SUCCESS,
    AUTOCOMPLETE_CLIENTS,
    AUTOCOMPLETE_CLIENTS_SUCCESS,
    AUTOCOMPLETE_MY_CLIENTS,
    AUTOCOMPLETE_MY_CLIENTS_SUCCESS,
    CLEAR_AUTOCOMPLETE_CLIENTS
} from '../constants/ActionsTypes'

  const initialState = {}

  function rootReducer(state = initialState, action) {
    switch (action.type) {
      case SEARCH_CLIENTS:
        return { ...state, searchRequest: action.payload }
      case SEARCH_CLIENTS_SUCCESS:
        return { ...state, search: action.payload.response }
      case AUTOCOMPLETE_CLIENTS:
        return { ...state, autocompleteData: null }
      case AUTOCOMPLETE_CLIENTS_SUCCESS:
        return { ...state, autocompleteData: action.payload }
      case AUTOCOMPLETE_MY_CLIENTS:
        return { ...state, autocompleteData: null }
      case AUTOCOMPLETE_MY_CLIENTS_SUCCESS:
        return { ...state, autocompleteData: action.payload }
      case CLEAR_AUTOCOMPLETE_CLIENTS:
        return { ...state, autocompleteData: null }
      default:
        return state
    }
  }

  export default rootReducer