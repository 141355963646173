import { all, call, fork, put, takeEvery, takeFirst } from 'redux-saga/effects';
import { getStatisticsSuccess } from '../actions/Statistics'

import {
  GET_STATISTICS,
} from '../constants/ActionsTypes';

import {
  getStatisticsRequest,
} from '../api/Statistics';

function* getStatistics({ payload }) {

  const { clientCode, dateFrom, dateTo, pageSize, page } = payload;
  try {
    const response = yield call(getStatisticsRequest, clientCode, dateFrom, dateTo, pageSize, page);
    if (response) {
      yield put(getStatisticsSuccess(response));
    }
  } catch (error) {
    // handled in interceptor
  }
}

export function* getStatisticsSaga() {
  yield takeEvery(GET_STATISTICS, getStatistics);
}

export default function* rootSaga() {
  yield all([
    fork(getStatisticsSaga),
  ]);
}