import {
    SAVE_FILE_APPLICATIONS,
    SAVE_FILE_APPLICATIONS_SUCCESS,
    SAVE_FILE_APPLICATIONS_ERROR,
    EXPORT_APPLICATIONS,
    EXPORT_APPLICATIONS_SUCCESS,
    EXPORT_APPLICATIONS_ERROR
} from '../constants/ActionsTypes';

const initialState = {
    applications_success: false,
    applications_error: false,
    export_application_success: false,
    export_application_error: false,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_FILE_APPLICATIONS:
            return { ...state,  applications_success: false, applications_error: false };
        case SAVE_FILE_APPLICATIONS_SUCCESS:
            return { ...state, applications_success: true };
        case SAVE_FILE_APPLICATIONS_ERROR: {
            return { ...state, applications_error: true}
        }
        case EXPORT_APPLICATIONS:
            return { ...state, export_application_success: false, export_application_error: false };
        case EXPORT_APPLICATIONS_SUCCESS:
            return { ...state, export_application_success: true };
        case EXPORT_APPLICATIONS_ERROR:
            return { ...state, export_application_error: true };
        default:
            return state
    }
}

export default rootReducer
