import Axios from 'axios';

export const getProductsRequest = async () => {
    const response = await Axios.get('/productos');
    return response;
};

export const getProductRequest = async (productId) => {
    const response = await Axios.get(
        `/productos/${productId}?filter={"include": [{"relation": "linea","scope": { "include": { "relation": "sublinea","scope": {"include": {"relation": "agrupacion"}}}}}, {"relation":"categoria"}]}`
    );
    return response;
};

export const addImagesRequest = async (productId, updatable) => {
    const response = await Axios.patch(`productos/${productId}/updateImages`, updatable);
    return response;
};

export const removeImagesRequest = async (productId, updatable) => {
    const response = await Axios.post(
        `productos/${productId}/deleteImages`,
        updatable.imagesToRemove
    );
    return response;
};

export const searchProductsRequest = async (searchParams) => {
    const response = await Axios.post('/productos/search', searchParams);
    return response;
};

export const searchCatalogProductsRequest = async (searchParams) => {
    const response = await Axios.post('/productos/searchCatalogProducts', searchParams);
    return response;
};

export const getProductByCode = async (searchParams) => {
    const response = await Axios.post('/productos/getProductByCode', searchParams);
    return response;
};

export const getProductsByIds = async (payload) => {
    const response = await Axios.post('/productos/getProductsByIds', { productIds: payload });
    return response;
};

export const getProductPriceByClient = async (searchParams) => {
    const response = await Axios.post('/cotizaciones/getPriceProductByClient', searchParams);
    return response;
};

export const getApplicationByProductCode = async (searchParams) => {
    const response = await Axios.post('/productos/getApplicationByProductCode', searchParams);
    return response;
};

export const getApplicationByProductId = async (searchParams) => {
    const response = await Axios.post('/productos/getApplicationByProductId', searchParams);
    return response;
};

export const deleteApplicationForProduct = async (params) => {
    const response = await Axios.post('/productos/deleteApplicationForProduct', params);
    return response;
};

export const createApplicationForProduct = async (params) => {
    const response = await Axios.post('/productos/createApplicationForProduct', params);
    return response;
};

export const editApplicationForProduct = async (params) => {
    const response = await Axios.post('/productos/editApplicationForProduct', params);
    return response;
};
