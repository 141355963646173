import Axios from 'axios';

export const searchCategories = async (searchParams) => {
    const response = await Axios.post('/categorias/search', searchParams);
    return response.data;
};

export const getCategory = async (categoryId) => {
    const response = await Axios.get('/categorias/' + categoryId + '/caracteristica');
    return response.data;
};

export const getCategoryBrands = async () => {
    const response = await Axios.post('/tipoCaracteristicas/getByCode', { codigo: 'CGR_MARCA' });
    return response;
};

export const getCategoryModels = async () => {
    const response = await Axios.post('/tipoCaracteristicas/getByCode', { codigo: 'CGR_MDELO' });
    return response;
};

export const saveCategories = async (category) => {
    const response = await Axios.post('/categorias/createCategory', category);
    return response.data;
};

export const updateCategories = async (id, category) => {
    const response = await Axios.put(`/categorias/editCategory/${id}`, category);
    return response.data;
};
