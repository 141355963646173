import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import { connect } from "react-redux";
import withReduxSaga from 'next-redux-saga';
import { wrapper } from '../store';
import ReactGA from "react-ga4";
import initializeGoogleAnalytics from '../Utils/AnalyticsUtils';
import '../lib/i18n'
import '../lib/AxiosInterceptors'
import '../styles/style.less'
import { Modal, BackTop } from 'antd';
import { hideUpdateNotification, checkForUpdates } from '../actions';
import getConfig from 'next/config'
import { withTranslation } from 'react-i18next';
import CatalogItemDetailModal from '../components/catalog/catalog-item-detail-modal';
import DealModal from '../components/deals/dealModal';

const { publicRuntimeConfig } = getConfig();

class MyApp extends App {

updateAppVersion = () => {
    const { latestVersion } = this.props
    localStorage.setItem("appVersion", latestVersion);
    window.location.reload(true);
  };

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  componentDidMount() {
    const { checkForUpdatesDelay, GA_CODE } = publicRuntimeConfig;

    const startCheckForUpdates = () => {
      this.props.checkForUpdates();
      this.timerId = setTimeout(() => {
        startCheckForUpdates();
      }, checkForUpdatesDelay * 60 * 1000);
    };

    startCheckForUpdates();

    window.onbeforeunload = () => {
      clearTimeout(this.timerId);
    };

    const user = this.props.user ? this.props.user : null

    // Initialize your GA4
    initializeGoogleAnalytics(user);

    // Set up a listener for route changes to log page views
    Router.events.on('routeChangeComplete', (url) => {
      if (GA_CODE) {
        ReactGA.send({
          hitType: 'pageview',
          page: url,
          role: user && user.role ? user.role.name : null
        });
      }
    });

    window.onstorage = (event) => {
      // detect a new login in other tab
      if (event.key == "userId") {
        // lazy way of refreshing the app - a reloadApp action would be more elegant,
        // but the whole login process would need a redesign since it's broken down in
        // steps, all embedded in the login action (which takes place only in the tab
        // that fired the login action, not here)
        window.location.reload();
      }
    };
  }

  handleRouteChange = (url) => {
    const { GA_CODE } = publicRuntimeConfig;
    if (GA_CODE) {
      send({
        hitType: 'pageview',
        page: url,
      });
    }
  };

  componentWillUnmount() {
    // Remove the route change listener when the component unmounts
    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  handleCancel = () => {
    this.props.hideUpdateNotification();
  };

  render() {
    const { Component, pageProps, t } = this.props;
    const { showUpdateNotification } = this.props;

    return (
      <>
        {showUpdateNotification && (
          <Modal
            title={t('New version available')}
            style={{ top: 20 }}
            visible={showUpdateNotification}
            cancelText={t('Postpone')}
            okText={t('Update')}
            onOk={() => this.updateAppVersion()}
            onCancel={this.handleCancel}
          >
            <p>
              {t('New version text 1')}
            </p>
          </Modal>
        )}

        <Component {...pageProps} />

        <CatalogItemDetailModal />

        {/* {selectedItem && ( */}
          <DealModal
            // visible={showDetail}
            // item={selectedItem}
            onClose={this.handleCancel}
            t={t}
          />
        {/* )} */}
        <BackTop />
      </>
    );
  }

}
const mapStateToProps = ({ common, auth }) => {
  const { showUpdateNotification, hideUpdateNotification, latestVersion } = common;
  const { user } = auth;

  return {
    showUpdateNotification,
    hideUpdateNotification,
    latestVersion,
    user
  }
}

const mapDispatchToProps = dispatch => ({
  hideUpdateNotification: () => {
    dispatch(hideUpdateNotification());
  },
  checkForUpdates: () => {
    dispatch(checkForUpdates());
  },

});

export default wrapper.withRedux(withReduxSaga(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyApp))));
