import {
    SEARCH_SELLERS,
    SEARCH_SELLERS_SUCCESS,
    AUTOCOMPLETE_SELLERS,
    AUTOCOMPLETE_SELLERS_SUCCESS,
    CLEAR_AUTOCOMPLETE_SELLERS,
    SAVE_SELLER,
    SAVE_SELLER_SUCCESS,
    SAVE_SELLER_ERROR
} from '../constants/ActionsTypes'

const initialState = {
    isSaved: false
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_SELLERS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_SELLERS_SUCCESS:
            return { ...state, search: action.payload.response }

        case SAVE_SELLER:
            return { ...state, isSaved: false }
        case SAVE_SELLER_SUCCESS:
            return { ...state, isSaved: true }
        case SAVE_SELLER_ERROR:
            return { ...state, isSaved: false }

        case AUTOCOMPLETE_SELLERS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_SELLERS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case CLEAR_AUTOCOMPLETE_SELLERS:
            return { ...state, autocompleteData: null }
        default:
            return state
    }
}

export default rootReducer
