import {
    GET_CHARTS_INFO,
    GET_CHARTS_INFO_ERROR,
    GET_CHARTS_INFO_SUCCESS,
    GET_DETAIL_SELLE_INFO,
    GET_DETAIL_SELLE_INFO_ERROR,
    GET_DETAIL_SELLE_INFO_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
    charts: undefined,
    chart_success: undefined,
    seller: [],
    seller_success: undefined
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CHARTS_INFO:
            return { ...state, charts: null, chart_success: false };
        case GET_CHARTS_INFO_ERROR:
            return { ...state, chart_success: false };
        case GET_CHARTS_INFO_SUCCESS:
            return { ...state, charts: action.payload, chart_success: true };

        case GET_DETAIL_SELLE_INFO:
            return { ...state, seller: [], seller_success: false };
        case GET_DETAIL_SELLE_INFO_ERROR:
            return { ...state, seller_success: false };
        case GET_DETAIL_SELLE_INFO_SUCCESS:
            return { ...state, seller: action.payload, seller_success: true };

        default:
            return state
    }
}

export default rootReducer
