import {
    CONFIGURATE_CLIENT_UPDATE_SUCCESS,
    CONFIGURATE_CLIENT_UPDATE,
    GET_CONFIG_CLIENT_SUCCESS,
    GET_CONFIG_CLIENT,
    GET_LOGO_IMAGE_SUCCESS,
    GET_LOGO_IMAGE,
} from '../constants/ActionsTypes';

export const configurateClientUpdate = (params) => {
    return {
        type: CONFIGURATE_CLIENT_UPDATE,
        payload: params,
    };
};

export const configurateClientUpdateSuccess = (response) => {
    return {
        type: CONFIGURATE_CLIENT_UPDATE_SUCCESS,
        payload: response,
    };
};

export const getConfigClient = (params) => {
    return {
        type: GET_CONFIG_CLIENT,
        payload: params,
    };
};

export const getConfigClientSuccess = (config) => {
    return {
        type: GET_CONFIG_CLIENT_SUCCESS,
        payload: config,
    };
};

export const getLogoImage = (params) => {
    return {
        type: GET_LOGO_IMAGE,
        payload: params,
    };
};

export const getLogoImageSuccess = (config) => {
    return {
        type: GET_LOGO_IMAGE_SUCCESS,
        payload: config,
    };
};
