import {
    GET_LOCK_MARK,
    GET_LOCK_MARK_SUCCESS,
    GET_LOCK_MARK_ERROR,
    ADD_UPDATE_LOCK_MARK,
    ADD_UPDATE_LOCK_MARK_SUCCESS,
    ADD_UPDATE_LOCK_MARK_ERROR,
    SEARCH_LOCK_MARKS,
    SEARCH_LOCK_MARKS_SUCCESS,
    AUTOCOMPLETE_LOCK_MARKS,
    AUTOCOMPLETE_LOCK_MARKS_SUCCESS,
    AUTOCOMPLETE_LOCK_MARKS_ERROR
} from '../constants/ActionsTypes'

const initialState = {
    lockMark: undefined
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LOCK_MARK:
            return { ...state, lockMark: null, success: false }
        case GET_LOCK_MARK_SUCCESS:
            return { ...state, lockMark: action.data }
        case GET_LOCK_MARK_ERROR:
            return { ...state, lockMark: action }
        case ADD_UPDATE_LOCK_MARK:
            return { ...state, success: false }
        case ADD_UPDATE_LOCK_MARK_SUCCESS:
            return { ...state, success: true, lockMark: undefined }
        case ADD_UPDATE_LOCK_MARK_ERROR:
            return { ...state, success: false }
        case SEARCH_LOCK_MARKS:
            return { ...state, searchRequest: action.payload, search: null, success: false }
        case SEARCH_LOCK_MARKS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_LOCK_MARKS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_LOCK_MARKS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case AUTOCOMPLETE_LOCK_MARKS_ERROR:
            return { ...state, message: action.payload }
        default:
            return state
    }
}

export default rootReducer;
