import {
    AUTOCOMPLETE_TRANSPORTS_SUCCESS,
    AUTOCOMPLETE_TRANSPORTS,
    GET_TRANSPORT,
    GET_TRANSPORTS,
    SEARCH_TRANSPORTS_SUCCESS,
    SEARCH_TRANSPORTS,
} from '../constants/ActionsTypes';

export const getTransports = (token) => {
    return {
        type: GET_TRANSPORTS,
        payload: token,
    };
};

export const getTransport = (transportId, token) => {
    return {
        type: GET_TRANSPORT,
        payload: { transportId, token },
    };
};

export const searchTransports = (params) => {
    return {
        type: SEARCH_TRANSPORTS,
        payload: params,
    };
};

export const searchTransportsSuccess = (data) => {
    return {
        type: SEARCH_TRANSPORTS_SUCCESS,
        payload: data,
    };
};

export const autocompleteTransports = (params) => {
    return {
        type: AUTOCOMPLETE_TRANSPORTS,
        payload: params,
    };
};

export const autocompleteTransportsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_TRANSPORTS_SUCCESS,
        payload: data,
    };
};
