import React, { useState  } from "react";
import { Modal, Tooltip, Table, Badge } from "antd";
import { withTranslation } from "react-i18next";
import { isElectron } from "../../electron/tools";

const mapper = {
	'A': 'yellow',
	'V': 'green',
	'R': 'red',
};

const tableColumns = [
	{
		title: 'Office',
		dataIndex: 'office',
		key: 'office',
	},
	{
		title: 'Amount',
		key: 'semaphore',
		align: 'right',
		render: ({semaphore}) => (
			<div
				style={{marginRight: 12}}
				className={ `catalog-item-stock-status ${((semaphore && semaphore in mapper) ? mapper[semaphore] : "grey")}` }/>
		),
	},
];

function DisplayStock (props) {

	const [visible, setVisible] = useState(false);

	const { t, product, showNews } = props;
	let { semaphore } = props;

	if (!semaphore && product) {
		semaphore = { general: product.semaforo, details: [] };
        if(product.semaforo_1000) semaphore.details.push({ office: 'Mar del Plata', semaphore: product.semaforo_1000 });
		if (product.semaforo_1100) semaphore.details.push({ office: 'Bahía Blanca', semaphore: product.semaforo_1100 });
		if (product.semaforo_1200) semaphore.details.push({ office: 'Buenos Aires', semaphore: product.semaforo_1200 });
	}

	const showModalDetails = () => {
		setVisible(true);
	};

	const handleCloseModal = () => {
		setVisible(false);
	};

	const pickColor = (semaphore) => {
		return ((semaphore && semaphore in mapper) ? mapper[semaphore] : "grey");
	};

	const semaphoreColor = pickColor(semaphore.general);
	const officesAmount = semaphore.details.length;

	return (
		<div>
			{!isElectron() ?
				<Tooltip title={officesAmount ? t('STOCK_DETAILS') : ''}>
					<div
						style={officesAmount ? {cursor: 'pointer'} : {}}
						className={`catalog-item-stock-status ${semaphoreColor}`}
						onClick={showModalDetails}/>
				</Tooltip>
				:
				<div className={`catalog-item-stock-status ${semaphoreColor}`}/>
			}
			{showNews ?
				<Badge
				className="site-badge-count-109"
				count={t('IN_STOCK_AGAIN')}
				style={{ backgroundColor: '#09A511' }}
				/>
				// <div>Ingreso de stock!</div>
				:
				null
			}
			<Modal
				title={t('MODAL_DETAIL_TITLE_BRANCH_OFFICE')}
				open={visible && !!officesAmount}
				footer={false}
				onCancel={handleCloseModal}
				width={350}
			>
				<Table
					showHeader={false}
					pagination={false}
					yScroll={true}
					scroll={{y:240}}
					size={'small'}
					columns={tableColumns}
					dataSource={semaphore.details}
					rowKey={record => record.office}
				/>
			</Modal>
		</div>
	)
}

export default withTranslation()(DisplayStock);
