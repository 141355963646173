import {
    GET_BRANCHES_ERROR,
    GET_BRANCHES_SUCCESS,
    GET_BRANCH,
    GET_BRANCH_ERROR,
    GET_BRANCH_SUCCESS,
    SEARCH_BRANCHES,
    SEARCH_BRANCHES_SUCCESS,
    AUTOCOMPLETE_BRANCHES,
    AUTOCOMPLETE_BRANCHES_SUCCESS,
    EDIT_CONTACT_LINES,
    EDIT_CONTACT_LINES_SUCCESS,
} from '../constants/ActionsTypes'

const initialState = {
    branchList: [],
    branch: undefined,
    isEditedSuccess: false
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BRANCH:
            return { ...state, branch: null }
        case GET_BRANCH_SUCCESS:
            return { ...state, branch: action.data }
        case GET_BRANCH_ERROR:
            return { ...state, branch: action }
        case GET_BRANCHES_SUCCESS:
            return { ...state, branchList: action.data }
        case GET_BRANCHES_ERROR:
            return { ...state, branches: action }
        case SEARCH_BRANCHES:
            return { ...state, searchRequest: action.payload }
        case SEARCH_BRANCHES_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_BRANCHES:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_BRANCHES_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case EDIT_CONTACT_LINES:
            return { ...state, isEditedSuccess: false };
        case EDIT_CONTACT_LINES_SUCCESS:
            return { ...state, isEditedSuccess: true };
        default:
            return state
    }
}

export default rootReducer