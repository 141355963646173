import Axios from 'axios';
import NProgress from 'nprogress'
import getConfig from 'next/config'
import { showLoader, hideLoader, userSignOut, showMessage, goOffline } from '../actions';
import { isElectron } from '../electron/tools'

const createOfflineRequestAndSetupAxios = (originalRequest) => {
  const { publicRuntimeConfig } = getConfig();

  //Set Axios offline url
  Axios.defaults.baseURL = publicRuntimeConfig.apiOfflineUrl;

  //Set offline user id
  const userId = localStorage.getItem('userId');
  if (userId) {
    Axios.defaults.headers.common['Offline'] = userId;
  }

  const offlineUrl = originalRequest.url.replace(publicRuntimeConfig.apiUrl, publicRuntimeConfig.apiOfflineUrl);

  const retryReq = {
    ...originalRequest,
    url: offlineUrl,
    baseURL: publicRuntimeConfig.apiOfflineUrl,
    headers: {
      ...originalRequest.headers,
      Offline: userId
    }
  }

  return retryReq
}

export default function configureAxios(store) {
  const { publicRuntimeConfig } = getConfig();

  Axios.defaults.baseURL = publicRuntimeConfig.apiUrl;
  Axios.defaults.timeout = 60000;

  Axios.interceptors.request.use((config) => {
    //Before request is sent

    NProgress.start();

    store.dispatch(showLoader())

    return config;
  }, function (error) {
    //Request error

    return Promise.reject(error);
  });

  // Add a response interceptor
  Axios.interceptors.response.use((response) => {
    //Response

    store.dispatch(hideLoader());

    setTimeout(() => {
      NProgress.done();
    }, 1000);

    return response;
  }, function (error) {
    //Response error
    store.dispatch(hideLoader());

    setTimeout(() => {
      NProgress.done();
    }, 1000);

    if (window.loggingOut) {
      // logout request failed, possibly had an old token. Ignore the error and proceed.
      delete window.loggingOut;
    }

    if (error && error.code && error.code === 'ECONNABORTED') {
      store.dispatch(showMessage({
        type: 'error',
        message: 'Error',
        description: "Timeout error",
      }));
    } else if (!error.response && error.request && error.request.status === 0) {

      if (isElectron()) {
        const state = store.getState()

        if (!state.catalog.offline) {

          store.dispatch(showMessage({
            type: 'error',
            message: 'Error',
            description: "Network error",
          }));


          // Setup offline mode
          const retryReq = createOfflineRequestAndSetupAxios(error.config);

          store.dispatch(goOffline());

          // Retry offline request
          return Axios(retryReq)
        }
      }

    } else if (error.response && error.response.request.responseType === 'arraybuffer'
        && error.response.data.toString() === '[object ArrayBuffer]') {
      // only enters when the error response is of arraybuffer type
      if (error.response.status === 404) {
        store.dispatch(showMessage({
          type: 'error',
          message: 'Error',
          description: 'The required resource was not found',
        }));
      } else {
        store.dispatch(showMessage({
          type: 'error',
          message: 'Error',
          description: (JSON.parse(Buffer.from(error.response.data).toString('utf8')))
        }));
      }
    } else {
      switch (error.response.status) {
        case 401:
          // Skip on logout to avoid loop
          if (!error.response.config.url.includes('/usuarios/logout')){
            store.dispatch(userSignOut());
          }
          break;
        case 404:
          store.dispatch(showMessage({
            type: 'error',
            message: 'Error 404',
            description: error.response.data && error.response.data.message ? error.response.data.message : 'The required resource was not found'
          }));
          break;
        case 403:
          store.dispatch(showMessage({
            type: 'error',
            message: 'Error',
            description: 'You are not authorized to access the resource'
          }));
          break;
        case 409:
          store.dispatch(showMessage({
            type: 'error',
            message: 'Error',
            description: error.response.data.message
          }));
          break;
        case 422:
          const isText = !/\{([^}]+)\}/.test(error.response.data.message);
          const message = error.response.data && isText ? error.response.data : 'Error communicating with server';
          store.dispatch(showMessage({
            type: 'error',
            message: 'Error',
            description: message
          }));
          break;
        default:
          store.dispatch(showMessage({
            type: 'error',
            message: 'Error',
            description: 'Error communicating with server',
          }));
          break;
      }
    }

    return Promise.reject(error);
  });

  //Set initial token from store when loading a direct URL
  if (typeof window !== 'undefined') {
    const token = window.localStorage.getItem('token');
    if (token) {
      Axios.defaults.headers.common['Authorization'] = `${token}`;
    }
  }
}
