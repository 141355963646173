import Axios from 'axios';

export const getCurrentQuotation = async (currentQuotationId) => {
    let params = {};

    if (currentQuotationId) {
        params.id = currentQuotationId;
    }

    const response = await Axios.get('/cotizaciones/current', { params });
    return response;
};

export const newQuotation = async () => {
    const response = await Axios.get('/cotizaciones/newQuotation');
    return response;
};

export const addItemQuotation = async (cotizacionId, item) => {
    const response = await Axios.post(`/cotizaciones/${cotizacionId}/addItem/`, item);
    return response;
};

export const addItemQuotationFromCode = async (cotizacionId, params) => {
    const response = await Axios.post(
        `/cotizaciones/${cotizacionId}/addProductToQuotation/`,
        params
    );
    return response;
};

export const changeItemQuantityQuotation = async (params) => {
    const id = params.cotizacionId;
    const payload = {
        codigodearticulo: params.codigodearticulo,
        quantity: params.quantity,
    };
    const response = await Axios.post(`/cotizaciones/${id}/changeItemQuantity/`, payload);
    return response;
};

export const removeItemQuotation = async (params) => {
    const id = params.cotizacionId;
    const response = await Axios.post(`/cotizaciones/${id}/removeItem/`, {
        codigodearticulo: params.codigodearticulo,
    });
    return response;
};

export const setDataToQuotation = async (params) => {
    const id = params.cotizacionId;
    const payload = {
        type: params.type,
        value: params.value,
    };
    const response = await Axios.post(`/cotizaciones/${id}/setData/`, payload);
    return response;
};

export const saveQuotation = async (id) => {
    const response = await Axios.post(`/cotizaciones/${id}/saveQuotation`);
    return response;
};

export const cancelQuotation = async (id) => {
    const response = await Axios.post(`/cotizaciones/${id}/cancelQuotation`);
    return response;
};

export const getQuotationsByUser = async (params) => {
    const response = await Axios.post('/cotizaciones/getQuotationsByClient', params);
    return response;
};

export const getQuotationById = async (params) => {
    const response = await Axios.post('/cotizaciones/getQuotationById', params);
    return response;
};

export const addProductsToCurrent = async (id) => {
    const response = await Axios.post(`/cotizaciones/${id}/addProductsToCurrent`);
    return response;
};
