const displayMapper = {
  '0': '0%',
  '1': '21%',
  '2': '10.5%',
  '3': '27%',
}

const calcMapper = {
  '0': 0,
  '1': 1.21,
  '2': 1.105,
  '3': 1.27,
}

const getIvaToDisplay = (iva) => {
  return (iva && iva in displayMapper) ? displayMapper[iva] : "S/D"
}

const getIvaToCalc = (iva) => {
  return (iva && iva in calcMapper) ? calcMapper[iva] : null
}

module.exports = {
  getIvaToDisplay,
  getIvaToCalc
}
