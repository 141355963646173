import {
  ADD_PRODUCT_TO_FAVORITES_ERROR,
  ADD_PRODUCT_TO_FAVORITES_SUCCESS,
  ADD_PRODUCT_TO_FAVORITES,
  ADD_OBSERVATION_TO_FAVORITE,
  ADD_OBSERVATION_TO_FAVORITE_SUCCESS,
  ADD_OBSERVATION_TO_FAVORITE_ERROR,
  GET_MY_FAVORITE_PRODUCTS_ERROR,
  GET_MY_FAVORITE_PRODUCTS_SUCCESS,
  GET_MY_FAVORITE_PRODUCTS,
  GET_USER_CONFIGURATIONS_ERROR,
  GET_USER_CONFIGURATIONS_SUCCESS,
  GET_USER_CONFIGURATIONS,
  REMOVE_PRODUCT_TO_FAVORITES_ERROR,
  REMOVE_PRODUCT_TO_FAVORITES_SUCCESS,
  REMOVE_PRODUCT_TO_FAVORITES,
  SET_USER_CONFIGURATIONS_ERROR,
  SET_USER_CONFIGURATIONS_SUCCESS,
  SET_USER_CONFIGURATIONS,
} from '../constants/ActionsTypes'

export const getUserConfig = () => {
  return {
    type: GET_USER_CONFIGURATIONS,
  }
}

export const getUserConfigSuccess = (configs) => {
  return {
    type: GET_USER_CONFIGURATIONS_SUCCESS,
    payload: configs,
  }
}

export const getUserConfigError = (error) => {
  return {
    type: GET_USER_CONFIGURATIONS_ERROR,
    payload: error,
  }
}

export const setUserConfig = (params) => {
  return {
    type: SET_USER_CONFIGURATIONS,
    payload: params,
  }
}

export const setUserConfigSuccess = (configs) => {
  return {
    type: SET_USER_CONFIGURATIONS_SUCCESS,
    payload: configs,
  }
}

export const setUserConfigError = (error) => {
  return {
    type: SET_USER_CONFIGURATIONS_ERROR,
    payload: error,
  }
}

export const addProductToFavorites = (param) => {
  return {
    type: ADD_PRODUCT_TO_FAVORITES,
    payload: param,
  }
}

export const addProductToFavoritesSuccess = (configs) => {
  return {
    type: ADD_PRODUCT_TO_FAVORITES_SUCCESS,
    payload: configs,
  }
}

export const addProductToFavoritesError = (error) => {
  return {
    type: ADD_PRODUCT_TO_FAVORITES_ERROR,
    payload: error,
  }
}

export const removeProductToFavorites = (param) => {
  return {
    type: REMOVE_PRODUCT_TO_FAVORITES,
    payload: param,
  }
}

export const addObservationToFavoriteSuccess = (configs) => {
  return {
    type: ADD_OBSERVATION_TO_FAVORITE_SUCCESS,
    payload: configs,
  }
}

export const addObservationToFavoriteError = (error) => {
  return {
    type: ADD_OBSERVATION_TO_FAVORITE_ERROR,
    payload: error,
  }
}

export const addObservationToFavorite = (params) => {
  return {
    type: ADD_OBSERVATION_TO_FAVORITE,
    payload: params,
  }
}

export const removeProductToFavoritesSuccess = (configs) => {
  return {
    type: REMOVE_PRODUCT_TO_FAVORITES_SUCCESS,
    payload: configs,
  }
}

export const removeProductToFavoritesError = (error) => {
  return {
    type: REMOVE_PRODUCT_TO_FAVORITES_ERROR,
    payload: error,
  }
}

export const getMyFavoriteProducts = (params) => {
  return {
    type: GET_MY_FAVORITE_PRODUCTS,
    payload: params,
  }
}

export const getMyFavoriteProductsSuccess = (products) => {
  return {
    type: GET_MY_FAVORITE_PRODUCTS_SUCCESS,
    payload: products,
  }
}

export const getMyFavoriteProductsError = (error) => {
  return {
    type: GET_MY_FAVORITE_PRODUCTS_ERROR,
    payload: error,
  }
}
