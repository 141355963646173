import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';

import {
    SEARCH_PERSONS,
    AUTOCOMPLETE_PERSONS,
    GET_PERSONS,
    GET_PERSON,
    CREATE_PERSON,
    EDIT_PERSON,
    DELETE_PERSON,
} from '../constants/ActionsTypes'

import {
    searchPersonsSuccess,
    autocompletePersonsSuccess,
    getPersonsSuccess,
    getPersonSuccess,
    createPersonSuccess,
    editPersonSuccess,
    deletePersonSuccess,
} from '../actions';

import {
    searchPersons,
    getPersons,
    getPerson,
    createPerson,
    editPerson,
    deletePerson
} from '../api/Persons';

const getCommonState = (state) => state.common;

function* searchPersonsRequest({ payload }) {
    try {
        const params = yield select(getCommonState);
        const { showRemove } = params.AdminActionBar;

        if(showRemove){
            payload.borrado = showRemove;
        }

        const data = yield call(searchPersons, payload);
        yield put(searchPersonsSuccess(data));
    } catch (error) {
    }
}

function* autocompletePersonsRequest({ payload }) {
    try {
        const data = yield call(searchPersons, payload);
        yield put(autocompletePersonsSuccess(data));
    } catch (error) {
    }
}

function* getPersonsRequest({ payload }) {
    try {
        const {data} = yield call(getPersons, payload);
        yield put(getPersonsSuccess(data));
    } catch (error) {
    }
}

function* getPersonRequest({ payload }) {
    try {
        const {data} = yield call(getPerson, payload);
        yield put(getPersonSuccess(data));
    } catch (error) {
    }
}

function* createPersonRequest({ payload }) {
    try {
        const {data} = yield call(createPerson, payload);
        yield put(createPersonSuccess(data));
    } catch (error) {
    }
}

function* editPersonRequest({ payload }) {
    try {
        const {data} = yield call(editPerson, payload);
        yield put(editPersonSuccess(data));
    } catch (error) {
    }
}

function* deletePersonRequest({ payload }) {
    try {
        yield call(deletePerson, payload);
        yield put(deletePersonSuccess());
    } catch (error) {
    }
}



export function* searchPersonsSaga() {
    yield takeLatest(SEARCH_PERSONS, searchPersonsRequest);
}

export function* autocompletePersonsSaga() {
    yield takeLatest(AUTOCOMPLETE_PERSONS, autocompletePersonsRequest);
}

export function* getPersonsSaga() {
    yield takeLatest(GET_PERSONS, getPersonsRequest);
}

export function* getPersonSaga() {
    yield takeLatest(GET_PERSON, getPersonRequest);
}

export function* createPersonSaga() {
    yield takeLatest(CREATE_PERSON, createPersonRequest);
}

export function* editPersonSaga() {
    yield takeLatest(EDIT_PERSON, editPersonRequest);
}

export function* deletePersonSaga() {
    yield takeLatest(DELETE_PERSON, deletePersonRequest);
}

export default function* rootSaga() {
    yield all([
        fork(searchPersonsSaga),
        fork(autocompletePersonsSaga),
        fork(getPersonsSaga),
        fork(getPersonSaga),
        fork(createPersonSaga),
        fork(editPersonSaga),
        fork(deletePersonSaga)
    ]);
}
