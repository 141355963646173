import {
    ADD_BANNER_SUCCESS,
    ADD_BANNER,
    AUTOCOMPLETE_BANNERS_SUCCESS,
    AUTOCOMPLETE_BANNERS,
    GET_BANNER_SUCCESS,
    GET_BANNER,
    SEARCH_BANNERS_BY_SEGMENT_SUCCESS,
    SEARCH_BANNERS_BY_SEGMENT,
    SEARCH_BANNERS_SUCCESS,
    SEARCH_BANNERS,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER,
} from '../constants/ActionsTypes';

export const getBanner = (params) => {
    return {
        type: GET_BANNER,
        payload: params,
    };
};

export const getBannerSuccess = (data) => {
    return {
        type: GET_BANNER_SUCCESS,
        payload: data,
    };
};

export const searchBanners = (params) => {
    return {
        type: SEARCH_BANNERS,
        payload: params,
    };
};

export const searchBannersSuccess = (data) => {
    return {
        type: SEARCH_BANNERS_SUCCESS,
        payload: data,
    };
};

export const autocompleteBanners = (params) => {
    return {
        type: AUTOCOMPLETE_BANNERS,
        payload: params,
    };
};

export const autocompleteBannersSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_BANNERS_SUCCESS,
        payload: data,
    };
};

export const addBanner = (params) => {
    return {
        type: ADD_BANNER,
        payload: params,
    };
};

export const addBannerSuccess = (data) => {
    return {
        type: ADD_BANNER_SUCCESS,
        payload: data,
    };
};

export const updateBanner = (bannerId, params) => {
    return {
        type: UPDATE_BANNER,
        payload: { bannerId, params },
    };
};

export const updateBannerSuccess = (data) => {
    return {
        type: UPDATE_BANNER_SUCCESS,
        payload: data,
    };
};

export const searchBannersBySegment = (params) => {
    return {
        type: SEARCH_BANNERS_BY_SEGMENT,
        payload: params,
    };
};

export const searchBannersBySegmentSuccess = (data) => {
    return {
        type: SEARCH_BANNERS_BY_SEGMENT_SUCCESS,
        payload: data,
    };
};
