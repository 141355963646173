import {all, call, fork, put, select, takeEvery, throttle} from 'redux-saga/effects';
import {
    searchCharacteristics,
    getCharacteristic,
    saveCharacteristic,
    updateCharacteristic
} from '../api/Characteristics'
import {
    SEARCH_CHARACTERISTICS,
    AUTOCOMPLETE_CHARACTERISTICS,
    GET_CHARACTERISTIC,
    SAVE_CHARACTERISTIC,
    UPDATE_CHARACTERISTIC
} from '../constants/ActionsTypes';
import {
    searchCharacteristicsSuccess,
    autocompleteCharacteristicsSuccess,
    getCharacteristicSuccess,
    saveCharacteristicSuccess,
    updateCharacteristicSuccess,
    clearCharacteristicSuccess
} from '../actions';
import {getCommonState} from "./Users";

function* searchCharacteristicsRequest({ payload }) {
    try {
        const params = yield select(getCommonState);
        const { AdminActionBar } = params;

        if(AdminActionBar.showRemove){
            payload.borrado = AdminActionBar.showRemove;
        }

        const response = yield call(searchCharacteristics, payload);
        yield put(searchCharacteristicsSuccess (response));
    } catch (error) {
        console.log(error);
    }
}

function* getCharacteristicRequest({ payload }) {
    const { characteristicId } = payload;
    try {
        const response = yield call(getCharacteristic, characteristicId);
        yield put(getCharacteristicSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

function* autocompleteCharacteristicsRequest({ payload }) {
    const query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }
    try {
        const response = yield call(searchCharacteristics, query);
        yield put(autocompleteCharacteristicsSuccess(response));
    } catch (error) {
        console.log(error)
    }
}

function* saveCharacteristicRequest({payload}) {
    try {
        yield call(saveCharacteristic, payload);
        yield put(saveCharacteristicSuccess());
        yield put(clearCharacteristicSuccess());
    } catch (error) {
        console.log(error)
    }
}

function* updateCharacteristicRequest({payload}) {
    try {
        yield call(updateCharacteristic, payload.id, payload.characteristic);
        yield put(updateCharacteristicSuccess());
        yield put(clearCharacteristicSuccess());
    } catch (error) {
        console.log(error)
    }
}

export function* searchCharacteristicsSaga() {
    yield takeEvery(SEARCH_CHARACTERISTICS, searchCharacteristicsRequest);
}

export function* autocompleteCharacteristicsSaga() {
    yield throttle(1000, AUTOCOMPLETE_CHARACTERISTICS, autocompleteCharacteristicsRequest);
}

export function* getCharacteristicSaga() {
    yield takeEvery(GET_CHARACTERISTIC, getCharacteristicRequest);
}

export function* saveCharacteristicSaga() {
    yield takeEvery(SAVE_CHARACTERISTIC, saveCharacteristicRequest);
}

export function* updateCharacteristicSaga() {
    yield takeEvery(UPDATE_CHARACTERISTIC, updateCharacteristicRequest);
}

export default function* rootSaga() {
    yield all([
        fork(searchCharacteristicsSaga),
        fork(autocompleteCharacteristicsSaga),
        fork(getCharacteristicSaga),
        fork(saveCharacteristicSaga),
        fork(updateCharacteristicSaga),
    ]);
}
