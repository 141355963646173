import Axios from 'axios';

const BASE_URL = '/ConfiguracionUsuarios';

export const getUserConfig = async () => {
    const response = await Axios.get(`${BASE_URL}/getUserConfig`);
    return response.data;
};

export const setUserConfig = async (params) => {
    const response = await Axios.post(`${BASE_URL}/setUserConfig`, params);
    return response.data;
};

export const getMyFavoriteProducts = async (params) => {
    const response = await Axios.post(`${BASE_URL}/getMyFavoriteProducts`, params);
    return response.data;
};

export const getNewsForFavorites = async () => {
    const response = await Axios.get(`${BASE_URL}/checkNewsForFavorites`);
    return response.data;
};
