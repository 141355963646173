const AVATAR_COLORS = ['#C41215', '#4483B3', '#FDC652', '#A8BF6D'];

function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * AVATAR_COLORS.length);
    return AVATAR_COLORS[randomIndex];
}

function getUserInitials(user) {
    if (user && user.cliente && user.cliente.nombre) {
        return user.cliente.nombre[0];
    } else if (user && user.vendedor && user.vendedor.nombre) {
        const splitName = user.vendedor.nombre.split(' ');
        if (splitName.length > 1) {
        return splitName[0][0] + splitName[1][0]; // Take the first letter of the first two words
        } else {
        return splitName[0][0]; // If just one word exists, return the first letter ot that only word
        }
    } else if (user && user.username && user.username === 'administrador') {
        return 'A';
    }
    return null;
}

export { getUserInitials, getRandomColor }