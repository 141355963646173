import Axios from 'axios';
const BASE_URL_END_POINT = '/personas';

export const searchPersons = async (params) => {
    const url = `${BASE_URL_END_POINT}/searchPeople`;
    const response = await Axios.post(url, params);
    return response.data;
};

export const getPersons = async () => {
    const url = `${BASE_URL_END_POINT}/listPeople`;
    const response = await Axios.get(url);
    return response.data;
};

export const getPerson = async (id) => {
    const url = `${BASE_URL_END_POINT}/listPeopleById?id=${id}`;
    const response = await Axios.get(url);
    return response.data;
};

export const createPerson = async (params) => {
    const url = `${BASE_URL_END_POINT}/createPeople`;
    const response = await Axios.post(url, params);
    return response.data;
};

export const editPerson = async (data) => {
    const url = `${BASE_URL_END_POINT}/editPeople?id=${data.id}`;
    const response = await Axios.put(url, data.params);
    return response.data;
};

export const deletePerson = async (id) => {
    const url = `${BASE_URL_END_POINT}/deletePeople?id=${id}`;
    const response = await Axios.delete(url);
    return response.data;
};
