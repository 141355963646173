import Axios from 'axios';

export const getTransports = async (token) => {
    const response = await Axios.get('/transportes?filter[limit]=10&filter[skip]=0', { token });
    return response;
};

export const getTransport = async (transportId, token) => {
    const response = await Axios.get('/transportes/' + transportId, {
        params: {
            access_token: token,
        },
    });
    return response;
};

export const searchTransports = async (searchParams) => {
    const response = await Axios.post('/transportes/search', searchParams);
    return response.data;
};
