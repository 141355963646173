import { all, call, fork, put, takeEvery, throttle, takeLatest, select } from 'redux-saga/effects';

import {
    ADD_DEAL,
    UPDATE_DEAL,
    ADD_DEAL_ERROR,
    UPDATE_DEAL_ERROR,
    ADD_DEAL_SUCCESS,
    UPDATE_DEAL_SUCCESS,
    SEARCH_DEALS,
    AUTOCOMPLETE_DEALS,
    GET_DEAL,
    REMOVE_DEAL,
    GET_DEALS_SEGMENT,
    GET_DEAL_PRODUCT_ID,
    GET_DEALS_TREE,
    GET_DEALS_TREE_SUCCESS,
    GET_DEALS_BY_BRAND,
} from '../constants/ActionsTypes';

import {
    addDeal,
    updateDeal,
    searchDeals,
    getDeal,
    removeDeal,
    getDealsBySegment,
    getDealProductById,
    getDealsTree,
    getDealsByBrand,
    addRemoveProducts,
} from '../api/Deals';


import {
    searchDealsSuccess,
    autocompleteDealsSuccess,
    getDealSuccess,
    removeDealSuccess,
    getDealsBySegmentSuccess,
    getDealsByBrandSuccess,
    getDealProductByIdSuccess
} from '../actions';

export const getCommonState = (state) => state.common;

function* updateDealsRequest({payload}) {
    try {
        const { dealId, params } = payload;
        const deal = yield call(updateDeal,dealId, params);
        yield put({ type: UPDATE_DEAL_SUCCESS, deal });
    } catch (error) {
        yield put({ type: UPDATE_DEAL_ERROR, error });
    }
}

function* addDealsRequest({payload}) {
    try {
        const { params } = payload;
        const deal = yield call(addDeal, params);
        yield put({ type: ADD_DEAL_SUCCESS, deal });
    } catch (error) {
        yield put({ type: ADD_DEAL_ERROR, error });
    }
}

function* searchDealsRequest({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if(showRemove){
        payload.params.borrado = showRemove;
    }

    try {
        const search = yield call(searchDeals, payload.params);
        yield put(searchDealsSuccess(search));
    } catch (error) {
        yield put({ type: ADD_DEAL_ERROR, error });
    }
}

function* autocompleteDealsRequest({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if(showRemove){
        payload.params.borrado = showRemove;
    }

    try {
        const deals = yield call(searchDeals, payload.params);
        yield put(autocompleteDealsSuccess(deals));
    } catch (error) {
    }
}

function* getDealRequest({ payload }) {

    try {
        const {data} = yield call(getDeal, payload.dealId);
        yield put(getDealSuccess(data));
    } catch (error) {
        yield put({ type: ADD_DEAL_ERROR, error });
    }
}

function* removeDealRequest({ payload }) {
    try {
        const dealResponse = yield call(removeDeal, payload.dealId);
        yield put(removeDealSuccess(dealResponse));
    } catch (error) {
        yield put({ type: ADD_DEAL_ERROR, error });
    }
}

function* getDealsBySegmentRequest({payload}) {
    try {
        const response = yield call(getDealsBySegment, payload);
        yield put( getDealsBySegmentSuccess(response));
    } catch (error) {
        yield put({ type: ADD_DEAL_ERROR, error });
    }
}

function* getDealProductByIdRequest({payload}) {
    try {
        const product = yield call(getDealProductById, payload);
        yield put( getDealProductByIdSuccess(product));
    } catch (error) {
    }
}

function* callGetDealsTreeRequest() {
    try {
            //We get the SegmentIds from the user.
            const response = yield call(getDealsTree);
            yield put({ type: GET_DEALS_TREE_SUCCESS, data: response.response.data });
    } catch (error) {
    }
}

function* getDealsByBrandRequest({ payload }) {
    try {
        const response = yield call(getDealsByBrand, payload);
        yield put( getDealsByBrandSuccess(response));
    } catch (error) {
    }
}

export function* addDealSaga() {
    yield takeEvery(ADD_DEAL, addDealsRequest);
}

export function* updateDealSaga() {
    yield takeEvery(UPDATE_DEAL, updateDealsRequest);
}

export function* searchDealSaga() {
    yield takeEvery(SEARCH_DEALS, searchDealsRequest);
}

export function* autocompleteDealSaga() {
    yield throttle(1000, AUTOCOMPLETE_DEALS, autocompleteDealsRequest);
}

export function* getDealSaga() {
    yield takeEvery(GET_DEAL, getDealRequest);
}

export function* removeDealSaga() {
    yield takeEvery(REMOVE_DEAL, removeDealRequest);
}

export function* getDealsBySegmentSaga() {
    yield takeEvery( GET_DEALS_SEGMENT , getDealsBySegmentRequest);
}

export function* getDealProductByIdSaga() {
    yield takeEvery( GET_DEAL_PRODUCT_ID , getDealProductByIdRequest);
}

export function* getDealsTreeSaga() {
    yield takeLatest(GET_DEALS_TREE, callGetDealsTreeRequest);
}

export function* getDealsByBrandSaga() {
    yield takeEvery(GET_DEALS_BY_BRAND, getDealsByBrandRequest);
}

export default function* rootSaga() {
    yield all([
        fork(addDealSaga),
        fork(updateDealSaga),
        fork(searchDealSaga),
        fork(autocompleteDealSaga),
        fork(getDealsTreeSaga),
        fork(getDealsByBrandSaga),
        fork(getDealSaga),
        fork(getDealsBySegmentSaga),
        fork(getDealProductByIdSaga),
        fork(removeDealSaga),
    ]);
}
