import {
    ADD_UPDATE_BRAND,
    GET_BRAND,
    GET_BRANDS_ERROR,
    GET_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    GET_ALL_BRANDS_SUCCESS,
    SEARCH_BRANDS,
    SEARCH_BRANDS_SUCCESS,
    AUTOCOMPLETE_BRANDS,
    AUTOCOMPLETE_BRANDS_SUCCESS,
    AUTOCOMPLETE_BRANDS_ERROR,
    ADD_UPDATE_BRAND_SUCCESS,
    ADD_UPDATE_BRAND_ERROR
} from '../constants/ActionsTypes'

const initialState = {
    brand: undefined,
    allBrands: null,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_UPDATE_BRAND:
            return { ...state, success: false }
        case ADD_UPDATE_BRAND_SUCCESS:
            return { ...state, success: true, brand: undefined }
        case ADD_UPDATE_BRAND_ERROR:
            return { ...state, success: false }
        case GET_BRAND:
            return { ...state, brand: null, success: false }
        case GET_BRAND_SUCCESS:
            return { ...state, brand: action.data }
        case GET_BRAND_ERROR:
            return { ...state, brand: action }
        case GET_BRANDS_ERROR:
            return { ...state, brands: action }
        case GET_ALL_BRANDS_SUCCESS:
            return { ...state, allBrands: action.data }
        case SEARCH_BRANDS:
            return { ...state, searchRequest: action.payload, search: null }
        case SEARCH_BRANDS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_BRANDS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_BRANDS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case AUTOCOMPLETE_BRANDS_ERROR:
            return { ...state, message: action.payload }
        default:
            return state
    }
}

export default rootReducer