import { all, call, fork, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as moment from 'moment';

import {
    SAVE_APPOINTMENT,
    UPDATE_APPOINTMENT,
    GET_APPOINTMENT,
    GET_APPOINTMENT_STATES,
    GET_APPOINTMENT_ACTIONS,
    GET_MY_APPOINTMENTS,
    GET_MY_APPOINTMENTS_GROUP,
    DELETE_APPOINTMENT,
    EXCEL_EXPORT_APPOINTMENT,
    GET_APPOINTMENT_EVENTS,
    GET_ACTIONS_GROUPED_BY_SELLER,
    GET_AMOUNT_OF_ACTIONS_BY_SELLER
} from '../constants/ActionsTypes';

import {
    saveAppointmentError,
    saveAppointmentSuccess,
    updateAppointmentError,
    updateAppointmentSuccess,
    getAppointmentError,
    getAppointmentSuccess,
    getAppointmentStatesError,
    getAppointmentStatesSuccess,
    getAppointmentActionsError,
    getAppointmentActionsSuccess,
    getMyAppointmentsSuccess,
    getMyAppointmentsError,
    getMyAppointmentsGroupSuccess,
    getMyAppointmentsGroupError,
    deleteAppointmentSuccess,
    deleteAppointmentError,
    getAppointmentEventsError,
    getAppointmentEventsSuccess,
    getActionsGroupedBySellerSuccess,
    getAmountOfActionsBySellerSuccess
} from '../actions/Appointment';

import {
    getMyAppointments,
    getMyAppointmentsGroup,
    createAppointment,
    updateAppointment,
    getAppointment,
    getAppointmentActions,
    getAppointmentStates,
    deleteAppointment,
    excelExport,
    getAppointmentEvents,
    actionsGroupedBySeller,
    amountOfActionsBySeller
} from '../api/Appointment';

export const getCommonState = (state) => state.common;
const getSelectedDate = (state) => state.appointment.selectedDate

export function* getAppointmentSaga() {
    yield takeLatest(GET_APPOINTMENT, callGetAppointment);
}

function* callGetAppointment({ payload }) {
    const { AppointmentId, filter } = payload;
    try {
        const response = yield call(getAppointment, AppointmentId, filter);
        yield put(getAppointmentSuccess(response));
    } catch (error) {
        yield put(getAppointmentError(error));
    }
}

export function* saveAppointmentSaga() {
    yield takeLatest(SAVE_APPOINTMENT, saveAppointmentsRequest);
}

function* saveAppointmentsRequest({ payload }) {
    try {
        const response = yield call(createAppointment, payload);
        yield put(saveAppointmentSuccess(response));
    } catch (error) {
        yield put(saveAppointmentError(error));
    }
}

export function* updateAppointmentSaga() {
    yield takeLatest(UPDATE_APPOINTMENT, updateAppointmentsRequest);
}

function* updateAppointmentsRequest({ payload }) {
    try {
        const {appointmentId, params} = payload;
        const response = yield call(updateAppointment, appointmentId, params);
        yield put(updateAppointmentSuccess(response));
    } catch (error) {
        yield put(updateAppointmentError(error));
    }
}

export function* getAppointmentStatesSaga() {
    yield takeEvery(GET_APPOINTMENT_STATES, getAppointmentStateRequest);
}

function* getAppointmentStateRequest({ payload }) {
    try {
        const response = yield call(getAppointmentStates, payload);
        yield put(getAppointmentStatesSuccess(response));
    } catch (error) {
        yield put(getAppointmentStatesError(error));
    }
}

export function* getAppointmentActionsSaga() {
    yield takeEvery(GET_APPOINTMENT_ACTIONS, getAppointmentActionsRequest);
}

function* getAppointmentActionsRequest({ payload }) {
    try {
        const response = yield call(getAppointmentActions, payload);
        yield put(getAppointmentActionsSuccess(response));
    } catch (error) {
        yield put(getAppointmentActionsError(error));
    }
}

export function* getAppointmentEventsSaga() {
    yield takeEvery(GET_APPOINTMENT_EVENTS, getAppointmentEventsRequest);
}

function* getAppointmentEventsRequest({ payload }) {
    try {
        const response = yield call(getAppointmentEvents, payload);
        yield put(getAppointmentEventsSuccess(response));
    } catch (error) {
        yield put(getAppointmentEventsError(error));
    }
}

export function* getMyAppointmentsSaga() {
    yield takeEvery(GET_MY_APPOINTMENTS, getMyAppointmentsRequest);
}

function* getMyAppointmentsRequest({ payload }) {
    try {
        const response = yield call(getMyAppointments, payload);

        // Parse events to fullcalendar format
        let events = [];
        if(response.data) {

            const now = moment().startOf('day');
            events = response.data.map( event => {

                const eventDate = moment(event.start_dateTime).startOf('day');
                const eventClass =
                    now.diff(eventDate, 'days') < 0 ? 'calendar-event-future' : 'calendar-event-' + event.appointment_state

                return {
                    id: event.id,
                    title: event.title,
                    start: event.start_dateTime,
                    end: event.end_dateTime,
                    className: eventClass,
                    data: event,
                }
            })
        }

        yield put(getMyAppointmentsSuccess(events));
    } catch (error) {
        yield put(getMyAppointmentsError(error));
    }
}

export function* getMyAppointmentsGroupSaga() {
    yield takeEvery(GET_MY_APPOINTMENTS_GROUP, getMyAppointmentsGroupRequest);
}

function* getMyAppointmentsGroupRequest({ payload }) {
    try {
        const response = yield call(getMyAppointmentsGroup, payload);

        // Parse events to fullcalendar format
        let events = [];
        if(response.data) {
            events = response.data.map( event => {

                const eventDate = moment(event._id);

                return {
                    id: event._id,
                    start: moment(eventDate).startOf('day').toDate(),
                    end: moment(eventDate).endOf('day').toDate(),
                    data: event,
                }
            })
        }

        yield put(getMyAppointmentsGroupSuccess(events));
    } catch (error) {
        yield put(getMyAppointmentsGroupError(error));
    }
}

function* actionsGroupedBySellerRequest({ payload }) {
    try {
        const {data} = yield call(actionsGroupedBySeller, payload);
        yield put(getActionsGroupedBySellerSuccess(data));
    } catch (error) {
    }
}

function* amountOfActionsBySellerRequest({ payload }) {
    try {
        const {data} = yield call(amountOfActionsBySeller, payload);
        yield put(getAmountOfActionsBySellerSuccess(data));
    } catch (error) {
    }
}

export function* deleteAppointmentSaga() {
    yield takeLatest(DELETE_APPOINTMENT, deleteAppointmentsRequest);
}

function* deleteAppointmentsRequest({ payload }) {
    try {
        const { appointmentId } = payload
        const response = yield call(deleteAppointment, appointmentId);

        let selectedDate = yield select(getSelectedDate)
        const dateFrom = moment(selectedDate).startOf('month').format('YYYY-MM-DD');
        const dateTo = moment(selectedDate).endOf('month').format('YYYY-MM-DD');

        yield getMyAppointmentsRequest({ payload: { dateFrom, dateTo }})

        yield put(deleteAppointmentSuccess(response))
    } catch (error) {
        yield put(deleteAppointmentError(error));
    }
}

export function* excelExportAppointmentSaga() {
    yield takeLatest(EXCEL_EXPORT_APPOINTMENT, excelExportAppointmentRequest);
}

function* excelExportAppointmentRequest({ payload }) {
    try {
        yield call(excelExport, payload);
    } catch (error) {

    }
}

export function* actionsGroupedBySellerSaga() {
    yield takeLatest(GET_ACTIONS_GROUPED_BY_SELLER, actionsGroupedBySellerRequest);
}

export function* amountOfActionsBySellerSaga() {
    yield takeLatest(GET_AMOUNT_OF_ACTIONS_BY_SELLER, amountOfActionsBySellerRequest);
}

export default function* rootSaga() {
    yield all([
      fork(saveAppointmentSaga),
      fork(updateAppointmentSaga),
      fork(getAppointmentStatesSaga),
      fork(getAppointmentActionsSaga),
      fork(getAppointmentSaga),
      fork(getMyAppointmentsSaga),
      fork(getMyAppointmentsGroupSaga),
      fork(deleteAppointmentSaga),
      fork(excelExportAppointmentSaga),
      fork(getAppointmentEventsSaga),
      fork(actionsGroupedBySellerSaga),
      fork(amountOfActionsBySellerSaga),
    ]);
}
