import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {createWrapper} from 'next-redux-wrapper';
import reducers from '../reducers'
import rootSaga from '../sagas/index'
import configureAxios from '../lib/AxiosInterceptors'
import {configureElectronTools} from '../electron/tools'

const sagaMiddleware = createSagaMiddleware()

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

// create a makeStore function
const makeStore = (context) => {
  const store = createStore(
    reducers,
    bindMiddleware([sagaMiddleware])
  )

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga)
  }

  store.runSagaTask()

  configureAxios(store);
  configureElectronTools(store);

  return store;
}

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, {debug: true});
