import {
    SEARCH_CHARACTERISTICS,
    SEARCH_CHARACTERISTICS_SUCCESS,
    AUTOCOMPLETE_CHARACTERISTICS,
    AUTOCOMPLETE_CHARACTERISTICS_SUCCESS,
    GET_CHARACTERISTIC,
    GET_CHARACTERISTIC_SUCCESS,
    SAVE_CHARACTERISTIC,
    SAVE_CHARACTERISTIC_SUCCESS,
    UPDATE_CHARACTERISTIC,
    UPDATE_CHARACTERISTIC_SUCCESS, CLEAR_CHARACTERISTIC_SUCCESS
} from "../constants/ActionsTypes";

const initialState = {
    searchRequest: [],
    search: [],
    autocompleteData: null,
    characteristic: null,
    success: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_CHARACTERISTICS:
            return { ...state, searchRequest: action.payload, search: [] }
        case SEARCH_CHARACTERISTICS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_CHARACTERISTICS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_CHARACTERISTICS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case GET_CHARACTERISTIC:
            return { ...state, characteristic: null }
        case GET_CHARACTERISTIC_SUCCESS:
            return { ...state, characteristic: action.payload }
        case SAVE_CHARACTERISTIC:
            return { ...state, success: false }
        case SAVE_CHARACTERISTIC_SUCCESS:
            return { ...state, success: true }
        case UPDATE_CHARACTERISTIC:
            return { ...state, success: false }
        case UPDATE_CHARACTERISTIC_SUCCESS:
            return { ...state, success: true }
        case CLEAR_CHARACTERISTIC_SUCCESS:
            return { ...state, success: false }
        default:
            return state
    }
}

export default rootReducer
