import {
    SEARCH_CATEGORIES,
    SEARCH_CATEGORIES_SUCCESS,
    AUTOCOMPLETE_CATEGORIES,
    AUTOCOMPLETE_CATEGORIES_SUCCESS,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY,
    GET_CATEGORY_BRANDS,
    GET_CATEGORY_BRANDS_SUCCESS,
    GET_CATEGORY_MODELS,
    GET_CATEGORY_MODELS_SUCCESS,
    GET_CATEGORY_ALL_CHARACTERISTICS,
    GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS,
    SAVE_CATEGORY,
    SAVE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, CLEAR_CATEGORY_SUCCESS, CLEAR_CATEGORY
} from "../constants/ActionsTypes";

const initialState = {
    searchRequest: [],
    search: [],
    allCharacteristics: [],
    autocompleteData: null,
    category: null,
    categoryBrands:null,
    categoryModels:null,
    selectedProductCategory: null,
    success: false,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_CATEGORIES:
            return { ...state, searchRequest: action.payload, search:[] }
        case SEARCH_CATEGORIES_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_CATEGORIES:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_CATEGORIES_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case GET_CATEGORY:
            return { ...state, category: null, selectedProductCategory:null }
        case GET_CATEGORY_SUCCESS:
            return { ...state, category: action.payload.data, selectedProductCategory:action.payload.data }
        case GET_CATEGORY_BRANDS:
            return { ...state, categoryBrands:null }
        case GET_CATEGORY_BRANDS_SUCCESS:
            return { ...state, categoryBrands:action.payload.data.response }
        case GET_CATEGORY_MODELS:
            return { ...state, categoryModels:null }
        case GET_CATEGORY_MODELS_SUCCESS:
            return { ...state, categoryModels:action.payload.data.response}
        case GET_CATEGORY_ALL_CHARACTERISTICS:
            return { ...state, allCharacteristics: [] }
        case GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS:
            return { ...state, allCharacteristics: action.payload}
        case SAVE_CATEGORY:
            return { ...state, success: false }
        case SAVE_CATEGORY_SUCCESS:
            return { ...state, success: true }
        case UPDATE_CATEGORY:
            return { ...state, success: false }
        case UPDATE_CATEGORY_SUCCESS:
            return { ...state, success: true }
        case CLEAR_CATEGORY_SUCCESS:
            return { ...state, success: false }
        case CLEAR_CATEGORY:
            return { ...initialState };
        default:
            return state
    }
}

export default rootReducer
