import Axios from 'axios';

const BASE_URL_END_POINT = '/vendedores';

export const getChartsData = async (from, to) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}/sellersForChart?from=${from}&to=${to}`);
    return response.data;
};

export const getDetailInfoBySeller = async (sellerId, from, to, pagination) => {
    const searchParams = { sellerId, from, to, pagination };
    const response = await Axios.post(
        `${BASE_URL_END_POINT}/detailInfoBySellerForChart`,
        searchParams
    );
    return response.data;
};
