import Axios from 'axios';

export const searchSellers = async (searchParams) => {
    const response = await Axios.post('/vendedores/search', searchParams);
    return response.data;
};

export const saveSeller = async (params) => {
    const response = await Axios.post('/vendedores/updateNoSAPProperties', params);
    return response.data;
};
