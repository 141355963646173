import Axios from 'axios';

export const searchDeals = async (params) => {
    const response = await Axios.post('/ofertas/search', params);
    return response.data;
};

export const getDeal = async (dealId) => {
    const response = await Axios.get('/ofertas/getOfferById?id=' + dealId);
    return response.data;
};

export const addDeal = async (data) => {
    const response = await Axios.post(`/ofertas/createOffer`, data);
    return response.data;
};

export const updateDeal = async (dealId, data) => {
    const response = await Axios.put(`/ofertas/updateOffer?id=${dealId}`, data);
    return response.data;
};

export const removeDeal = async (dealId) => {
    const response = await Axios.delete('/ofertas/deleteOffer?id=' + dealId);
    return response.data;
};

export const addRemoveProducts = async (data) => {
    const response = await Axios.post('/ofertasProductos/addRemoveProducts', data);
    return response.data;
};

export const getDealsBySegment = async (params) => {
    const response = await Axios.post('/ofertas/searchBySegment', params);
    return response.data;
};

export const getDealProductById = async (params) => {
    const response = await Axios.post('/ofertas/getProductById', params);
    return response.data;
};

export const getDealsTree = async () => {
    const response = await Axios.post('/segmentos/getDealsTree');
    return response.data;
}

export const getDealsByBrand = async (params) => {
    const response = await Axios.post('/ofertas/searchByBrand', params);
    return response.data;
}