import {
    ONLINE,
    OFFLINE,
    CHECK_CONNECTION,
} from "../constants/ActionsTypes";

const initialState = {
    isOnline: false,
    isOffline: true
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case CHECK_CONNECTION:
            return { ...state, isOffline: true, isOnline: false };
        case ONLINE:
            return { ...state, isOffline: false, isOnline: true };
        case OFFLINE:
            return { ...state, isOffline: true, isOnline: false };
        default:
            return state
    }
}

export default rootReducer