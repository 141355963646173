import Axios from 'axios';
import moment from 'moment-business-days';
const FileSaver = require('file-saver');

export const getMyAppointments = async (searchParams) => {
    const response = await Axios.post('/appointments/mine', searchParams);
    return response.data;
};

export const getMyAppointmentsGroup = async (searchParams) => {
    const response = await Axios.post('/appointments/amountByDate', searchParams);
    return response.data;
};

export const getAppointment = async (AppointmentId, filter) => {
    const response = await Axios.get(`/appointments/findAppointmentById/${AppointmentId}`, {
        params: {
            filter,
        },
    });
    return response;
};

export const createAppointment = async (params) => {
    const response = await Axios.post('/appointments/createAppointment', params);
    return response.data;
};

export const updateAppointment = async (appointmentId, params) => {
    const response = await Axios.post(`/appointments/${appointmentId}/updateAppointment`, params);
    return response.data;
};

export const getAppointmentActions = async () => {
    const response = await Axios.get('/appointments/getActions');
    return response.data;
};

export const getAppointmentStates = async (state) => {
    const url = state
        ? `/appointments/getValidStatesFor?currentState=${state}`
        : `/appointments/getValidStatesFor`;
    const response = await Axios.get(url);
    return response.data;
};

export const deleteAppointment = async (appointmentId) => {
    const response = await Axios.post(`/appointments/${appointmentId}/deleteAppointment`);
    return response.data;
};

export const excelExport = async (data) => {
    const response = await Axios.get(`/appointments/export`, {
        params: data,
        responseType: 'arraybuffer',
    });
    if (response && response.data) {
        const defaultName = `Citas-${moment().format('YYYY-MM-DD')}.xlsx`,
            serverName = response.headers['content-disposition'].split('filename=')[1];
        const fileName = serverName ? serverName : defaultName;
        FileSaver.saveAs(new Blob([response.data]), fileName, { autoBOM: true });
    }
};

export const getAppointmentEvents = async () => {
    const response = await Axios.get('/appointments/getEvents');
    return response.data;
};

export const actionsGroupedBySeller = async (params) => {
    const response = await Axios.post(`/appointments/actionsBySeller`, params);
    return response.data;
};

export const amountOfActionsBySeller = async (params) => {
    const response = await Axios.post(`/appointments/amountOfActionBySeller`, params);
    return response.data;
};
