import Axios from 'axios';

export const getSegments = async () => {
    const response = await Axios.get('/segmentos/listAll');
    return response.data;
};

export const getActiveSegments = async () => {
    const response = await Axios.get('/segmentos?filter={"where":{"borrado":"0"}}');
    return response;
};

export const getSegment = async (segmentId) => {
    const response = await Axios.get('/segmentos/' + segmentId);
    return response;
};

export const getBrandsBySegments = async (segmentIds) => {
    const response = await Axios.post('/segmentos/getBrandsBySegments', {
        ids: segmentIds,
    });
    return response.data;
};

export const getUsersActiveSegments = async () => {
    const response = await Axios.post('/segmentos/getUsersActiveSegments');
    return response.data;
};

export const getBrandsByUsersSegments = async () => {
    const response = await Axios.post('/segmentos/getBrandsByUsersSegments');
    return response.data;
};

export const searchSegments = async (searchParams) => {
    const response = await Axios.post('/segmentos/search', searchParams);
    return response.data;
};

export const addSegment = async (data) => {
    const response = await Axios.put('/segmentos', data);
    return response.data;
};
