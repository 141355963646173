import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';

import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    SEARCH_USERS,
    SEARCH_USERS_SUCCESS,
    SEARCH_USERS_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_CLIENTS,
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_ERROR,
    GET_CLIENT,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_ERROR,
    GET_SELLERS,
    GET_SELLERS_SUCCESS,
    GET_SELLERS_ERROR,
    GET_SELLERS_TELEMARKETER,
    GET_SELLERS_TELEMARKETER_SUCCESS,
    GET_SELLERS_TELEMARKETER_ERROR,
    GET_SELLER,
    GET_SELLER_SUCCESS,
    GET_SELLER_ERROR,
    GET_BRANDS_BY_USER,
    GET_BRANDS_BY_SEGMENTS_ERROR,
    GET_BRANDS_BY_USER_ERROR,
    CHANGE_PASSWORD,
    UPDATE_EMAIL,
    SEND_PASSWORD_REQUEST_EMAIL,
    SET_PASSWORD,
    VERIFY_TOKEN,
} from '../constants/ActionsTypes';

import {
    getUser,
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    getClient,
    getClients,
    getSeller,
    getSellers,
    searchUsers,
    getBrandsByUser,
    changePassword,
    updateEmail,
    sendPasswordRequestEmail,
    setPassword,
    verifyToken,
    getSellersTelemarketer,
} from '../api/Users';

import {
    getBrandsByUserSuccess,
    getAllBrandsByUserSuccess,
    changePasswordSuccess,
    updateEmailByUserSuccess,
    sendPasswordRequestEmailError,
    sendPasswordRequestEmailSuccess,
    setPasswordSuccess,
    setPasswordError,
    verifyTokenSuccess,
    verifyTokenError,
} from '../actions';

export const getCommonState = (state) => state.common;

function* callGetUser({ payload }) {
    const { userId } = payload;
    try {
        const {data} = yield call(getUser, userId);

        yield put({ type: GET_USER_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_USER_ERROR, error });
    }
}

function* callGetUsers({ payload }) {
    try {
        const { token } = payload;
        const response = yield call(getUsers, token);
        yield put({ type: GET_USERS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_USERS_ERROR, error });
    }
}

function* callSearchUsers({ payload }) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;

    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove;
    }

    try {
        const response = yield call(searchUsers, payload);
        yield put({ type: SEARCH_USERS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: SEARCH_USERS_ERROR, error });
    }
}

function* callCreateUser({ payload }) {
    try {
        const { token, values } = payload;
        const response = yield call(createUser, values, token);
        if (response.data.statusCode === 200) {
            yield put({ type: CREATE_USER_SUCCESS, data: response.data });
            Router.push('/users');
        } else {
            yield put({ type: CREATE_USER_ERROR, data: response.data });
        }

    } catch (error) {
        yield put({ type: CREATE_USER_ERROR, data: error });
    }
}

function* callUpdateUser({ payload }) {
    try {
        const { token, values } = payload;
        const response = yield call(updateUser, values, token);
        if (response.data.statusCode === 200) {
            yield put({ type: UPDATE_USER_SUCCESS, data: response.data });
            Router.push('/users');
        } else {
            yield put({ type: UPDATE_USER_ERROR, data: response.data });
        }
    } catch (error) {
        yield put({ type: UPDATE_USER_ERROR, data: error });
    }
}

function* callDeleteUser({ payload }) {
    try {
        const { userId } = payload;
        const response = yield call(deleteUser, userId);
        yield put({ type: DELETE_USER_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: DELETE_USER_ERROR, error });
    }
}

function* callGetClient({ payload }) {
    const { clientId, token } = payload;
    try {
        const response = yield call(getClient, clientId, token);
        yield put({ type: GET_CLIENT_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_CLIENT_ERROR, error });
    }
}

function* callGetClients({ payload }) {
    try {
        const { searchText } = payload;
        const response = yield call(getClients, searchText);
        yield put({ type: GET_CLIENTS_SUCCESS, data: response.data.response.data });
    } catch (error) {
        yield put({ type: GET_CLIENTS_ERROR, error });
    }
}

function* callGetSellers({ payload }) {
    try {
        const { searchText } = payload;
        const response = yield call(getSellers, searchText);
        yield put({ type: GET_SELLERS_SUCCESS, data: response.data.response.data });
    } catch (error) {
        yield put({ type: GET_SELLERS_ERROR, error });
    }
}

function* callGetSellersTelemarketer({ payload }) {
    try {
        const { searchText } = payload;
        const response = yield call(getSellersTelemarketer, searchText);
        yield put({ type: GET_SELLERS_TELEMARKETER_SUCCESS, data: response.data.response.data });
    } catch (error) {
        yield put({ type: GET_SELLERS_TELEMARKETER_ERROR, error });
    }
}

function* callGetSeller({ payload }) {
    const { sellerId, token } = payload;
    try {
        const response = yield call(getSeller, sellerId, token);
        yield put({ type: GET_SELLER_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_SELLER_ERROR, error });
    }
}

function* callGetBrandsByUser({payload}) {
    try {
        const brands = yield call(getBrandsByUser, payload);
        yield put( getBrandsByUserSuccess(brands) );
    } catch (error) {
        yield put({ type: GET_BRANDS_BY_USER_ERROR , error });
    }
}

function* callChangePassword({payload}) {
    try {
        const success = yield call(changePassword, payload);
        yield put( changePasswordSuccess(success) );
    } catch (error) {

    }
}

function* callUpdateEmail({payload}) {
    try {
        const success = yield call(updateEmail, payload);
        yield put( updateEmailByUserSuccess(success) );
    } catch (error) {

    }
}

function* callSendPasswordRequestEmail({payload}) {
    try {
        const success = yield call(sendPasswordRequestEmail, payload);
        yield put(sendPasswordRequestEmailSuccess(success));
    } catch (err) {
        if (err.response && err.response.status == 401) {
            yield put(sendPasswordRequestEmailError({ message: 'validation-email' }));
        } else if (err.response && err.response.data) {
            yield put(sendPasswordRequestEmailError({ message: err.response.data.message }));
        } else {
            yield put(sendPasswordRequestEmailError({ message: 'Error communicating with server' }));
        }
    }
}

function* callSetPassword({payload}) {
    try {
        const params = { newPassword: payload.newPassword };
        const success = yield call(setPassword, params, payload.token);
        yield put(setPasswordSuccess(success));
    } catch (err) {
        if (err.response && err.response.data) {
            yield put(setPasswordError({ message: err.response.data.message }));
        } else {
            yield put(setPasswordError({ message: 'Error communicating with server' }));
        }
    }
}

function* callVerifyToken({payload}) {
    try {
        const success = yield call(verifyToken, payload.token);
        if (success && success.response && success.response.data) {
            if ('INVALID_TOKEN' in success.response.data) {
                yield put(verifyTokenSuccess({ isTokenValid: false }));
            } else if ('IS_VALID_TOKEN' in success.response.data) {
                yield put(verifyTokenSuccess({ isTokenValid: true }));
            } else {
                throw Error('Incorrect response');
            }
        }
    } catch (err) {
        if (err.response && err.response.data) {
            yield put(verifyTokenError({ message: err.response.data.message }));
        } else {
            yield put(verifyTokenError({ message: 'Error communicating with server' }));
        }
    }
}

export function* getUserSaga() {
    yield takeLatest(GET_USER, callGetUser);
}

export function* getUsersSaga() {
    yield takeLatest(GET_USERS, callGetUsers);
}

export function* searchUsersSaga() {
    yield takeLatest(SEARCH_USERS, callSearchUsers);
}

export function* createUserSaga() {
    yield takeLatest(CREATE_USER, callCreateUser);
}

export function* updateUserSaga() {
    yield takeLatest(UPDATE_USER, callUpdateUser);
}

export function* deleteUserSaga() {
    yield takeLatest(DELETE_USER, callDeleteUser);
}

export function* getClientsSaga() {
    yield takeLatest(GET_CLIENTS, callGetClients);
}

export function* getClientSaga() {
    yield takeLatest(GET_CLIENT, callGetClient);
}

export function* getSellersSaga() {
    yield takeLatest(GET_SELLERS, callGetSellers);
}

export function* getSellersTelemarketerSaga() {
    yield takeLatest(GET_SELLERS_TELEMARKETER, callGetSellersTelemarketer);
}

export function* getSellerSaga() {
    yield takeLatest(GET_SELLER, callGetSeller);
}

export function* getBrandsByUserSaga() {
    yield takeEvery(GET_BRANDS_BY_USER, callGetBrandsByUser);
}

export function* changePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD, callChangePassword);
}

export function* updateEmailSaga() {
    yield takeLatest(UPDATE_EMAIL, callUpdateEmail);
}

export function* getSendPasswordRequestEmailSaga() {
    yield takeLatest(SEND_PASSWORD_REQUEST_EMAIL, callSendPasswordRequestEmail);
}

export function* getSetPasswordSaga() {
    yield takeLatest(SET_PASSWORD, callSetPassword);
}

export function* getVerifyTokenSaga() {
    yield takeLatest(VERIFY_TOKEN, callVerifyToken);
}

export default function* rootSaga() {
    yield all([
        fork(getUserSaga),
        fork(getUsersSaga),
        fork(searchUsersSaga),
        fork(updateUserSaga),
        fork(createUserSaga),
        fork(deleteUserSaga),
        fork(getClientsSaga),
        fork(getClientSaga),
        fork(getSellersSaga),
        fork(getSellersTelemarketerSaga),
        fork(getSellerSaga),
        fork(getBrandsByUserSaga),
        fork(changePasswordSaga),
        fork(updateEmailSaga),
        fork(getSendPasswordRequestEmailSaga),
        fork(getSetPasswordSaga),
        fork(getVerifyTokenSaga)
    ]);
}
