import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_SUB_GROUP_SUCCESS,
    GET_SUB_GROUP_BY_CODE_SUCCESS,
    GET_SUB_GROUP_BY_CODE_ERROR,
    GET_SUB_GROUP_BY_CODE,
    GET_SUB_GROUP_ERROR,
    SEARCH_SUB_GROUPS,
    AUTOCOMPLETE_SUB_GROUPS,
    GET_SUB_GROUP,
} from '../constants/ActionsTypes';

import {
    getLines,
    getLinesByCode,
    searchSubGroups
} from '../api/Lines';

import {
    searchSubGroupsSuccess,
    autocompleteSubGroupsSuccess
} from '../actions/SubGroups';

export const getCommonState = (state) => state.common;

function* callGetSubGroup({ payload }) {
    const { subGroupId } = payload;
    try {
        const response = yield call(getLines, subGroupId);        
        yield put({ type: GET_SUB_GROUP_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: GET_SUB_GROUP_ERROR, error });
    }
}


function* callGetSubGroupByCode({ payload }) {
    const { code, token } = payload;
    try {
        const response = yield call(getLinesByCode, code, token);
        yield put({ type: GET_SUB_GROUP_BY_CODE_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_SUB_GROUP_BY_CODE_ERROR, error });
    }
}

function* searchSubGroupstRequest({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;
    
    if(showRemove){
        payload.borrado = showRemove;
    }    

    try {
        const transports = yield call(searchSubGroups, payload);
        yield put(searchSubGroupsSuccess(transports));
    } catch (error) {
    }
}

function* autocompleteSubGroupsRequest({ payload }) {

    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;   
    
    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(showRemove){
        query.borrado = showRemove;
    } 

    try {
        const groups = yield call(searchSubGroups, query);
        yield put(autocompleteSubGroupsSuccess(groups));
    } catch (error) {
    }
}

export function* searchSubGroupsSaga() {
    yield takeEvery(SEARCH_SUB_GROUPS, searchSubGroupstRequest);
}

export function* getSubGroupSaga() {
    yield takeLatest(GET_SUB_GROUP, callGetSubGroup);
}

export function* getSubGroupByCodeSaga() {
    yield takeLatest(GET_SUB_GROUP_BY_CODE, callGetSubGroupByCode);
}

export function* autocompleteSubGroupsSaga() {
    yield throttle(1000, AUTOCOMPLETE_SUB_GROUPS, autocompleteSubGroupsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getSubGroupSaga),
        fork(getSubGroupByCodeSaga),
        fork(searchSubGroupsSaga),
        fork(autocompleteSubGroupsSaga),
    ]);
}