import Axios from 'axios';

const BASE_URL_END_POINT = '/Banners';

export const searchBanners = async (searchParams) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/search`, searchParams);
    return response.data;
};

export const searchBannersBySegment = async (searchParams) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/searchBySegment`, searchParams);
    return response.data;
};

export const addBanner = async (params) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/createBanner`, params);
    return response.data;
};

export const getBanner = async (bannerId) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}/findBannerById?id=${bannerId}`);
    return response.data;
};

export const updateBanner = async (bannerId, params) => {
    const response = await Axios.put(`${BASE_URL_END_POINT}/updateBanner?id=${bannerId}`, params);
    return response.data;
};
