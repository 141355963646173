import Axios from 'axios';

const BASE_URL_END_POINT = '/agrupaciones';

export const getAgrupations = async (mainGroupId) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}/${mainGroupId}`);
    return response;
};

export const searchAgrupations = async (searchParams) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/search`, searchParams);
    return response.data;
};
