import ReactGA from "react-ga4";
import getConfig from 'next/config'

const { publicRuntimeConfig: { GA_CODE } } = getConfig();

function getUserId(user) {
    if (user && user.vendedor) {
        return user.vendedor.nombre;
    } else if (user && user.cliente) {
        return user.cliente.nombre;
    } else if (user && user.username) {
        return user.username;
    }
    return null;
}

const initializeGoogleAnalytics = (user) => {
    const user_id = getUserId(user);

    ReactGA.initialize(GA_CODE, {
        gaOptions: {
            debug_mode: true,
            user_id,
            iturriaUser: user_id,
            role: user && user.role ? user.role.name : null
            }
        }
    );

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

const trackAnalyticsCommerceEvent = (
    event_name,
    event_category,
    event_label,
    user,
    value,
    data = []
) => {
    const username = getUserId(user);

    let event_params = {
        event_category,
        event_label,
        iturriaUsername: username,
        value,
        currency: 'ARS',
        items: data
    };
    // Send GA4 Event

    ReactGA.event(event_name, event_params);
};

const trackDownloadEvent = (
    format
) => {
    let event_params = {
        eventCategory: "Price List",
        eventLabel: "Download Price List",
        currency: 'ARS',
        format
    };
    ReactGA.event('downloadPriceList', event_params);
};

export default initializeGoogleAnalytics;
export { initializeGoogleAnalytics, trackAnalyticsCommerceEvent, trackDownloadEvent };


