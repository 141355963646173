import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_ERROR,
    RESET_SUCCESS,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_ERROR,
    SEARCH_PRODUCTS_SUCCESS,
    AUTOCOMPLETE_PRODUCTS_SUCCESS,
    AUTOCOMPLETE_PRODUCTS,
    GET_PRODUCT_BY_CODE,
    GET_PRODUCT_BY_CODE_SUCCESS,
    GET_PRODUCTS_BY_IDS,
    GET_PRODUCTS_BY_IDS_SUCCESS,
    GET_PRODUCT_PRICE_CLIENT,
    GET_PRODUCT_PRICE_CLIENT_SUCCESS,
    GET_PRODUCT_PRICE_CLIENT_ERROR,
    GET_PRODUCT_PRICE_BY_CLIENT_LOADING,
    SHOW_ONE_PRODUCT_SUCCESS,
    SEARCH_PRODUCTS,
    SORT_CHANGE,
    GET_APPLICATIONS_BY_PRODUCT_CODE,
    GET_APPLICATION_BY_PRODUCT_CODE_LOADING,
    GET_APPLICATIONS_BY_PRODUCT_CODE_SUCCESS,
    GET_APPLICATIONS_BY_PRODUCT_ID,
    GET_APPLICATIONS_BY_PRODUCT_ID_SUCCESS,
    DELETE_APPLICATION_FROM_PRODUCT,
    DELETE_APPLICATION_FROM_PRODUCT_SUCCESS,
    CREATE_APPLICATION_FROM_PRODUCT,
    CREATE_APPLICATION_FROM_PRODUCT_SUCCESS,
    EDIT_APPLICATION_FROM_PRODUCT,
    EDIT_APPLICATION_FROM_PRODUCT_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
    productsList: [],
    product: null,
    success: false,
    error: false,
    productPrice: null,
    searchRequest: null,
    productOne: null,
    loadStockSuccess: false,
    listOfApplications: [],
    isDeleteApplicationSuccess: false,
    isCreateApplicationSuccess: false,
    isEditedApplicationSuccess: false,
    isLoadingGetApplicationByProductCode: false,
    isLoadingGetProductPriceByClient: false,
    sort: null
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS_SUCCESS:
            return { ...state, productsList: action.data, success: false, error: false }
        case GET_PRODUCT_SUCCESS:
            return { ...state, product: action.data, success: false, error: false }
        case GET_PRODUCTS_ERROR:
            return { ...state, success: false, error: true }
        case RESET_SUCCESS:
            return { ...state, success: false }
        case GET_PRODUCT_BY_CODE:
            return { ...state, product: null }
        case GET_PRODUCTS_BY_IDS:
            return { ...state, productsList: [] }
        case SHOW_ONE_PRODUCT_SUCCESS:
            return { ...state, productOne: action.data.response.product }
        case GET_PRODUCT_BY_CODE_SUCCESS:
            return { ...state, product: action.data.response.product }
        case GET_PRODUCTS_BY_IDS_SUCCESS:
            return { ...state, productsList: action.data, success: false, error: false }
        case UPDATE_PRODUCT_SUCCESS:
            return { ...state, success: true, error: false }
        case UPDATE_PRODUCT_ERROR:
            return { ...state, error: true }
        case SEARCH_PRODUCTS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_PRODUCTS_SUCCESS:
            return { ...state, search: action.payload }
        case SORT_CHANGE:
            return { ...state, sort: action.sort };
        case AUTOCOMPLETE_PRODUCTS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_PRODUCTS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case GET_PRODUCT_PRICE_CLIENT:
            return { ...state, productPrice: null }
        case GET_PRODUCT_PRICE_CLIENT_SUCCESS:
            return { ...state, productPrice: action.payload.response, isLoadingGetProductPriceByClient: false }
        case GET_PRODUCT_PRICE_CLIENT_ERROR:
            return { ...state, isLoadingGetProductPriceByClient: false }
        case GET_PRODUCT_PRICE_BY_CLIENT_LOADING:
            return { ...state, isLoadingGetProductPriceByClient: true }
        case GET_APPLICATIONS_BY_PRODUCT_CODE:
            return { ...state, listOfApplications: []}
        case GET_APPLICATION_BY_PRODUCT_CODE_LOADING:
            return { ...state, isLoadingGetApplicationByProductCode: true };
        case GET_APPLICATIONS_BY_PRODUCT_CODE_SUCCESS:
            return { ...state, listOfApplications: action.payload, isLoadingGetApplicationByProductCode: false }
        case GET_APPLICATIONS_BY_PRODUCT_ID:
            return { ...state, listOfApplications: []}
        case GET_APPLICATIONS_BY_PRODUCT_ID_SUCCESS:
            return { ...state, listOfApplications: action.payload }
        case DELETE_APPLICATION_FROM_PRODUCT:
            return { ...state, isDeleteApplicationSuccess: false}
        case DELETE_APPLICATION_FROM_PRODUCT_SUCCESS:
            return { ...state, isDeleteApplicationSuccess: true }
        case CREATE_APPLICATION_FROM_PRODUCT:
            return { ...state, isCreateApplicationSuccess: false }
        case CREATE_APPLICATION_FROM_PRODUCT_SUCCESS:
            return { ...state, isCreateApplicationSuccess: true }
        case EDIT_APPLICATION_FROM_PRODUCT:
            return { ...state, isEditedApplicationSuccess: false }
        case EDIT_APPLICATION_FROM_PRODUCT_SUCCESS:
            return { ...state, isEditedApplicationSuccess: true }
        default:
            return state
    }
}

export default rootReducer
