import {all, call, fork, put, select, takeEvery, throttle} from 'redux-saga/effects';

import {
    searchCategories,
    getCategory,
    getCategoryBrands,
    getCategoryModels,
    saveCategories,
    updateCategories
} from '../api/Categories'
import {getAllCharacteristics} from '../api/Characteristics'

import {
    SEARCH_CATEGORIES,
    AUTOCOMPLETE_CATEGORIES,
    GET_CATEGORY,
    GET_CATEGORY_BRANDS,
    GET_CATEGORY_MODELS,
    GET_CATEGORY_ALL_CHARACTERISTICS,
    SAVE_CATEGORY,
    UPDATE_CATEGORY
} from '../constants/ActionsTypes';
import {
    searchCategoriesSuccess,
    autocompleteCategoriesSuccess,
    getCategorySuccess,
    getCategoryBrandsSuccess,
    getCategoryModelsSuccess,
    getCategoryCharacteristicsSuccess,
    saveCategorySuccess,
    updateCategorySuccess,
    clearCategorySuccess
} from '../actions';
import {getCommonState} from "./Users";


function* searchCategoriesRequest({payload}) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;

    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove;
    }

    try {
        const categories = yield call(searchCategories, payload);
        yield put(searchCategoriesSuccess (categories));
    } catch (error) {
    }
}


function* getCategoryRequest({payload}) {
    const { categoryId } = payload;
    try {
        const {response} = yield call(getCategory, categoryId);
        yield put(getCategorySuccess (response));
    } catch (error) {
    }
}

function* autocompleteCategoriesRequest({payload}) {

    const query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }
    try {
        const categories = yield call(searchCategories, query);
        yield put(autocompleteCategoriesSuccess(categories));
    } catch (error) {
    }
}

function* getCategoryBrandsRequest() {
    try {
        const brands = yield call(getCategoryBrands);
        yield put(getCategoryBrandsSuccess (brands));
    } catch (error) {
    }
}

function* getCategoryModelsRequest() {
    try {
        const models = yield call(getCategoryModels);
        yield put(getCategoryModelsSuccess(models));
    } catch (error) {
    }
}

function* getCategoryCharacteristicsRequest() {
    try {
        const response = yield call(getAllCharacteristics);
        yield put(getCategoryCharacteristicsSuccess(response));
    } catch (error) {
    }
}

function* saveCategoryRequest({payload}) {
    try {
        yield call(saveCategories, payload);
        yield put(saveCategorySuccess());
        yield put(clearCategorySuccess());
    } catch (error) {
    }
}

function* updateCategoryRequest({payload}) {
    try {
        yield call(updateCategories, payload.id, payload.category);
        yield put(updateCategorySuccess());
        yield put(clearCategorySuccess());
    } catch (error) {
    }
}

export function* searchCategoriesSaga() {
    yield takeEvery(SEARCH_CATEGORIES, searchCategoriesRequest);
}

export function* autocompleteCategoriesSaga() {
    yield throttle(1000, AUTOCOMPLETE_CATEGORIES, autocompleteCategoriesRequest);
}

export function* getCategorySaga() {
    yield takeEvery(GET_CATEGORY, getCategoryRequest);
}

export function* getCategoryBrandsSaga() {
    yield takeEvery(GET_CATEGORY_BRANDS, getCategoryBrandsRequest);
}

export function* getCategoryModelsSaga() {
    yield takeEvery(GET_CATEGORY_MODELS, getCategoryModelsRequest);
}

export function* getCategoryCharacteristicsSaga() {
    yield takeEvery(GET_CATEGORY_ALL_CHARACTERISTICS, getCategoryCharacteristicsRequest);
}

export function* saveCategorySaga() {
    yield takeEvery(SAVE_CATEGORY, saveCategoryRequest);
}

export function* updateCategorySaga() {
    yield takeEvery(UPDATE_CATEGORY, updateCategoryRequest);
}

export default function* rootSaga() {
    yield all([
        fork(searchCategoriesSaga),
        fork(autocompleteCategoriesSaga),
        fork(getCategorySaga),
        fork(getCategoryBrandsSaga),
        fork(getCategoryModelsSaga),
        fork(getCategoryCharacteristicsSaga),
        fork(saveCategorySaga),
        fork(updateCategorySaga),
    ]);
}
