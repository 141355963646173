import {
    ADD_DEAL,
    ADD_DEAL_SUCCESS,
    UPDATE_DEAL,
    UPDATE_DEAL_SUCCESS,
    SEARCH_DEALS,
    SEARCH_DEALS_SUCCESS,
    AUTOCOMPLETE_DEALS,
    AUTOCOMPLETE_DEALS_SUCCESS ,
    GET_DEAL,
    GET_DEAL_SUCCESS,
    REMOVE_DEAL,
    REMOVE_DEAL_SUCCESS,
    SHOW_DEAL_MODAL,
    HIDE_DEAL_MODAL,
    GET_DEALS_SEGMENT,
    GET_DEALS_SEGMENT_SUCCESS,
    GET_DEAL_PRODUCT_ID,
    GET_DEAL_PRODUCT_ID_SUCCESS,
    INIT_DEAL_COMPONENT,
    SET_SELECTED_DEALS_BRAND,
    SET_SELECTED_DEALS_SEGMENT ,
    CLEAR_SELECTED_DEALS_BRAND,
    GET_DEALS_TREE,
    GET_DEALS_BY_BRAND,
    GET_DEALS_BY_BRAND_SUCCESS
} from '../constants/ActionsTypes';

export const addDeal = (params) => {
    return {
        type: ADD_DEAL,
        payload: {params}
    };
};

export const updateDeal = (dealId, params) => {
    return {
        type: UPDATE_DEAL,
        payload: {dealId, params}
    };
};

export const addDealSuccess = (data) => {
    return {
        type: ADD_DEAL_SUCCESS,
        payload: data
    }
};

export const searchDeals = (params) => {
    return {
        type: SEARCH_DEALS,
        payload: {params}
    };
};

export const getDealsTree = () => {
    return {
        type: GET_DEALS_TREE
    };
};


export const showDealModal = (data) => {
    return {
        type: SHOW_DEAL_MODAL,
        payload: data,
    };
};

export const hideDealModal = (data) => {
    return {
        type: HIDE_DEAL_MODAL,
    };
};

export const getDealsByBrand = (data) => {
    return {
        type: GET_DEALS_BY_BRAND,
        payload: { ...data }
    };
};

export const getDealsByBrandSuccess = (deal) => {
    return {
        type: GET_DEALS_BY_BRAND_SUCCESS,
        payload: { deal }
    };
};

export const setSelectedDealsBrand = (data) => {
    return {
        type: SET_SELECTED_DEALS_BRAND,
        payload: data
    }
};

export const setSelectedDealsSegment = (data) => {
    return {
        type: SET_SELECTED_DEALS_SEGMENT,
        payload: data
    }
};

export const clearSelectedDealsBrand = () => {
    return {
        type: CLEAR_SELECTED_DEALS_BRAND,
    }
};

export const searchDealsSuccess = (data) => {
    return {
        type: SEARCH_DEALS_SUCCESS,
        payload: {data}
    };
};

export const autocompleteDeals = (params) => {
    return {
        type: AUTOCOMPLETE_DEALS,
        payload: {params}
    };
};

export const autocompleteDealsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_DEALS_SUCCESS,
        payload: {data}
    };
};

export const getDeal = (dealId) => {
    return {
        type: GET_DEAL,
        payload: {dealId}
    };
};

export const initDealComponent = () => {
    return {
        type: INIT_DEAL_COMPONENT
    };
};

export const getDealSuccess = (deal) => {
    return {
        type: GET_DEAL_SUCCESS,
        payload: {deal}
    };
};


export const getDealsBySegment = (segmentId) => {
    return {
        type: GET_DEALS_SEGMENT,
        payload: segmentId
    };
};

export const getDealsBySegmentSuccess = (deal) => {
    return {
        type: GET_DEALS_SEGMENT_SUCCESS,
        payload: {deal}
    };
};

export const removeDeal = (dealId) => {
    return {
        type: REMOVE_DEAL,
        payload: {dealId}
    };
};

export const removeDealSuccess = (response) => {
    return {
        type: REMOVE_DEAL_SUCCESS,
        payload: {response}
    };
};


export const getDealProductById = (params) => {
    return {
        type: GET_DEAL_PRODUCT_ID,
        payload: params
    };
};

export const getDealProductByIdSuccess = (deal) => {
    return {
        type: GET_DEAL_PRODUCT_ID_SUCCESS,
        payload: {deal}
    };
};