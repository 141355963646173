import {
    ADD_UPDATE_CATALOG_ENTRY,
    GET_CATALOG_ENTRY,
    GET_CATALOGS_ENTRIES_ERROR,
    GET_CATALOG_ENTRY_SUCCESS,
    GET_CATALOG_ENTRY_ERROR,
    SEARCH_CATALOGS_ENTRIES,
    SEARCH_CATALOGS_ENTRIES_SUCCESS,
    AUTOCOMPLETE_CATALOGS_ENTRIES,
    AUTOCOMPLETE_CATALOGS_ENTRIES_SUCCESS,
    ADD_UPDATE_CATALOG_ENTRY_SUCCESS,
    ADD_UPDATE_CATALOG_ENTRY_ERROR
} from '../constants/ActionsTypes'

const initialState = {    
    catalogEntry: undefined,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_UPDATE_CATALOG_ENTRY:
            return { ...state, success: false }
        case ADD_UPDATE_CATALOG_ENTRY_SUCCESS:
            return { ...state, success: true }
        case ADD_UPDATE_CATALOG_ENTRY_ERROR:
            return { ...state, success: false }
        case GET_CATALOG_ENTRY:
            return { ...state, catalogEntry: null, success:false }
        case GET_CATALOG_ENTRY_SUCCESS:
            return { ...state, catalogEntry: action.data }
        case GET_CATALOG_ENTRY_ERROR:
            return { ...state, catalogEntry: action }
        case GET_CATALOGS_ENTRIES_ERROR:
            return { ...state, catalogEntries: action }
        case SEARCH_CATALOGS_ENTRIES:
            return { ...state, searchRequest: action.payload, search : null }
        case SEARCH_CATALOGS_ENTRIES_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_CATALOGS_ENTRIES:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_CATALOGS_ENTRIES_SUCCESS:
            return { ...state, autocompleteData: action.payload }  
        default:
            return state
    }
}

export default rootReducer