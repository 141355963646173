import {
  GET_USER_CONFIGURATIONS_ERROR,
  GET_USER_CONFIGURATIONS_SUCCESS,
  SET_USER_CONFIGURATIONS_SUCCESS,
  SET_USER_CONFIGURATIONS_ERROR,
  GET_MY_FAVORITE_PRODUCTS_ERROR,
  GET_MY_FAVORITE_PRODUCTS_SUCCESS,
  SET_USER_CONFIGURATIONS,
  ADD_PRODUCT_TO_FAVORITES,
  ADD_PRODUCT_TO_FAVORITES_SUCCESS,
  ADD_PRODUCT_TO_FAVORITES_ERROR,
  ADD_OBSERVATION_TO_FAVORITE,
  ADD_OBSERVATION_TO_FAVORITE_SUCCESS,
  ADD_OBSERVATION_TO_FAVORITE_ERROR,
  REMOVE_PRODUCT_TO_FAVORITES,
  REMOVE_PRODUCT_TO_FAVORITES_SUCCESS,
  REMOVE_PRODUCT_TO_FAVORITES_ERROR,
} from '../constants/ActionsTypes'

import { USER_CONFIG } from '../constants/DefaultUserConfig'

const initialState = {
  config:
    typeof window !== 'undefined'
      ? window.localStorage.getItem('userConfig')
        ? { ...USER_CONFIG, ...JSON.parse(window.localStorage.getItem('userConfig')) }
        : USER_CONFIG
      : USER_CONFIG,
  error: undefined,
  success: false,
  favoriteProducts: [],
  successAddingProductToFavorites: false,
  errorAddingProductToFavorites: false,
  successRemovingProductToFavorites: false,
  errorRemovingProductToFavorites: false,
  successAddingObservationToFavorite: false,
  errorAddingObservationToFavorite: false,
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_CONFIGURATIONS_SUCCESS:
      localStorage.setItem('userConfig', JSON.stringify({ ...USER_CONFIG, ...action.payload }))
      return { ...state, config: { ...USER_CONFIG, ...action.payload } }
    case GET_USER_CONFIGURATIONS_ERROR:
      return { ...state, error: action.payload }

    case SET_USER_CONFIGURATIONS:
      return { ...state, success: false }
    case SET_USER_CONFIGURATIONS_SUCCESS:
      localStorage.setItem('userConfig', JSON.stringify({ ...USER_CONFIG, ...action.payload }))
      return { ...state, config: { ...USER_CONFIG, ...action.payload }, success: true }
    case SET_USER_CONFIGURATIONS_ERROR:
      return { ...state, error: action.payload }

    case GET_MY_FAVORITE_PRODUCTS_SUCCESS:
      return { ...state, favoriteProducts: action.payload }
    case GET_MY_FAVORITE_PRODUCTS_ERROR:
      return { ...state, error: action.payload }

    case ADD_PRODUCT_TO_FAVORITES:
      return {
        ...state,
        successAddingProductToFavorites: false,
        errorAddingProductToFavorites: false,
      }
    case ADD_PRODUCT_TO_FAVORITES_SUCCESS: {
      const mergeConfig = { ...USER_CONFIG, ...action.payload }
      localStorage.setItem('userConfig', JSON.stringify(mergeConfig))
      return {
        ...state,
        config: { ...USER_CONFIG, ...action.payload },
        successAddingProductToFavorites: true,
      }
    }
    case ADD_PRODUCT_TO_FAVORITES_ERROR:
      return { ...state, errorAddingProductToFavorites: action.payload }
    case ADD_OBSERVATION_TO_FAVORITE:
      return {
        ...state,
        successAddingObservationToFavorite: false,
        errorAddingObservationToFavorite: false,
      }
    case ADD_OBSERVATION_TO_FAVORITE_SUCCESS: {
      const mergeConfig = { ...USER_CONFIG, ...action.payload }
      localStorage.setItem('userConfig', JSON.stringify(mergeConfig))
      return {
        ...state,
        config: { ...USER_CONFIG, ...action.payload },
        successAddingObservationToFavorite: true,
      }
    }
    case ADD_OBSERVATION_TO_FAVORITE_ERROR:
      return { ...state, errorAddingObservationToFavorite: action.payload }
    case REMOVE_PRODUCT_TO_FAVORITES:
      return {
        ...state,
        successRemovingProductToFavorites: false,
        errorRemovingProductToFavorites: false,
      }
    case REMOVE_PRODUCT_TO_FAVORITES_SUCCESS: {
      const mergeConfig = { ...USER_CONFIG, ...action.payload }
      localStorage.setItem('userConfig', JSON.stringify(mergeConfig))
      return { ...state, config: mergeConfig, successRemovingProductToFavorites: true }
    }
    case REMOVE_PRODUCT_TO_FAVORITES_ERROR:
      return { ...state, errorRemovingProductToFavorites: action.payload }

    default:
      return state
  }
}

export default rootReducer
