import {
    GET_MAIN_GROUP,
    GET_MAIN_GROUP_SUCCESS,
    GET_MAIN_GROUP_ERROR,
    SEARCH_MAIN_GROUPS,
    SEARCH_MAIN_GROUPS_SUCCESS,
    AUTOCOMPLETE_MAIN_GROUPS,
    AUTOCOMPLETE_MAIN_GROUPS_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {    
    mainGroup: undefined,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MAIN_GROUP:
            return { ...state, mainGroup: null }
        case GET_MAIN_GROUP_SUCCESS:
            return { ...state, mainGroup: action.data }
        case GET_MAIN_GROUP_ERROR:
            return { ...state, mainGroup: action }
        case SEARCH_MAIN_GROUPS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_MAIN_GROUPS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_MAIN_GROUPS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_MAIN_GROUPS_SUCCESS:
            return { ...state, autocompleteData: action.payload }  
        default:
            return state
    }
}

export default rootReducer