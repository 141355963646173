import Axios from 'axios';

const branchesWSPath = '/Sucursales';

export const getBranches = async () => {
    const response = await Axios.get(branchesWSPath);
    return response;
};

export const getBranch = async (branchId) => {
    const response = await Axios.get(branchesWSPath + '/' + branchId);
    return response;
};

export const searchBranches = async (searchParams) => {
    const response = await Axios.post(branchesWSPath + '/search', searchParams);
    return response.data;
};

export const editContactLines = async (payload) => {
    const url = branchesWSPath + `/editContactLines?id=${payload.id}`;
    const response = await Axios.put(url, payload.params);
    return response.data;
};