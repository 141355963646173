import Axios from 'axios';

const BASE_URL_END_POINT = '/configuracionesClientes';

export const configureClientUpdate = async (params) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/save`, params);
    return response.data;
};

export const getConfigClient = async () => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/getConfigByClient`);
    return response.data;
};

export const getLogoImage = async (payload) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}/downloadImage`, {
        params: {
            ...payload,
        },
    });
    return response.data;
};
