import {
    CLEAR_ERROR,
    CLEAR_MESSAGE,
    GET_BANKS_SUCCESS,
    GET_BANKS,
    GET_CURRENCY_TYPES_SUCCESS,
    GET_CURRENCY_TYPES,
    GET_TAX_WITHHOLDINGS_TYPES_SUCCESS,
    GET_TAX_WITHHOLDINGS_TYPES,
    HIDE_LOADER,
    INIT_ACTION_BAR,
    SET_ACTION_BAR_SHOW_REMOVE,
    SET_HEADER_HEIGHT,
    SHOW_ERROR,
    SHOW_LOADER,
    SHOW_MESSAGE,
    TOGGLE_FIX_HEADER,
    TRIGGER_UPDATE_NOTIFICATION,
    HIDE_UPDATE_NOTIFICATION,
    CHECK_FOR_UPDATES
} from '../constants/ActionsTypes';

export const showLoader = () => {
    return {
        type: SHOW_LOADER,
    };
};

export const hideLoader = () => {
    return {
        type: HIDE_LOADER,
    };
};

export const showError = (error) => {
    return {
        type: SHOW_ERROR,
        payload: error,
    };
};

export const clearError = () => {
    return {
        type: CLEAR_ERROR,
    };
};

export const showMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message,
    };
};

export const clearMessage = () => {
    return {
        type: CLEAR_MESSAGE,
    };
};

export const setActionBarShowRemove = (state) => {
    return {
        type: SET_ACTION_BAR_SHOW_REMOVE,
        payload: state,
    };
};

export const toggleFixHeader = () => ({
    type: TOGGLE_FIX_HEADER,
});

export const setHeaderHeight = (height) => ({
    type: SET_HEADER_HEIGHT,
    payload: height,
});

export const initActionBarShowRemove = () => {
    return {
        type: INIT_ACTION_BAR,
    };
};

export const getCurrencyTypes = () => {
    return {
        type: GET_CURRENCY_TYPES,
    };
};

export const getCurrencyTypesSuccess = (data) => {
    return {
        type: GET_CURRENCY_TYPES_SUCCESS,
        payload: data,
    };
};

export const getBanks = () => {
    return {
        type: GET_BANKS,
    };
};

export const getBanksSuccess = (data) => {
    return {
        type: GET_BANKS_SUCCESS,
        payload: data,
    };
};

export const getTaxWithholdingsTypes = () => {
    return {
        type: GET_TAX_WITHHOLDINGS_TYPES,
    };
};

export const getTaxWithholdingsTypesSuccess = (data) => {
    return {
        type: GET_TAX_WITHHOLDINGS_TYPES_SUCCESS,
        payload: data,
    };
};

export const triggerUpdateNotification = (latestVersion) => ({
    type: TRIGGER_UPDATE_NOTIFICATION,
    payload: latestVersion
});

export const hideUpdateNotification = () => ({
    type: HIDE_UPDATE_NOTIFICATION
});

export const checkForUpdates = () => ({
    type: CHECK_FOR_UPDATES,
});
