import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
} from '../constants/ActionsTypes'

const initialState = {
  results: []
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTICS:
      return { ...state }
    case GET_STATISTICS_SUCCESS:
      return {
        ...state,
        results: action.data
      }
    default:
      return state
  }
}

export default rootReducer