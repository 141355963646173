import {
    ADD_DEAL,
    UPDATE_DEAL,
    ADD_DEAL_SUCCESS,
    UPDATE_DEAL_SUCCESS,
    SEARCH_DEALS,
    SEARCH_DEALS_SUCCESS,
    AUTOCOMPLETE_DEALS,
    AUTOCOMPLETE_DEALS_SUCCESS,
    GET_DEAL,
    GET_DEAL_SUCCESS,
    REMOVE_DEAL,
    REMOVE_DEAL_SUCCESS,
    SHOW_DEAL_MODAL,
    HIDE_DEAL_MODAL,
    GET_DEALS_SEGMENT,
    GET_DEALS_SEGMENT_SUCCESS,
    GET_DEAL_PRODUCT_ID,
    GET_DEAL_PRODUCT_ID_SUCCESS,
    GET_DEALS_TREE,
    GET_DEALS_TREE_SUCCESS,
    GET_DEALS_BY_BRAND_SUCCESS,
    SET_SELECTED_DEALS_BRAND,
    CLEAR_SELECTED_DEALS_BRAND,
    INIT_DEAL_COMPONENT,
    SET_SELECTED_DEALS_SEGMENT
}
from "../constants/ActionsTypes";

const initialState = {
    success: false,
    selectedDealsBrand: null,
    selectedDealsSegment: null,
    dealsSegments: [],
    deal : null,
    search: [],
    autocompleteData : null,
    removeSuccess:false,
    product:null,
    dealItemDetail: {
      showDeal: false,
    },
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_DEAL :
        case UPDATE_DEAL:
            return { ...state, success: false }
        case ADD_DEAL_SUCCESS:
        case UPDATE_DEAL_SUCCESS:
            return { ...state, success: true  }
        case GET_DEAL :
            return { ...state, deal: null, success:false }
        case GET_DEAL_SUCCESS:
            return { ...state, deal: action.payload.deal }
        case GET_DEALS_SEGMENT :
            return { ...state, deals: null }
        case GET_DEALS_SEGMENT_SUCCESS:
            return { ...state, deals: action.payload.deal.response }
        case GET_DEALS_BY_BRAND_SUCCESS:
            return { ...state, deals: action.payload.deal.response }
        case SEARCH_DEALS:
            return { ...state, search: [], searchRequest : action.payload.params  }
        case SEARCH_DEALS_SUCCESS:
            return { ...state, search: action.payload.data }
        case GET_DEAL_PRODUCT_ID:
            return { ...state, product:null  }
        case GET_DEAL_PRODUCT_ID_SUCCESS:
            return { ...state, product: action.payload.deal.response }
        case AUTOCOMPLETE_DEALS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_DEALS_SUCCESS :
            return { ...state, autocompleteData: action.payload.data }
        case REMOVE_DEAL:
            return { ...state, deal: null, success:false, removeSuccess:false }
        case REMOVE_DEAL_SUCCESS:
            return { ...state, dealRemove: action.payload.response.data, removeSuccess : true }
        case INIT_DEAL_COMPONENT:
            return { ...state, ...initialState}
        case GET_DEALS_TREE:
            return { ...state, dealsSegments: null }
        case GET_DEALS_TREE_SUCCESS:
            return { ...state, dealsSegments: action.data }
        case SHOW_DEAL_MODAL:
            return { ...state, dealItemDetail: action.payload }
        case HIDE_DEAL_MODAL:
            return {
                ...state,
                dealItemDetail: {
                showModal: false,
                }
            }
        case SET_SELECTED_DEALS_BRAND:
            return { ...state, selectedDealsBrand: action.payload}
        case SET_SELECTED_DEALS_SEGMENT:
            return { ...state, selectedDealsSegment: action.payload}
        case CLEAR_SELECTED_DEALS_BRAND:
            return { ...state, selectedDealsBrand: null}
        default:
            return state
    }
}

export default rootReducer
