import {
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_IMAGES,
    UPDATE_PRODUCT_SUCCESS,
    SELECT_MAIN_PICTURE
} from '../constants/ActionsTypes'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig();



const initialState = {
    all: [],
    toSave: [],
    toRemove: [],
    remotes: [],
    mainPicture: null,
}

const _buildImageUrl = (imageFileName) => {
    return `${publicRuntimeConfig.productImagesBasePath}${imageFileName}`
}

const _wasMainPictureRemoved = (mainPicture, images) => {
    const toSaveimagesIDs = images.all.map( image => {return image.uid});
   return !toSaveimagesIDs.includes(mainPicture.uid)
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_PRODUCT_IMAGES:
            if(state.mainPicture && _wasMainPictureRemoved(state.mainPicture, action.images)){
                return { ...state,  all: action.images.all , mainPicture: null ,toSave: action.images.toSave , toRemove: action.images.toRemove}
            }
            return { ...state,  all: action.images.all , toSave: action.images.toSave , toRemove: action.images.toRemove}
        case UPDATE_PRODUCT_SUCCESS:
            return { ...state,  all: [] , toSave:[] , toRemove: [], remotes: [], mainPicture:null}
        case SELECT_MAIN_PICTURE:
            return { ...state,  mainPicture: action.mainPicture}
        case GET_PRODUCT_SUCCESS:
            const product = action.data;
            const files = product.imagesFiles || []
            const  mainPicture = product.mainImageFileName ? {uid: product.mainImageFileName , url : _buildImageUrl(product.mainImageFileName)} : null
            const images = files.map(fileName => {
                const url = _buildImageUrl(fileName);
                return {
                    uid: fileName,
                    name: fileName,
                    status: 'done',
                    origin: 'remote',
                    url: url
                }
            });
            return { ...state, mainPicture: mainPicture,  remotes: images , all: images , success: false , error: false}
        default:
            return state
    }
}

export default rootReducer