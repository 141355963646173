import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_BRAND,
    GET_BRAND_SUCCESS,
    GET_ALL_BRANDS,
    GET_ALL_BRANDS_SUCCESS,
    GET_BRAND_ERROR,
    SEARCH_BRANDS,
    ADD_UPDATE_BRAND,
    ADD_UPDATE_BRAND_ERROR,
    AUTOCOMPLETE_BRANDS
} from '../constants/ActionsTypes';

import {
    getBrand,
    getAllBrands,
    searchBrands,
    addBrand
} from '../api/Brands';

import {
    searchBrandsSuccess,
    addBrandSuccess,
    autocompleteBrandsSuccess
} from '../actions/Brands';

export const getCommonState = (state) => state.common;

function* callGetBrand({ payload }) {
    const { brandId, token, filter } = payload;
    try {
        const response = yield call(getBrand, brandId, token, filter);
        yield put({ type: GET_BRAND_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: ADD_UPDATE_BRAND_ERROR, error });
    }
}

function* callGetAllBrands() {
    try {
        const response = yield call(getAllBrands);
        yield put({ type: GET_ALL_BRANDS_SUCCESS, data: response.data });
    } catch (error) {
    }
}

function* searchBrandsRequest({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if (showRemove) {
        payload.borrado = showRemove;
    }

    try {
        const brands = yield call(searchBrands, payload);
        yield put(searchBrandsSuccess(brands));
    } catch (error) {
    }
}

function* addBrandsRequest({ payload }) {
    try {
        const brands = yield call(addBrand, payload.params);
        yield put(addBrandSuccess(brands));
    } catch (error) {
        yield put({ type: GET_BRAND_ERROR, error });
    }
}

function* autocompleteBrandsRequest({ payload }) {

    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    let query = {
        filtro: payload,
        page: 1,
        pageSize: 10
    }

    if (showRemove) {
        query.borrado = showRemove;
    }

    try {
        const brands = yield call(searchBrands, query);
        yield put(autocompleteBrandsSuccess(brands));
    } catch (error) {
    }
}

export function* searchBrandsSaga() {
    yield takeEvery(SEARCH_BRANDS, searchBrandsRequest);
}

export function* addBrandSaga() {
    yield takeEvery(ADD_UPDATE_BRAND, addBrandsRequest);
}

export function* getBrandSaga() {
    yield takeLatest(GET_BRAND, callGetBrand);
}

export function* getAllBrandsSaga() {
    yield takeLatest(GET_ALL_BRANDS, callGetAllBrands);
}

export function* autocompleteBrandsSaga() {
    yield throttle(1000, AUTOCOMPLETE_BRANDS, autocompleteBrandsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getBrandSaga),
        fork(getAllBrandsSaga),
        fork(searchBrandsSaga),
        fork(autocompleteBrandsSaga),
        fork(addBrandSaga),
    ]);
}
