import Axios from 'axios';
import getConfig from 'next/config';
import { getCurrentOrder, getCurrentQuotation, goOnline, setMergeOrders } from '../actions';

const { publicRuntimeConfig } = getConfig();
let interval = null;
let store = null;
let ipcRenderer = null;

export const isElectron = () => {
	// Renderer process
	if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
		return true;
	}

	// Main process
	if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
		return true;
	}

	// Detect the user agent when the `nodeIntegration` option is set to true
	if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
		return true;
	}

	return false;
};

//IPC
if (isElectron()) {

	if (window.require) {

		const electron = window.require('electron');
		ipcRenderer = electron.ipcRenderer;

		ipcRenderer.on('upload-orders-status', () => {
			store.dispatch(getCurrentOrder());
			store.dispatch(getCurrentQuotation());
			getOrdersToMerge()
		});

		ipcRenderer.on('get-orders-merge-response', (sender, data) => {
			store.dispatch(setMergeOrders(data))
		});


		ipcRenderer.on('do-orders-merge-response', () => {
			uploadOrders()
		})

	}
}

export const uploadOrders = () => {
	if (isElectron()) {

		if (ipcRenderer) {
			ipcRenderer.send('upload-orders')
		}
	}
};

export const onlineCheck = () => {

	if (!interval) {

		interval = setInterval(() => {
			Axios({ url: publicRuntimeConfig.apiCheckUrl, baseURL: '' }).then(
				() => {
					clearInterval(interval);
					interval = null;
					store.dispatch(goOnline());
					uploadOrders()
				}, () => {

				}
			);
		}, publicRuntimeConfig.apiCheckInterval);

	}

};

export const updateElectronOrder = (order, isOffline) => {

	if (isElectron()) {

		if (ipcRenderer) {
			ipcRenderer.send('update-order', order, isOffline)
		}
	}

};

export const confirmElectronOrder = (orderId, isOffline) => {

	if (isElectron()) {

		if (ipcRenderer) {
			ipcRenderer.send('confirm-order', orderId, isOffline)
		}
	}

};

export const updateElectronQuotation = (quotation, isOffline) => {

	if (isElectron()) {

		if (ipcRenderer) {
			ipcRenderer.send('update-quotation', quotation, isOffline)
		}
	}

};

export const updateUserConfig = () => {

	if (isElectron()) {

		if (ipcRenderer) {
			ipcRenderer.send('update-user-config')
		}
	}

};

export const getOrdersToMerge = () => {
	if (isElectron()) {
		if (ipcRenderer) {

			// Get logged user from ls
			const userJSON = localStorage.getItem('user');
			if (userJSON) {
				const user = JSON.parse(userJSON);
				ipcRenderer.send('get-orders-merge', user)
			}
		}
	}
};

export const doOrdersMerge = (actions) => {
	if (isElectron()) {
		if (ipcRenderer) {
			ipcRenderer.send('do-orders-merge', actions)
		}
	}
};

export const configureElectronTools = (newStore) => {
	store = newStore
};



