import {
    AUTOCOMPLETE_SELLERS_SUCCESS,
    AUTOCOMPLETE_SELLERS,
    CLEAR_AUTOCOMPLETE_SELLERS,
    SAVE_SELLER_ERROR,
    SAVE_SELLER_SUCCESS,
    SAVE_SELLER,
    SEARCH_SELLERS_SUCCESS,
    SEARCH_SELLERS,
} from '../constants/ActionsTypes';

export const searchSellers = (params) => {
    return {
        type: SEARCH_SELLERS,
        payload: params
    };
};

export const searchSellersSuccess = (data) => {
    return {
        type: SEARCH_SELLERS_SUCCESS,
        payload: data
    }
};

export const autocompleteSellers = (params) => {
    return {
        type: AUTOCOMPLETE_SELLERS,
        payload: params
    };
};

export const autocompleteSellersSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_SELLERS_SUCCESS,
        payload: data
    }
};

export const clearAutocompleteSellers = () => {
    return {
        type: CLEAR_AUTOCOMPLETE_SELLERS
    }
};

export const saveSeller = params => {
    return {
        type: SAVE_SELLER,
        payload: params
    }
};

export const saveSellerSuccess = () => {
    return {
        type: SAVE_SELLER_SUCCESS
    }
};

export const saveSellerError = response => {
    return {
        type: SAVE_SELLER_ERROR,
        payload: response
    }
};
