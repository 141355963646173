import Axios from 'axios';

export const getBrand = async (brandId, token, filter) => {
    const response = await Axios.get(`/marcas/${brandId}`, {
        params: {
            access_token: token,
            filter,
        },
    });
    return response;
};

export const searchBrands = async (searchParams) => {
    const response = await Axios.post('/marcas/search', searchParams);
    return response.data;
};

export const getAllBrands = async () => {
  const response = await Axios.get('/marcas/getallbrands');
  return response.data;
}

export const addBrand = async (data) => {
    const response = await Axios.put('/marcas', data);
    return response.data;
};
