import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Carousel } from 'antd'
import DealShowImageSvg from './deal-show-imageSvg'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import getConfig from 'next/config'
import { withTranslation } from 'react-i18next'
import { hideDealModal } from '../../actions'

const { publicRuntimeConfig } = getConfig()

class DealModal extends Component {
  constructor(props) {
    super(props)
    this.carousel = React.createRef()
    this.dealsUrls = props.dealsUrls || publicRuntimeConfig.dealImagesBasePath

    this.state = {
      sizeImg: [],
    }
  }

  componentDidMount() {
    this.prepareModal()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dealItemDetail.showModal !== this.props.dealItemDetail.showModal &&
      this.props.dealItemDetail.showModal
    ) {
      this.prepareModal()
    }
  }

  prepareModal = async () => {
    if (this.props.dealItemDetail.showModal && this.props.dealItemDetail.dealItem) {
      const sizes = await Promise.all(
        this.props.dealItemDetail.dealItem.files.map((_, index) => this.getMetaImage(index)),
      )
      this.setState({ sizeImg: sizes })
    }
  }

  getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        resolve({
          height: img.naturalHeight,
          width: img.naturalWidth,
        })
      }
      img.src = dataURL
    })

  getMetaImage = async (index) => {
    const { dealItemDetail } = this.props
    const fileAsDataURL = `${this.dealsUrls}${dealItemDetail.dealItem.files[index].image.fileId}`
    const dimensions = await this.getHeightAndWidthFromDataUrl(fileAsDataURL)
    return dimensions
  }

  handleSaveCoords = () => {
    this.props.onClose()
  }

  next = () => {
    this.carousel.next()
  }

  previous = () => {
    this.carousel.prev()
  }

  handleModalClose = () => {
    this.props.hideDealModal()
  }

  render() {
    const { dealItemDetail, onClose, t } = this.props
    const { sizeImg } = this.state
    const { showModal, dealItem, fileSelectedPos } = dealItemDetail
    const props = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <div>
        {showModal && (
          <Modal
            open={showModal}
            maskClosable={true}
            width={'800px'}
            onOk={onClose}
            onCancel={this.handleModalClose}
            okText={t('Close')}
            footer={[
              <Button key='back' type={'primary'} onClick={this.handleModalClose}>
                {t('Close')}
              </Button>,
            ]}
          >
            {fileSelectedPos !== null && sizeImg.length !== 0 ? (
              <Carousel
                ref={(node) => (this.carousel = node)}
                dotPosition='top'
                infinite={true}
                initialSlide={fileSelectedPos}
              >
                {dealItem.files.map((f, index) => (
                  <div key={f.image.uid}>
                    <DealShowImageSvg
                      dealId={dealItem.id || dealItem._id}
                      image={{ url: `${this.dealsUrls}${f.image.fileId}` }}
                      dealItem={f}
                      saveCoordProduct={this.handleSaveCoords}
                      sizeImg={sizeImg[index]}
                    />
                  </div>
                ))}
              </Carousel>
            ) : null}
            {dealItem && dealItem.files.length > 1 && (
              <div>
                <LeftCircleOutlined className='float-left' onClick={this.previous} />
                <RightCircleOutlined className='float-right' onClick={this.next} />
              </div>
            )}
          </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ deals }) => {
  const { dealItemDetail } = deals
  return { dealItemDetail }
}

export default connect(mapStateToProps, { hideDealModal })(withTranslation()(DealModal))
