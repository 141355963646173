import {
    AUTOCOMPLETE_PERSONS_SUCCESS,
    AUTOCOMPLETE_PERSONS,
    CLEAR_PERSONS_STATE,
    CREATE_PERSON_SUCCESS,
    CREATE_PERSON,
    DELETE_PERSON_SUCCESS,
    DELETE_PERSON,
    EDIT_PERSON_SUCCESS,
    EDIT_PERSON,
    GET_PERSON_SUCCESS,
    GET_PERSON,
    GET_PERSONS_SUCCESS,
    GET_PERSONS,
    SEARCH_PERSONS_SUCCESS,
    SEARCH_PERSONS,
} from '../constants/ActionsTypes';

export const searchPersons = params => {
    return {
        type: SEARCH_PERSONS,
        payload: params
    };
};

export const searchPersonsSuccess = data => {
    return {
        type: SEARCH_PERSONS_SUCCESS,
        payload: data
    }
};

export const autocompletePersons = params => {
    return {
        type: AUTOCOMPLETE_PERSONS,
        payload: params
    };
};

export const autocompletePersonsSuccess = data => {
    return {
        type: AUTOCOMPLETE_PERSONS_SUCCESS,
        payload: data
    }
};

export const getPersons = () => {
    return {
        type: GET_PERSONS
    };
};

export const getPersonsSuccess = data => {
    return {
        type: GET_PERSONS_SUCCESS,
        payload: data
    }
};

export const getPerson = id => {
    return {
        type: GET_PERSON,
        payload: id
    };
};

export const getPersonSuccess = data => {
    return {
        type: GET_PERSON_SUCCESS,
        payload: data
    }
};

export const createPerson = params => {
    return {
        type: CREATE_PERSON,
        payload: params
    };
};

export const createPersonSuccess = data => {
    return {
        type: CREATE_PERSON_SUCCESS,
        payload: data
    }
};

export const editPerson = params => {
    return {
        type: EDIT_PERSON,
        payload: params
    };
};

export const editPersonSuccess = data => {
    return {
        type: EDIT_PERSON_SUCCESS,
        payload: data
    }
};

export const deletePerson = id => {
    return {
        type: DELETE_PERSON,
        payload: id
    };
};

export const deletePersonSuccess = data => {
    return {
        type: DELETE_PERSON_SUCCESS,
        payload: data
    }
};

export const clearPersonsState = () => {
    return {
        type: CLEAR_PERSONS_STATE
    }
}
