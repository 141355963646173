import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { CONFIGURATE_CLIENT_UPDATE, GET_CONFIG_CLIENT, GET_LOGO_IMAGE } from "../constants/ActionsTypes";
import { configurateClientUpdateSuccess, getConfigClientSuccess, getLogoImageSuccess } from "../actions";

import {
    configureClientUpdate,
    getConfigClient,
    getLogoImage
} from '../api/ConfigureClient';

import {
    updateUserConfig,
} from '../electron/tools'

function* configureClientUpdateRequest({payload}){    
    try {
        const success = yield call(configureClientUpdate, payload);
        yield put( configurateClientUpdateSuccess(success) );
        updateUserConfig();
    } catch (error) {
        
    }
}

function* getConfigClientRequest({payload}){    
    try {
        const configClient = yield call(getConfigClient, payload);
        yield put( getConfigClientSuccess(configClient) );
    } catch (error) {
        
    }
}

function* getLogoImageRequest({payload}){    
    try {
        const logo = yield call(getLogoImage, payload);
        yield put( getLogoImageSuccess(logo) );
    } catch (error) {
        
    }
}


export function* configureClientUpdateSaga() {
    yield takeLatest(CONFIGURATE_CLIENT_UPDATE, configureClientUpdateRequest);
}

export function* getConfigClientSaga() {
    yield takeLatest(GET_CONFIG_CLIENT, getConfigClientRequest);
}

export function* getLogoImageSaga() {
    yield takeLatest(GET_LOGO_IMAGE, getLogoImageRequest);
}

export default function* rootSaga() {
    yield all([
        fork(configureClientUpdateSaga),   
        fork(getConfigClientSaga),   
        fork(getLogoImageSaga),   
    ]);
}