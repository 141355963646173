import { all, fork, put, takeEvery, throttle, select } from 'redux-saga/effects';
import getConfig from 'next/config'
import Axios from 'axios';

import { isElectron, onlineCheck } from '../electron/tools'

import {
  GO_OFFLINE,
  GO_ONLINE,
} from '../constants/ActionsTypes';

import {
  getCurrentOrder,
  getCurrentQuotation,
  getBrandsBySegments,
  getLineasCatalogos,
  getCategoryBrands,
  getCategoryModels,
  searchBannersBySegment
} from '../actions'

import { ROLES } from '../constants/AuthConstants';

//Selectors
const getUser = (state) => state.auth.user

function* goOfflineRequest() {
  try {

    if (isElectron()){
      
      //Start online check
      onlineCheck()

      // Re-dispatch all basic actions
      yield put(getCurrentOrder());

      // Get user
      const user = yield select(getUser)
      if (user && user.role.name == ROLES.CLIENT){
        yield put(getCurrentQuotation());
      }

      yield put(getBrandsBySegments());
      yield put(getLineasCatalogos());
      yield put(getCategoryBrands());
      yield put(getCategoryModels());
      yield put(searchBannersBySegment());
    }


  } catch (error) {
  }
}

function* goOnlineRequest() {
  try {

    if (isElectron()) {

      const { publicRuntimeConfig } = getConfig();
      //Set Axios online url
      Axios.defaults.baseURL = publicRuntimeConfig.apiUrl;
      delete Axios.defaults.headers.common['Offline'];
    }

  } catch (error) {
  }
}

export function* goOfflineSaga() {
  yield throttle(1000, GO_OFFLINE, goOfflineRequest);
}

export function* goOnlineSaga() {
  yield takeEvery(GO_ONLINE, goOnlineRequest);
}

export default function* rootSaga() {
  yield all([
    fork(goOfflineSaga),
    fork(goOnlineSaga),
  ]);
}