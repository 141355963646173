import { ROLES } from '../constants/AuthConstants';

export function getRolename(auth) {
    return auth && auth.user && auth.user.role ? auth.user.role.name : null;
}

export function getSupervisorField(auth) {
    return auth && auth.user ? auth.user.supervisor : null;
}

export function isTelemarketer(auth) {
    return auth && auth.user && auth.user.vendedor && auth.user.vendedor.tipovendedor === "t";
}

export function isSupervisor(auth) {
    return auth && auth.user && auth.user.supervisor ? auth.user.supervisor : false
}

export function isLoggedIn(auth) {
    // make sure that all user information is consistent (i.e.: the saved state matches what the app will need)
    return auth.userId && auth.token && auth.user && auth.user.role && auth.user.role.name && auth.user.role.permissions;
}

export function isRoleAllowed(auth, acl) {
    const rolename = getRolename(auth);
    return acl.indexOf(rolename) >= 0 || acl.indexOf(ROLES.ANY) >= 0 || !isLoggedIn(auth) && acl.indexOf(ROLES.UNAUTHENTICATED) >= 0;
}

export function getHomeForRole(rolename, mapping) {
    return mapping[rolename] ? mapping[rolename] : mapping[ROLES.ANY];
}

export function hasAccess(user, requiredPermissions = []) {
    const userPermissions = user && user.role ? user.role.permissions : null;

    if(!userPermissions) {
        return false;
    }

    for (let i = 0; i < requiredPermissions.length; i++) {
        const model = requiredPermissions[i].split(':')[0];
        let notExist = !userPermissions.ui.find(up => up === `*:*` || up === `${model}:*` || up === requiredPermissions[i]);
        if (notExist) return false;
    }

    return true;
}
