import Axios from 'axios';
const BASE_URL_END_POINT = '/accountForms';

export const getAccountForm = async (id) => {
    const url = `${BASE_URL_END_POINT}/listAccountFormById?id=${id}`;
    const response = await Axios.get(url);
    return response.data;
};

export const searchAccountForm = async (params) => {
    const url = `${BASE_URL_END_POINT}/searchAccountForm`;
    const response = await Axios.post(url, params);
    return response.data;
};

export const createAccountForm = async (formData) => {
    const url = `${BASE_URL_END_POINT}/createAccountForm`;
    const response = await Axios.post(url, formData);
    return response.data;
};

export const editAccountForm = async (formData) => {
    const url = `${BASE_URL_END_POINT}/updateAccountForm`;
    const response = await Axios.put(url, formData);
    return response.data;
};

export const deleteAccountForm = async (id) => {
    const url = `${BASE_URL_END_POINT}/deleteAccountForm?id=${id}`;
    const response = await Axios.delete(url);
    return response.data;
};

export const completeAccountForm = async ({ id }) => {
    const url = `${BASE_URL_END_POINT}/completeAccountForm?id=${id}`;
    const response = await Axios.post(url);
    return response.data;
};

export const getSegmentsWithBrands = async () => {
    const url = `${BASE_URL_END_POINT}/getSegmentsWithBrands`;
    const response = await Axios.get(url);
    return response.data;
};

export const getPeople = async () => {
    const url = `personas/listPeople`;
    const response = await Axios.get(url);
    return response.data;
};

export const getWorks = async () => {
    const url = `trabajos/listWorks`;
    const response = await Axios.get(url);
    return response.data;
};

export const getLink = async (id) => {
    const url = `${BASE_URL_END_POINT}/generateTokenForForm?id=${id}`;
    const response = await Axios.get(url);
    return response.data;
};
