import {
    EXPORT_APPLICATIONS_ERROR,
    EXPORT_APPLICATIONS_SUCCESS,
    EXPORT_APPLICATIONS,
    SAVE_FILE_APPLICATIONS_ERROR,
    SAVE_FILE_APPLICATIONS_SUCCESS,
    SAVE_FILE_APPLICATIONS,
} from '../constants/ActionsTypes';

export const saveApplications = (file) => {
    return {
        type: SAVE_FILE_APPLICATIONS,
        payload: file,
    };
};

export const saveApplicationsSuccess = () => {
    return {
        type: SAVE_FILE_APPLICATIONS_SUCCESS,
    };
};

export const saveApplicationsError = () => {
    return {
        type: SAVE_FILE_APPLICATIONS_ERROR,
    };
};

export const exportApplications = (values) => {
    return {
        type: EXPORT_APPLICATIONS,
        payload: values,
    };
};

export const exportApplicationsSuccess = () => {
    return {
        type: EXPORT_APPLICATIONS_SUCCESS,
    };
};

export const exportApplicationsError = () => {
    return {
        type: EXPORT_APPLICATIONS_ERROR,
    };
};
