import {
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD,
    CLEAR_AUTOCOMPLETE_SELLERS_TELEMARKETER,
    CLEAR_AUTOCOMPLETE_SELLERS,
    CREATE_USER,
    DELETE_USER,
    GET_BRANDS_BY_USER_SUCCESS,
    GET_BRANDS_BY_USER,
    GET_CLIENT,
    GET_CLIENTS,
    GET_SELLER,
    GET_SELLERS_TELEMARKETER,
    GET_SELLERS,
    GET_USER,
    GET_USERS,
    INIT_SEND_PASSWORD_REQUEST_EMAIL,
    INIT_SET_PASSWORD,
    SEARCH_USERS_SUCCESS,
    SEARCH_USERS,
    SEND_PASSWORD_REQUEST_EMAIL_ERROR,
    SEND_PASSWORD_REQUEST_EMAIL_SUCCESS,
    SEND_PASSWORD_REQUEST_EMAIL,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_EMAIL,
    UPDATE_USER,
    VERIFY_TOKEN_ERROR,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN,
} from '../constants/ActionsTypes';

export const getUser = (userId, token) => {
    return {
        type: GET_USER,
        payload: { userId, token },
    };
};

export const getUsers = (token) => {
    return {
        type: GET_USERS,
        payload: token,
    };
};

export const updateUser = (values, token) => {
    return {
        type: UPDATE_USER,
        payload: { values, token },
    };
};

export const createUser = (values, token) => {
    return {
        type: CREATE_USER,
        payload: { values, token },
    };
};

export const deleteUser = (userId) => {
    return {
        type: DELETE_USER,
        payload: { userId },
    };
};

export const getClients = (searchText) => {
    return {
        type: GET_CLIENTS,
        payload: { searchText },
    };
};

export const getClient = (clientId) => {
    return {
        type: GET_CLIENT,
        payload: { clientId },
    };
};

export const getSellers = (searchText) => {
    return {
        type: GET_SELLERS,
        payload: { searchText },
    };
};

export const getSellersTelemarketer = (searchText) => {
    return {
        type: GET_SELLERS_TELEMARKETER,
        payload: { searchText },
    };
};

export const getSeller = (sellerId, token) => {
    return {
        type: GET_SELLER,
        payload: { sellerId, token },
    };
};

export const searchUsers = (params) => {
    return {
        type: SEARCH_USERS,
        payload: params,
    };
};

export const searchUsersSuccess = (data) => {
    return {
        type: SEARCH_USERS_SUCCESS,
        payload: data,
    };
};

export const getBrandsByUser = (params) => {
    return {
        type: GET_BRANDS_BY_USER,
        payload: params,
    };
};

export const getBrandsByUserSuccess = (data) => {
    return {
        type: GET_BRANDS_BY_USER_SUCCESS,
        payload: data,
    };
};

export const changePassword = (params) => {
    return {
        type: CHANGE_PASSWORD,
        payload: params,
    };
};

export const changePasswordSuccess = (data) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const updateEmailByUser = (params) => {
    return {
        type: UPDATE_EMAIL,
        payload: params,
    };
};

export const updateEmailByUserSuccess = (data) => {
    return {
        type: UPDATE_EMAIL_SUCCESS,
        payload: data,
    };
};

export const sendPasswordRequestEmail = (params) => {
    return {
        type: SEND_PASSWORD_REQUEST_EMAIL,
        payload: params,
    };
};

export const sendPasswordRequestEmailSuccess = (data) => {
    return {
        type: SEND_PASSWORD_REQUEST_EMAIL_SUCCESS,
        payload: data,
    };
};

export const sendPasswordRequestEmailError = (data) => {
    return {
        type: SEND_PASSWORD_REQUEST_EMAIL_ERROR,
        payload: data,
    };
};

export const initSendPasswordRequestEmail = (data) => {
    return {
        type: INIT_SEND_PASSWORD_REQUEST_EMAIL,
        payload: data,
    };
};

export const setPassword = (data) => {
    return {
        type: SET_PASSWORD,
        payload: data,
    };
};

export const setPasswordSuccess = (data) => {
    return {
        type: SET_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const setPasswordError = (data) => {
    return {
        type: SET_PASSWORD_ERROR,
        payload: data,
    };
};

export const initSetPassword = (data) => {
    return {
        type: INIT_SET_PASSWORD,
        payload: data,
    };
};

export const verifyToken = (token) => {
    return {
        type: VERIFY_TOKEN,
        payload: { token },
    };
};

export const verifyTokenSuccess = (data) => {
    return {
        type: VERIFY_TOKEN_SUCCESS,
        payload: data,
    };
};

export const verifyTokenError = (data) => {
    return {
        type: VERIFY_TOKEN_ERROR,
        payload: data,
    };
};

export const clearAutocompleteSeller = () => {
    return {
        type: CLEAR_AUTOCOMPLETE_SELLERS,
    };
};

export const clearAutocompleteSellerTelemarketer = () => {
    return {
        type: CLEAR_AUTOCOMPLETE_SELLERS_TELEMARKETER,
    };
};
