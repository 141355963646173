import { SEARCH_BANNERS, SEARCH_BANNERS_SUCCESS, AUTOCOMPLETE_BANNERS, AUTOCOMPLETE_BANNERS_SUCCESS, ADD_BANNER, ADD_BANNER_SUCCESS, GET_BANNER, GET_BANNER_SUCCESS, UPDATE_BANNER, UPDATE_BANNER_SUCCESS, SEARCH_BANNERS_BY_SEGMENT, SEARCH_BANNERS_BY_SEGMENT_SUCCESS } from "../constants/ActionsTypes";

const initialState = {
    search: null,
    searchRequest:null,
    autocompleteData: null,
    success:false, 
    message:null,
    bannersBySegment:null
}

function rootReducer(state = initialState, action) {
    switch (action.type) {        
        case SEARCH_BANNERS:
            return { ...state, searchRequest: action.payload, search: null }
        case SEARCH_BANNERS_SUCCESS:
            return { ...state, search: action.payload.response, success:false }                
        case AUTOCOMPLETE_BANNERS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_BANNERS_SUCCESS:
            return { ...state, autocompleteData: action.payload }  
        case ADD_BANNER:
            return {...state, success:false}
        case ADD_BANNER_SUCCESS:
            return { ...state, success:true, message:action.payload.response.data, banner:null }
        case GET_BANNER:
            return { ...state, banner: null, success:false }
        case GET_BANNER_SUCCESS:
            return { ...state, banner: action.payload }
        case UPDATE_BANNER:
            return { ...state, success:false }
        case UPDATE_BANNER_SUCCESS:
            return {...state, success:true, message:action.payload.data, banner:null}
        case SEARCH_BANNERS_BY_SEGMENT:
            return { ...state, bannersBySegment: null }
        case SEARCH_BANNERS_BY_SEGMENT_SUCCESS:
            return { ...state, bannersBySegment: action.payload.response }        
        default:
            return state
    }
}

export default rootReducer