import {
    SEARCH_PERSONS,
    SEARCH_PERSONS_SUCCESS,
    AUTOCOMPLETE_PERSONS,
    AUTOCOMPLETE_PERSONS_SUCCESS,
    GET_PERSONS,
    GET_PERSONS_SUCCESS,
    GET_PERSON,
    GET_PERSON_SUCCESS,
    CREATE_PERSON,
    CREATE_PERSON_SUCCESS,
    EDIT_PERSON,
    EDIT_PERSON_SUCCESS,
    DELETE_PERSON,
    DELETE_PERSON_SUCCESS,
    CLEAR_PERSONS_STATE
} from '../constants/ActionsTypes';

const initialState = {
    search: [],
    list: [],
    autocomplete: [],
    element: {},
    isCreatedSuccess: false,
    isEditedSuccess: false,
    isDeleteSuccess: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_PERSONS:
            return { ...state, search: [] };
        case SEARCH_PERSONS_SUCCESS:
            return { ...state, search: action.payload };
        case AUTOCOMPLETE_PERSONS:
            return { ...state, autocomplete: [] };
        case AUTOCOMPLETE_PERSONS_SUCCESS:
            return { ...state, autocomplete: action.payload };
        case GET_PERSONS:
            return { ...state, list: [] };
        case GET_PERSONS_SUCCESS:
            return { ...state, list: action.payload };
        case GET_PERSON:
            return { ...state, element: {} };
        case GET_PERSON_SUCCESS:
            return { ...state, element: action.payload };
        case CREATE_PERSON:
            return { ...state, isCreatedSuccess: false };
        case CREATE_PERSON_SUCCESS:
            return { ...state, isCreatedSuccess: true };
        case EDIT_PERSON:
            return { ...state, isEditedSuccess: false };
        case EDIT_PERSON_SUCCESS:
            return { ...state, isEditedSuccess: true };
        case DELETE_PERSON:
            return { ...state, isDeleteSuccess: false };
        case DELETE_PERSON_SUCCESS:
            return { ...state, isDeleteSuccess: true };
        case CLEAR_PERSONS_STATE:
            return { ...initialState }
        default:
            return state
    }
}

export default rootReducer
