import {
    CLEAR_ACCOUNT_FORM_STATE,
    CLEAR_LINK_STATE,
    COMPLETE_ACCOUNT_FORM_ERROR,
    COMPLETE_ACCOUNT_FORM_SUCCESS,
    COMPLETE_ACCOUNT_FORM,
    CREATE_ACCOUNT_FORM_ERROR,
    CREATE_ACCOUNT_FORM_SUCCESS,
    CREATE_ACCOUNT_FORM,
    DELETE_ACCOUNT_FORM_ERROR,
    DELETE_ACCOUNT_FORM_SUCCESS,
    DELETE_ACCOUNT_FORM,
    EDIT_ACCOUNT_FORM_ERROR,
    EDIT_ACCOUNT_FORM_SUCCESS,
    EDIT_ACCOUNT_FORM,
    GET_ACCOUNT_FORM_ERROR,
    GET_ACCOUNT_FORM_SUCCESS,
    GET_ACCOUNT_FORM,
    GET_LINK_FOR_DETAIL_ERROR,
    GET_LINK_FOR_DETAIL_SUCCESS,
    GET_LINK_FOR_DETAIL,
    GET_SEGMENTS_WITH_BRANDS_ERROR,
    GET_SEGMENTS_WITH_BRANDS_SUCCESS,
    GET_SEGMENTS_WITH_BRANDS,
    GET_WORKS_ERROR,
    GET_WORKS_SUCCESS,
    GET_WORKS,
    SEARCH_ACCOUNT_FORM_ERROR,
    SEARCH_ACCOUNT_FORM_SUCCESS,
    SEARCH_ACCOUNT_FORM,
} from '../constants/ActionsTypes';

export const getAccountForm = (id) => {
    return {
        type: GET_ACCOUNT_FORM,
        payload: id,
    };
};

export const getAccountFormSuccess = (data) => {
    return {
        type: GET_ACCOUNT_FORM_SUCCESS,
        payload: data,
    };
};

export const getAccountFormError = (response) => {
    return {
        type: GET_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const searchAccountForm = (params) => {
    return {
        type: SEARCH_ACCOUNT_FORM,
        payload: params,
    };
};

export const searchAccountFormSuccess = (data) => {
    return {
        type: SEARCH_ACCOUNT_FORM_SUCCESS,
        payload: data,
    };
};

export const searchAccountFormError = (response) => {
    return {
        type: SEARCH_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const createAccountForm = (params) => {
    return {
        type: CREATE_ACCOUNT_FORM,
        payload: params,
    };
};

export const createAccountFormSuccess = (data) => {
    return {
        type: CREATE_ACCOUNT_FORM_SUCCESS,
        payload: data,
    };
};

export const createAccountFormError = (response) => {
    return {
        type: CREATE_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const editAccountForm = (params) => {
    return {
        type: EDIT_ACCOUNT_FORM,
        payload: params,
    };
};

export const editAccountFormSuccess = (data) => {
    return {
        type: EDIT_ACCOUNT_FORM_SUCCESS,
        payload: data,
    };
};

export const editAccountFormError = (response) => {
    return {
        type: EDIT_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const deleteAccountForm = (id) => {
    return {
        type: DELETE_ACCOUNT_FORM,
        payload: id,
    };
};

export const deleteAccountFormSuccess = () => {
    return {
        type: DELETE_ACCOUNT_FORM_SUCCESS,
    };
};

export const deleteAccountFormError = (response) => {
    return {
        type: DELETE_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const completeAccountForm = (params) => {
    return {
        type: COMPLETE_ACCOUNT_FORM,
        payload: params,
    };
};

export const completeAccountFormSuccess = () => {
    return {
        type: COMPLETE_ACCOUNT_FORM_SUCCESS,
    };
};

export const completeAccountFormError = (response) => {
    return {
        type: COMPLETE_ACCOUNT_FORM_ERROR,
        payload: response,
    };
};

export const getWorks = () => {
    return {
        type: GET_WORKS,
    };
};

export const getWorksSuccess = (data) => {
    return {
        type: GET_WORKS_SUCCESS,
        payload: data,
    };
};

export const getWorksError = (response) => {
    return {
        type: GET_WORKS_ERROR,
        payload: response,
    };
};

export const getSegmentsWithBrands = () => {
    return {
        type: GET_SEGMENTS_WITH_BRANDS,
    };
};

export const getSegmentsWithBrandsSuccess = (data) => {
    return {
        type: GET_SEGMENTS_WITH_BRANDS_SUCCESS,
        payload: data,
    };
};

export const getSegmentsWithBrandsError = (response) => {
    return {
        type: GET_SEGMENTS_WITH_BRANDS_ERROR,
        payload: response,
    };
};

export const clearAccountFormState = () => {
    return {
        type: CLEAR_ACCOUNT_FORM_STATE,
    };
};

export const getLinkForDetail = (id) => {
    return {
        type: GET_LINK_FOR_DETAIL,
        payload: id,
    };
};

export const getLinkForDetailSuccess = (data) => {
    return {
        type: GET_LINK_FOR_DETAIL_SUCCESS,
        payload: data,
    };
};

export const getLinkForDetailError = (data) => {
    return {
        type: GET_LINK_FOR_DETAIL_ERROR,
        payload: data,
    };
};

export const clearLinkState = () => {
    return {
        type: CLEAR_LINK_STATE,
    };
};
