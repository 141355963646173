import { 
    GET_CURRENT_QUOTATION_SUCCESS, ADD_ITEM_QUOTATION_FROM_CODE_SUCCESS, CHANGE_ITEM_QUANTITY_QUOTATION_SUCCESS, REMOVE_ITEM_QUOTATION_SUCCESS, SET_DATA_QUOTATION_SUCCESS, ADD_ITEM_QUOTATION_SUCCESS, SAVE_QUOTATION_SUCCESS, CANCEL_QUOTATION_SUCCESS, NEW_QUOTATION_SUCCESS, GET_QUOTATIONS_BY_USER, GET_QUOTATIONS_BY_USER_SUCCESS, CHANGE_CURRENT_QUOTATION, GET_QUOTATION_BY_ID, GET_QUOTATION_BY_ID_SUCCESS, ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS 
} from "../constants/ActionsTypes";

  
  const initialState = {
    current: null,  
    successAdd:null ,
    search:null,
    changeQuotation:false,
    quotation:null
  }
  
  function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CURRENT_QUOTATION_SUCCESS:
            return { ...state, current: action.data, changeQuotation:false }        
        case NEW_QUOTATION_SUCCESS:
            return { ...state, current: action.data }        
        case ADD_ITEM_QUOTATION_FROM_CODE_SUCCESS:
            return { ...state, current:action.payload.data, successAdd:true}    
        case CHANGE_ITEM_QUANTITY_QUOTATION_SUCCESS:
            return { ...state, current:action.payload.data }
        case REMOVE_ITEM_QUOTATION_SUCCESS:
            return { ...state, current:action.payload.data }
        case SET_DATA_QUOTATION_SUCCESS:
            return { ...state, current:action.payload.data }
        case ADD_ITEM_QUOTATION_SUCCESS:
            return { ...state, current:action.payload.data }
        case ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS:
            return { ...state, current:action.payload.data.data }
        case SAVE_QUOTATION_SUCCESS:
            return { ...state, current:null }
        case CANCEL_QUOTATION_SUCCESS:
            return { ...state, current:null }
        case GET_QUOTATIONS_BY_USER:
            return {... state, search:null}
        case GET_QUOTATIONS_BY_USER_SUCCESS:
            return {... state, search:action.payload}
        case GET_QUOTATION_BY_ID:
            return {... state, quotation:null}
        case GET_QUOTATION_BY_ID_SUCCESS:
            return {... state, quotation:action.payload}
        case CHANGE_CURRENT_QUOTATION:
            return { ... state, current:action.payload, changeQuotation:true }
        default:
            return state
    }
  }
  
  export default rootReducer