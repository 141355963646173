import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_TRANSPORTS,
    GET_TRANSPORTS_SUCCESS,
    GET_TRANSPORTS_ERROR,
    GET_TRANSPORT,
    GET_TRANSPORT_SUCCESS,
    GET_TRANSPORT_ERROR,
    SEARCH_TRANSPORTS,
    AUTOCOMPLETE_TRANSPORTS
} from '../constants/ActionsTypes';

import {
    getTransports,
    getTransport,
    searchTransports
} from '../api/Transports';

import {
    searchTransportsSuccess,    
    autocompleteTransportsSuccess
} from '../actions/Transports';

export const getCommonState = (state) => state.common;

function* callGetTransports({ payload }) {
    const { token } = payload;
    try {
        const response = yield call(getTransports, token);
        yield put({ type: GET_TRANSPORTS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_TRANSPORTS_ERROR, error });
    }
}

function* callGetTransport({ payload }) {
    const { transportId, token } = payload;
    try {
        const response = yield call(getTransport, transportId, token);
        yield put({ type: GET_TRANSPORT_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_TRANSPORT_ERROR, error });
    }
}


function* searchTranporstRequest({payload}) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;
    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove;
    }  
    try {
        const transports = yield call(searchTransports, payload);
        yield put(searchTransportsSuccess(transports));
    } catch (error) {
    }
}

function* autocompleteTransportsRequest({payload}) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;
    const query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }
    if(AdminActionBar.showRemove){
        query.borrado = AdminActionBar.showRemove;
    }  
  
    try {
        const transports = yield call(searchTransports, query);
        yield put(autocompleteTransportsSuccess(transports));
    } catch (error) {
    }
}

export function* searchTransportsSaga() {
    yield takeEvery(SEARCH_TRANSPORTS, searchTranporstRequest);
}

export function* getTransportsSaga() {
    yield takeLatest(GET_TRANSPORTS, callGetTransports);
}

export function* getTransportSaga() {
    yield takeLatest(GET_TRANSPORT, callGetTransport);
}

export function* autocompleteTransportsSaga() {
    yield throttle(1000, AUTOCOMPLETE_TRANSPORTS, autocompleteTransportsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getTransportsSaga),
        fork(getTransportSaga),
        fork(searchTransportsSaga),
        fork(autocompleteTransportsSaga),
    ]);
}