import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR,
    SEARCH_GROUPS,
    AUTOCOMPLETE_GROUPS,
    GET_GROUP,    
} from '../constants/ActionsTypes';

import {    
    getSubline,
    searchSublines
} from '../api/Sublines';

import {
    searchGroupsSuccess,    
    autocompleteGroupsSuccess
} from '../actions/Groups';


export const getCommonState = (state) => state.common;

function* callGetGroup({ payload }) {
    const { groupId } = payload;  
    try {
        const response = yield call(getSubline, groupId);
        //Don't forget to add error here if code is 422        
        yield put({ type: GET_GROUP_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: GET_GROUP_ERROR, error });
    }
}

function* searchGroupstRequest({payload}) {

    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;
    
    if(showRemove){
        payload.borrado = showRemove;
    }  

    try {
        const transports = yield call(searchSublines, payload);
        yield put(searchGroupsSuccess(transports));
    } catch (error) {
    }
}

function* autocompleteGroupsRequest({payload}) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;   
    
    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(showRemove){
        query.borrado = showRemove;
    } 
  
    try {
        const groups = yield call(searchSublines, query);
        yield put(autocompleteGroupsSuccess(groups));
    } catch (error) {
    }
}

export function* searchGroupsSaga() {
    yield takeEvery(SEARCH_GROUPS, searchGroupstRequest);
}

export function* getGroupSaga() {
    yield takeLatest(GET_GROUP, callGetGroup);
}

export function* autocompleteGroupsSaga() {
    yield throttle(1000, AUTOCOMPLETE_GROUPS, autocompleteGroupsRequest);
}

export default function* rootSaga() {
    yield all([        
        fork(getGroupSaga),
        fork(searchGroupsSaga),
        fork(autocompleteGroupsSaga),
    ]);
}