import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    SEARCH_USERS_SUCCESS,
    SEARCH_USERS_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_CLIENTS_SUCCESS,
    GET_CLIENT_SUCCESS,
    GET_SELLERS_SUCCESS,
    GET_SELLERS_TELEMARKETER_SUCCESS,
    GET_SELLER_SUCCESS,
    GET_SELLER_ERROR,
    SEARCH_USERS,
    GET_BRANDS_BY_USER,
    GET_BRANDS_BY_USER_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_SUCCESS,
    SEND_PASSWORD_REQUEST_EMAIL,
    SEND_PASSWORD_REQUEST_EMAIL_SUCCESS,
    SEND_PASSWORD_REQUEST_EMAIL_ERROR,
    INIT_SEND_PASSWORD_REQUEST_EMAIL,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    INIT_SET_PASSWORD,
    VERIFY_TOKEN,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    CLEAR_AUTOCOMPLETE_SELLERS,
    CLEAR_AUTOCOMPLETE_SELLERS_TELEMARKETER,
} from '../constants/ActionsTypes'

const initialState = {
    clients: [], //Verificar si necesito esto
    sellers: [], //Verificar si necesito esto
    user: {
        clienteId: '',
        vendedorId: '',
        username: '',
        password: '',
        repeatPassword: '',
        enabled:true,
        role: {
            name: ''
        },
        segmentos: [],
        supervisor: '',
    },
    deleting: false,
    client: undefined,
    message: '',
    userList: [],
    searchRequest: [],
    clientList: [],
    sellerList: [],
    brands:null,
    success:null,
    successEmail:null,
    passwordRecovery: {
        sendingEmail: false,
        error: null,
        emailSent: false
    },
    resetPassword: {
        error: null,
        processing: false,
        passwordChanged: false,
        isTokenValid: true
    }
}

//TODO: Error Checks
function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER:
            return { ...state, user: null }
        case GET_USER_SUCCESS:
            return { ...state, user: action.data }
        case GET_USER_ERROR:
            return { ...state, user: action }
        case GET_USERS_SUCCESS:
            return {
                ...state, userList: action, user: {
                    clienteId: '',
                    vendedorId: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    role: {
                        name: ''
                    },
                    segmentos: [],
                    supervisor: '',
                }
            }
        case GET_USERS_ERROR:
            return { ...state, users: action }
        case CREATE_USER:
            return {
                ...state, message: '', error: false, user: {
                    clienteId: '',
                    vendedorId: '',
                    username: '',
                    password: '',
                    enabled : true,
                    repeatPassword: '',
                    role: {
                        name: ''
                    },
                    segmentos: [],
                    supervisor: '',
                }
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state, message: action.data.data, error: false, success: true, user: {
                    clienteId: '',
                    vendedorId: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    role: {
                        name: ''
                    },
                    segmentos: [],
                    supervisor: '',
                }
            }
        case CREATE_USER_ERROR:
            return { ...state, message: action.data.message, error: true }
        case UPDATE_USER_SUCCESS:
            return {
                ...state, message: action.data.data, error: false, success: true, user: {
                    clienteId: '',
                    vendedorId: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    role: {
                        name: ''
                    },
                    segmentos: [],
                    supervisor: '',
                }
            }

        case UPDATE_USER_ERROR:
            return { ...state, message: action.data.message, error: true }

        case DELETE_USER:
            return { ...state, deleting: true }
        case DELETE_USER_SUCCESS:
            return { ...state, message: action.data.message, deleting: false }
        case DELETE_USER_ERROR:
            return { ...state, message: action.error, deleting: false, error: true }
        case GET_BRANDS_BY_USER:
            return { ...state, brands: null }
        case GET_BRANDS_BY_USER_SUCCESS:
            return { ...state, brands:action.payload }
        case CHANGE_PASSWORD:
            return { ...state, success: null }
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, success:action.payload }
        case UPDATE_EMAIL:
            return { ...state, successEmail: null }
        case UPDATE_EMAIL_SUCCESS:
            return {...state, successEmail:true}
        case GET_CLIENTS_SUCCESS:
            return { ...state, clientList: action.data }
        case GET_CLIENT_SUCCESS:
            return { ...state, client: action.data }
        case GET_SELLERS_SUCCESS:
            return { ...state, sellerList: action.data }
        case GET_SELLERS_TELEMARKETER_SUCCESS:
            return { ...state, sellerTelemarketerList: action.data }
        case GET_SELLER_SUCCESS:
            return { ...state, seller: action.data }
        case GET_SELLER_ERROR:
            return { ...state, seller: action }
        case SEARCH_USERS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_USERS_SUCCESS:
            return {
                ...state, userList: action.data.response, user: {
                    clienteId: '',
                    vendedorId: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    role: {
                        name: ''
                    },
                    segmentos: [],
                    supervisor: '',
                }
            }
        case SEARCH_USERS_ERROR:
            return { ...state, message: action.error, error: true }
        case SEND_PASSWORD_REQUEST_EMAIL:
            return {
                ...state,
                passwordRecovery: {
                    ...state.passwordRecovery,
                    sendingEmail: true,
                    error: null
                }
            }
        case INIT_SEND_PASSWORD_REQUEST_EMAIL:
            return {
                ...state,
                passwordRecovery: {
                    ...state.passwordRecovery,
                    sendingEmail: false,
                    error: null,
                    emailSent: false
                }
            }
        case SEND_PASSWORD_REQUEST_EMAIL_SUCCESS:
            return {
                ...state,
                passwordRecovery: {
                    ...state.passwordRecovery,
                    sendingEmail: false,
                    error: null,
                    emailSent: true
                }
            }
        case SEND_PASSWORD_REQUEST_EMAIL_ERROR:
            return {
                ...state,
                passwordRecovery: {
                    ...state.passwordRecovery,
                    sendingEmail: false,
                    error: action.payload.message,
                    emailSent: false
                }
            }
        case INIT_SET_PASSWORD:
            return {
                ...state,
                resetPassword: {
                    error: null,
                    processing: false,
                    passwordChanged: false,
                    isTokenValid: true
                }
            }
        case SET_PASSWORD:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    error: null,
                    processing: true,
                    passwordChanged: false,
                }
            }
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    error: null,
                    processing: false,
                    passwordChanged: true
                }
            }
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    error: action.payload.message,
                    processing: false,
                    passwordChanged: false
                }
            }
        case VERIFY_TOKEN_SUCCESS:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isTokenValid: action.payload.isTokenValid
                }
            }
        case VERIFY_TOKEN_ERROR:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    error: action.payload.message,
                    isTokenValid: true
                }
            }
        case CLEAR_AUTOCOMPLETE_SELLERS:
            return { ...state, sellerList: [] }
        case CLEAR_AUTOCOMPLETE_SELLERS_TELEMARKETER:
            return { ...state, sellerTelemarketerList: [] }

        default:
            return state
    }
}

export default rootReducer
