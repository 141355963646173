import {
    ADD_ITEM_QUOTATION_FROM_CODE_SUCCESS,
    ADD_ITEM_QUOTATION_FROM_CODE,
    ADD_ITEM_QUOTATION_SUCCESS,
    ADD_ITEM_QUOTATION,
    ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS,
    ADD_PRODUCTS_CURRENT_QUOTATION,
    CANCEL_QUOTATION_SUCCESS,
    CANCEL_QUOTATION,
    CHANGE_CURRENT_QUOTATION,
    CHANGE_ITEM_QUANTITY_QUOTATION_SUCCESS,
    CHANGE_ITEM_QUANTITY_QUOTATION,
    GET_CURRENT_QUOTATION,
    GET_QUOTATION_BY_ID_SUCCESS,
    GET_QUOTATION_BY_ID,
    GET_QUOTATIONS_BY_USER_SUCCESS,
    GET_QUOTATIONS_BY_USER,
    NEW_QUOTATION_SUCCESS,
    NEW_QUOTATION,
    REMOVE_ITEM_QUOTATION_SUCCESS,
    REMOVE_ITEM_QUOTATION,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION,
    SET_DATA_QUOTATION_SUCCESS,
    SET_DATA_QUOTATION,
} from '../constants/ActionsTypes';

export const getCurrentQuotation = () => {
    return {
        type: GET_CURRENT_QUOTATION,
    };
};

export const addItemQuotationFromCode = (params) => {
    return {
        type: ADD_ITEM_QUOTATION_FROM_CODE,
        payload: params,
    };
};

export const addItemQuotationFromCodeSuccess = (response) => {
    return {
        type: ADD_ITEM_QUOTATION_FROM_CODE_SUCCESS,
        payload: response.data,
    };
};

export const addItemToQuotation = (params) => {
    return {
        type: ADD_ITEM_QUOTATION,
        payload: params,
    };
};

export const addItemToQuotationSuccess = (response) => {
    return {
        type: ADD_ITEM_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const changeItemQuantityQuotation = (params) => {
    return {
        type: CHANGE_ITEM_QUANTITY_QUOTATION,
        payload: params,
    };
};

export const changeItemQuantityQuotationSuccess = (response) => {
    return {
        type: CHANGE_ITEM_QUANTITY_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const removeItemFromQuotation = (params) => {
    return {
        type: REMOVE_ITEM_QUOTATION,
        payload: params,
    };
};

export const removeItemFromQuotationSuccess = (response) => {
    return {
        type: REMOVE_ITEM_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const setDataToQuotation = (params) => {
    return {
        type: SET_DATA_QUOTATION,
        payload: params,
    };
};

export const setDataToQuotationSuccess = (response) => {
    return {
        type: SET_DATA_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const saveQuotation = (params) => {
    return {
        type: SAVE_QUOTATION,
        payload: params,
    };
};

export const saveQuotationSuccess = (response) => {
    return {
        type: SAVE_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const cancelQuotation = (params) => {
    return {
        type: CANCEL_QUOTATION,
        payload: params,
    };
};

export const cancelQuotationSuccess = (response) => {
    return {
        type: CANCEL_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const newQuotation = (params) => {
    return {
        type: NEW_QUOTATION,
        payload: params,
    };
};

export const newQuotationSuccess = (response) => {
    return {
        type: NEW_QUOTATION_SUCCESS,
        payload: response.data,
    };
};

export const getQuotationsByUser = (params) => {
    return {
        type: GET_QUOTATIONS_BY_USER,
        payload: params,
    };
};

export const getQuotationsByUserSuccess = (response) => {
    return {
        type: GET_QUOTATIONS_BY_USER_SUCCESS,
        payload: response.data,
    };
};

export const changeCurrentQuotation = (quotation) => {
    return {
        type: CHANGE_CURRENT_QUOTATION,
        payload: quotation,
    };
};

export const getQuotationById = (params) => {
    return {
        type: GET_QUOTATION_BY_ID,
        payload: params,
    };
};

export const getQuotationByIdSuccess = (response) => {
    return {
        type: GET_QUOTATION_BY_ID_SUCCESS,
        payload: response.data,
    };
};

export const addProductsToCurrentQuotation = (params) => {
    return {
        type: ADD_PRODUCTS_CURRENT_QUOTATION,
        payload: params,
    };
};

export const addProductsToCurrentQuotationSuccess = (response) => {
    return {
        type: ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS,
        payload: response.data,
    };
};
