import {
    AUTOCOMPLETE_CATEGORIES_SUCCESS,
    AUTOCOMPLETE_CATEGORIES,
    CLEAR_CATEGORY_SUCCESS,
    CLEAR_CATEGORY,
    GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS,
    GET_CATEGORY_ALL_CHARACTERISTICS,
    GET_CATEGORY_BRANDS_SUCCESS,
    GET_CATEGORY_BRANDS,
    GET_CATEGORY_MODELS_SUCCESS,
    GET_CATEGORY_MODELS,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY,
    SAVE_CATEGORY_SUCCESS,
    SAVE_CATEGORY,
    SEARCH_CATEGORIES_SUCCESS,
    SEARCH_CATEGORIES,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY,
} from '../constants/ActionsTypes';

export const searchCategories = (params) => {
    return {
        type: SEARCH_CATEGORIES,
        payload: params,
    };
};

export const searchCategoriesSuccess = (data) => {
    return {
        type: SEARCH_CATEGORIES_SUCCESS,
        payload: data,
    };
};

export const autocompleteCategories = (params) => {
    return {
        type: AUTOCOMPLETE_CATEGORIES,
        payload: params,
    };
};

export const autocompleteCategoriesSuccess = (response) => {
    return {
        type: AUTOCOMPLETE_CATEGORIES_SUCCESS,
        payload: response,
    };
};

export const getCategory = (categoryId) => {
    return {
        type: GET_CATEGORY,
        payload: { categoryId },
    };
};

export const getCategorySuccess = (category) => {
    return {
        type: GET_CATEGORY_SUCCESS,
        payload: category,
    };
};

export const getCategoryBrands = () => {
    return {
        type: GET_CATEGORY_BRANDS,
    };
};

export const getCategoryBrandsSuccess = (brands) => {
    return {
        type: GET_CATEGORY_BRANDS_SUCCESS,
        payload: brands,
    };
};

export const getCategoryModels = () => {
    return {
        type: GET_CATEGORY_MODELS,
    };
};

export const getCategoryModelsSuccess = (models) => {
    return {
        type: GET_CATEGORY_MODELS_SUCCESS,
        payload: models,
    };
};

export const getCategoryCharacteristics = () => {
    return {
        type: GET_CATEGORY_ALL_CHARACTERISTICS,
    };
};

export const getCategoryCharacteristicsSuccess = (characteristics) => {
    return {
        type: GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS,
        payload: characteristics,
    };
};

export const saveCategory = (category) => {
    return {
        type: SAVE_CATEGORY,
        payload: category,
    };
};

export const saveCategorySuccess = () => {
    return {
        type: SAVE_CATEGORY_SUCCESS,
    };
};

export const updateCategory = (category) => {
    return {
        type: UPDATE_CATEGORY,
        payload: category,
    };
};

export const updateCategorySuccess = () => {
    return {
        type: UPDATE_CATEGORY_SUCCESS,
    };
};

export const clearCategorySuccess = () => {
    return {
        type: CLEAR_CATEGORY_SUCCESS,
    };
};

export const clearCategory = () => {
    return {
        type: CLEAR_CATEGORY,
    };
};
