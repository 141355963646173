import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { getCurrentAccountSuccess, creditLimitIncreaseSuccess } from '../actions/CurrentAccount'

import {
  GET_CURRENT_ACCOUNT,
  CREDIT_LIMIT_INCREASE_REQUEST,
  DOWNLOAD_ACCOUNTING_DOCUMENT,
  DOWNLOAD_COMPENSATED_MATCHES,
  GET_CREDIT_LIMIT_REQUESTS,
  GET_CREDIT_LIMIT_REQUESTS_SUCCESS,
} from '../constants/ActionsTypes'

import {
  creditLimitIncreaseRequestApi,
  getCreditLimitRequest,
  getCurrentAccountRequest,
  downloadAccountingDocumentRequest,
  downloadCompensatedMatches,
} from '../api/CurrentAccount'

const embeddedFields = (payload) => {
  const formData = new FormData()

  // Append each file to the formData with a common key like 'files'
  payload.files.forEach((f) => {
    formData.append(f.uid, f)
  })

  // Create a new object for the rest of the payload data
  const updatedPayload = {
    ...payload,
    files: payload.files.map((f) => ({
      uid: f.uid,
      name: f.name,
      size: f.size,
      type: f.type,
      fileId: f.fileId,
      category: f.category,
    })),
  }

  // Add the updated payload as JSON
  formData.append('all', JSON.stringify(updatedPayload))
  return formData
}

function* getCurrentAccount({ payload }) {
  const { clientCode, dateTo, totals, pageSize, page } = payload
  try {
    const response = yield call(
      getCurrentAccountRequest,
      clientCode,
      dateTo,
      totals,
      pageSize,
      page,
    )
    if (response) {
      yield put(getCurrentAccountSuccess(response))
    }
  } catch (error) {
    // handled in interceptor
  }
}

function* creditLimitIncreaseRequest({ payload }) {
  try {
    const formData = embeddedFields(payload)
    const response = yield call(creditLimitIncreaseRequestApi, formData)
    if (response && response.data) {
      yield put(creditLimitIncreaseSuccess())
    }
  } catch (error) {
    // handled in interceptor
  }
}

function* getCreditLimitRequests({ payload }) {
  console.log('params en el sagas: ', payload)
  try {
    const response = yield call(getCreditLimitRequest, payload)
    yield put({ type: GET_CREDIT_LIMIT_REQUESTS_SUCCESS, data: response })
  } catch (error) {
    // handled in interceptor
  }
}

function* downloadAccountingDocument({ payload }) {
  const { documentId, documentType, clientCode, documentDate } = payload
  try {
    const response = yield call(
      downloadAccountingDocumentRequest,
      documentId,
      documentType,
      clientCode,
      documentDate,
    )
  } catch (error) {
    // handled in interceptor
  }
}

function* downloadCompensatedMatchesRequest({ payload }) {
  const { clientCode, dateTo, totals, generatePDF } = payload
  try {
    const response = yield call(downloadCompensatedMatches, clientCode, dateTo, totals, generatePDF)
  } catch (error) {
    // handled in interceptor
  }
}

export function* getCurrentAccountSaga() {
  yield takeEvery(GET_CURRENT_ACCOUNT, getCurrentAccount)
}

export function* creditLimitIncreaseRequestSaga() {
  yield takeEvery(CREDIT_LIMIT_INCREASE_REQUEST, creditLimitIncreaseRequest)
}

export function* downloadAccountingDocumentSaga() {
  yield takeEvery(DOWNLOAD_ACCOUNTING_DOCUMENT, downloadAccountingDocument)
}

export function* downloadCompensatedMatchesSaga() {
  yield takeEvery(DOWNLOAD_COMPENSATED_MATCHES, downloadCompensatedMatchesRequest)
}

export function* getCreditLimitRequestsSaga() {
  yield takeEvery(GET_CREDIT_LIMIT_REQUESTS, getCreditLimitRequests)
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentAccountSaga),
    fork(getCreditLimitRequestsSaga),
    fork(creditLimitIncreaseRequestSaga),
    fork(downloadAccountingDocumentSaga),
    fork(downloadCompensatedMatchesSaga),
  ])
}
