import {
    ADD_UPDATE_SEGMENT,
    GET_SEGMENT,
    GET_SEGMENTS_ERROR,
    GET_SEGMENTS_SUCCESS,
    GET_SEGMENT_SUCCESS,
    GET_SEGMENT_ERROR,
    SEARCH_SEGMENTS,
    SEARCH_SEGMENTS_SUCCESS,
    AUTOCOMPLETE_SEGMENTS,
    AUTOCOMPLETE_SEGMENTS_SUCCESS,
    ADD_UPDATE_SEGMENT_SUCCESS,
    ADD_UPDATE_SEGMENT_ERROR,
    CLEAR_SEGMENTS
} from '../constants/ActionsTypes'

const initialState = {
    segment: undefined,
    segmentList: [],
    searchRequest: [],
    search: [],
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_UPDATE_SEGMENT:
            return { ...state, success: false }
        case ADD_UPDATE_SEGMENT_SUCCESS:
            return { ...state, success: true, segment: undefined }
        case ADD_UPDATE_SEGMENT_ERROR:
            return { ...state, success: false }
        case GET_SEGMENT:
            return { ...state, segment: null, success: false }
        case GET_SEGMENT_SUCCESS:
            return { ...state, segment: action.data }
        case GET_SEGMENT_ERROR:
            return { ...state, segment: undefined }
        case GET_SEGMENTS_SUCCESS:
            return { ...state, segmentList: action.data }
        case GET_SEGMENTS_ERROR:
            return { ...state, segmentList: [] }
        case SEARCH_SEGMENTS:
            return { ...state, searchRequest: action.payload, search:[] }
        case SEARCH_SEGMENTS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_SEGMENTS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_SEGMENTS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        case CLEAR_SEGMENTS:
            return { ...state, segmentList: [], search: []}
        default:
            return state
    }
}

export default rootReducer
