import {
    GET_CHARTS_INFO_ERROR,
    GET_CHARTS_INFO_SUCCESS,
    GET_CHARTS_INFO,
    GET_DETAIL_SELLE_INFO_ERROR,
    GET_DETAIL_SELLE_INFO_SUCCESS,
    GET_DETAIL_SELLE_INFO,
} from '../constants/ActionsTypes';

export const getChartsData = (from, to) => {
    return {
        type: GET_CHARTS_INFO,
        payload: { from, to },
    };
};

export const getChartsDataSuccess = (data) => {
    return {
        type: GET_CHARTS_INFO_SUCCESS,
        payload: data,
    };
};

export const getChartsDataError = () => {
    return {
        type: GET_CHARTS_INFO_ERROR,
    };
};

export const getDetailInfoBySeller = (sellerId, from, to, pagination) => {
    return {
        type: GET_DETAIL_SELLE_INFO,
        payload: { sellerId, from, to, pagination },
    };
};

export const getDetailInfoBySellerSuccess = (data) => {
    return {
        type: GET_DETAIL_SELLE_INFO_SUCCESS,
        payload: data,
    };
};

export const getDetailInfoBySellerError = () => {
    return {
        type: GET_DETAIL_SELLE_INFO_ERROR,
    };
};
