import { all, call, fork, put, takeEvery, throttle, select } from 'redux-saga/effects';

import {
    searchBanners,
    addBanner,
    getBanner,
    updateBanner,
    searchBannersBySegment
} from '../api/Banners';

import {SEARCH_BANNERS, AUTOCOMPLETE_BANNERS, ADD_BANNER, GET_BANNER, UPDATE_BANNER, SEARCH_BANNERS_BY_SEGMENT} from '../constants/ActionsTypes';
import { searchBannersSuccess, autocompleteBannersSuccess, addBannerSuccess, getBannerSuccess, updateBannerSuccess, searchBannersBySegmentSuccess } from '../actions';

export const getCommonState = (state) => state.common;

function* searchBannersRequest({ payload }) {

    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if(showRemove){
        payload.borrado = showRemove;
    }

    try {
        const banners = yield call(searchBanners, payload);
        yield put(searchBannersSuccess(banners));
    } catch (error) {
    }
}


function* autocompleteBannersRequest({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    const query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(showRemove){
        query.borrado = showRemove;
    }

    try {
        const banners = yield call(searchBanners, query);
        yield put(autocompleteBannersSuccess(banners));
    } catch (error) {
    }
}

function* addBannerRequest({ payload }) {
    try {
        const response = yield call(addBanner, payload);
        yield put(addBannerSuccess(response));
    } catch (error) {
    }
}

function* getBannerRequest({ payload }) {
    try {
        const {data} = yield call(getBanner, payload);
        yield put(getBannerSuccess(data));
    } catch (error) {
    }
}

function* updateBannerRequest({ payload }) {
    const {bannerId, params} = payload;
    try {
        const response = yield call(updateBanner, bannerId, params);
        yield put(updateBannerSuccess(response));
    } catch (error) {
    }
}

function* searchBannersBySegmentRequest({ payload }) {
    try {
        const banners = yield call(searchBannersBySegment, payload);
        yield put(searchBannersBySegmentSuccess(banners));
    } catch (error) {
    }
}


export function* searchBannersSaga() {
    yield takeEvery(SEARCH_BANNERS, searchBannersRequest);
}

export function* autocompleteBannersSaga() {
    yield throttle(1000, AUTOCOMPLETE_BANNERS, autocompleteBannersRequest);
}

export function* addBannerSaga() {
    yield takeEvery(ADD_BANNER, addBannerRequest);
}

export function* getBannerSaga() {
    yield takeEvery(GET_BANNER, getBannerRequest);
}

export function* updateBannerSaga() {
    yield takeEvery(UPDATE_BANNER, updateBannerRequest);
}

export function* searchBannersBySegmentSaga() {
    yield takeEvery(SEARCH_BANNERS_BY_SEGMENT, searchBannersBySegmentRequest);
}

export default function* rootSaga() {
    yield all([
        fork(searchBannersSaga),
        fork(autocompleteBannersSaga),
        fork(addBannerSaga),
        fork(getBannerSaga),
        fork(updateBannerSaga),
        fork(searchBannersBySegmentSaga),
    ]);
}
