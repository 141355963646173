import { CONFIGURATE_CLIENT_UPDATE, CONFIGURATE_CLIENT_UPDATE_SUCCESS, GET_CONFIG_CLIENT, GET_CONFIG_CLIENT_SUCCESS, GET_LOGO_IMAGE_SUCCESS } from "../constants/ActionsTypes";

const initialState = {
    success:null,
    logoClient:null
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case CONFIGURATE_CLIENT_UPDATE:
            return { ...state, success: null }
        case CONFIGURATE_CLIENT_UPDATE_SUCCESS:
            return { ...state, success: action.payload }
        case GET_CONFIG_CLIENT:
            return { ...state, configClient: null }
        case GET_CONFIG_CLIENT_SUCCESS:
            return { ...state, configClient: action.payload.response.data }
        case GET_LOGO_IMAGE_SUCCESS:
            return { ...state, logoClient:action.payload }            
        default:
            return state
    }
}

export default rootReducer
