import {
    ADD_UPDATE_CATALOG_ENTRY_SUCCESS,
    ADD_UPDATE_CATALOG_ENTRY,
    AUTOCOMPLETE_CATALOGS_ENTRIES_SUCCESS,
    AUTOCOMPLETE_CATALOGS_ENTRIES,
    GET_CATALOG_ENTRY,
    SEARCH_CATALOGS_ENTRIES_SUCCESS,
    SEARCH_CATALOGS_ENTRIES,
} from '../constants/ActionsTypes';

export const setSuccess = (data) => {
    return {
        type: ADD_UPDATE_CATALOG_ENTRY,
        payload: data,
    };
};

export const addUpdateCatalog = (params) => {
    return {
        type: ADD_UPDATE_CATALOG_ENTRY,
        payload: { params },
    };
};

export const getCatalogEntry = (catalogEntryId) => {
    return {
        type: GET_CATALOG_ENTRY,
        payload: { catalogEntryId },
    };
};

export const searchCatalogEntries = (params) => {
    return {
        type: SEARCH_CATALOGS_ENTRIES,
        payload: params,
    };
};

export const searchCatalogEntriesSuccess = (data) => {
    return {
        type: SEARCH_CATALOGS_ENTRIES_SUCCESS,
        payload: data,
    };
};

export const addCatalogEntrySuccess = (data) => {
    return {
        type: ADD_UPDATE_CATALOG_ENTRY_SUCCESS,
        payload: data,
    };
};

export const autocompleteCatalogEntries = (params) => {
    return {
        type: AUTOCOMPLETE_CATALOGS_ENTRIES,
        payload: params,
    };
};

export const autocompleteCatalogEntriesSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_CATALOGS_ENTRIES_SUCCESS,
        payload: data,
    };
};
