import {
    AUTOCOMPLETE_MAIN_GROUPS_SUCCESS,
    AUTOCOMPLETE_MAIN_GROUPS,
    GET_MAIN_GROUP,
    SEARCH_MAIN_GROUPS_SUCCESS,
    SEARCH_MAIN_GROUPS,
} from '../constants/ActionsTypes';

export const getMainGroup = (mainGroupId, token) => {
    return {
        type: GET_MAIN_GROUP,
        payload: { mainGroupId, token },
    };
};

export const searchMainGroups = (params) => {
    return {
        type: SEARCH_MAIN_GROUPS,
        payload: params,
    };
};

export const searchMainGroupsSuccess = (data) => {
    return {
        type: SEARCH_MAIN_GROUPS_SUCCESS,
        payload: data,
    };
};

export const autocompleteMainGroups = (params) => {
    return {
        type: AUTOCOMPLETE_MAIN_GROUPS,
        payload: params,
    };
};

export const autocompleteMainGroupsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_MAIN_GROUPS_SUCCESS,
        payload: data,
    };
};
