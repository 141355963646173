import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    GET_ACCOUNT_FORM,
    SEARCH_ACCOUNT_FORM,
    CREATE_ACCOUNT_FORM,
    EDIT_ACCOUNT_FORM,
    DELETE_ACCOUNT_FORM,
    COMPLETE_ACCOUNT_FORM,
    GET_WORKS,
    GET_SEGMENTS_WITH_BRANDS,
    GET_LINK_FOR_DETAIL
} from '../constants/ActionsTypes'

import {
    getAccountFormError,
    getAccountFormSuccess,
    searchAccountFormError,
    searchAccountFormSuccess,
    createAccountFormError,
    createAccountFormSuccess,
    editAccountFormError,
    editAccountFormSuccess,
    deleteAccountFormError,
    deleteAccountFormSuccess,
    completeAccountFormError,
    completeAccountFormSuccess,
    getWorksError,
    getWorksSuccess,
    getSegmentsWithBrandsError,
    getSegmentsWithBrandsSuccess,
    getLinkForDetailError,
    getLinkForDetailSuccess,
    clearAccountFormState,
} from '../actions';

import {
    getAccountForm,
    searchAccountForm,
    createAccountForm,
    editAccountForm,
    deleteAccountForm,
    completeAccountForm,
    getWorks,
    getSegmentsWithBrands,
    getLink
} from '../api/AccountForm';

const embeddedFields = files => {
    const formData = new FormData();
    files.files.forEach(f => {
        formData.append(f.uid, f);
    });
    files.files = files.files.map(f => ({
        uid: f.uid,
        name: f.name,
        size: f.size,
        type: f.type,
        fileId: f.fileId,
        category: f.category
    }));
    formData.append("all", JSON.stringify(files));
    return formData;
}

function* getAccountFormRequest({ payload }) {
    try {
        const {data} = yield call(getAccountForm, payload);
        yield put(getAccountFormSuccess(data));
    } catch (error) {
        yield put(getAccountFormError(error));
    }
}

function* searchAccountFormRequest({ payload }) {
    try {
        const data = yield call(searchAccountForm, payload);
        yield put(searchAccountFormSuccess(data));
    } catch (error) {
        yield put(searchAccountFormError(error));
    }
}

function* createAccountFormRequest({ payload }) {
    try {
        const formData = embeddedFields(payload);
        const {data} = yield call(createAccountForm, formData);
        yield put(createAccountFormSuccess(data));
        yield put(clearAccountFormState());
    } catch (error) {
        yield put(createAccountFormError(error));
    }
}

function* editAccountFormRequest({ payload }) {
    try {
        const formData = embeddedFields(payload);
        const {data} = yield call(editAccountForm, formData);
        yield put(editAccountFormSuccess(data));
        yield put(clearAccountFormState());
    } catch (error) {
        yield put(editAccountFormError(error));
    }
}

function* deleteAccountFormRequest({ payload }) {
    try {
        yield call(deleteAccountForm, payload);
        yield put(deleteAccountFormSuccess());
        yield put(clearAccountFormState());
    } catch (error) {
        yield put(deleteAccountFormError(error));
    }
}

function* completeAccountFormRequest({ payload }) {
    try {
        yield call(completeAccountForm, payload);
        yield put(completeAccountFormSuccess());
        yield put(clearAccountFormState());
    } catch (error) {
        yield put(completeAccountFormError(error));
    }
}

function* getWorksRequest() {
    try {
        const {data} = yield call(getWorks);
        yield put(getWorksSuccess(data));
    } catch (error) {
        yield put(getWorksError(error));
    }
}

function* getSegmentsWithBrandsRequest() {
    try {
        const {data} = yield call(getSegmentsWithBrands);
        yield put(getSegmentsWithBrandsSuccess(data));
    } catch (error) {
        yield put(getSegmentsWithBrandsError(error));
    }
}

function* getLinkForDetailRequest({ payload }) {
    try {
        const {data} = yield call(getLink, payload);
        yield put(getLinkForDetailSuccess(data));
    } catch (error) {
        yield put(getLinkForDetailError(error));
    }
}

export function* getAccountFormSaga() {
    yield takeLatest(GET_ACCOUNT_FORM, getAccountFormRequest);
}

export function* searchAccountFormSaga() {
    yield takeLatest(SEARCH_ACCOUNT_FORM, searchAccountFormRequest);
}

export function* createAccountFormSaga() {
    yield takeEvery(CREATE_ACCOUNT_FORM, createAccountFormRequest);
}

export function* editAccountFormSaga() {
    yield takeEvery(EDIT_ACCOUNT_FORM, editAccountFormRequest);
}

export function* deleteAccountFormSaga() {
    yield takeEvery(DELETE_ACCOUNT_FORM, deleteAccountFormRequest);
}

export function* completeAccountFormSaga() {
    yield takeLatest(COMPLETE_ACCOUNT_FORM, completeAccountFormRequest);
}

export function* getWorksSaga() {
    yield takeEvery(GET_WORKS, getWorksRequest);
}

export function* getSegmentsWithBrandsSaga() {
    yield takeEvery(GET_SEGMENTS_WITH_BRANDS, getSegmentsWithBrandsRequest);
}

export function* getLinkForDetailSaga() {
    yield takeEvery(GET_LINK_FOR_DETAIL, getLinkForDetailRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getAccountFormSaga),
        fork(searchAccountFormSaga),
        fork(createAccountFormSaga),
        fork(editAccountFormSaga),
        fork(deleteAccountFormSaga),
        fork(completeAccountFormSaga),
        fork(getWorksSaga),
        fork(getSegmentsWithBrandsSaga),
        fork(getLinkForDetailSaga),
    ]);
}
