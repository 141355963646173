import {
  CREDIT_LIMIT_INCREASE_REQUEST,
  CREDIT_LIMIT_INCREASE_SUCCESS,
  GET_CREDIT_LIMIT_REQUESTS,
  GET_CREDIT_LIMIT_REQUESTS_SUCCESS,
  DOWNLOAD_ACCOUNTING_DOCUMENT,
  DOWNLOAD_COMPENSATED_MATCHES,
  GET_CURRENT_ACCOUNT_SUCCESS,
  GET_CURRENT_ACCOUNT,
} from '../constants/ActionsTypes'

export const getCurrentAccount = (clientCode, dateTo, totals, pageSize, page) => {
  return {
    type: GET_CURRENT_ACCOUNT,
    payload: { clientCode, dateTo, totals, pageSize, page },
  }
}

export const getCurrentAccountSuccess = (result) => {
  return {
    type: GET_CURRENT_ACCOUNT_SUCCESS,
    data: result,
  }
}

export const downloadAccountingDocument = (documentId, documentType, clientCode, documentDate) => {
  return {
    type: DOWNLOAD_ACCOUNTING_DOCUMENT,
    payload: { documentId, documentType, clientCode, documentDate },
  }
}

export const downloadCompensatedMatches = (clientCode, dateTo, totals, generatePDF) => {
  return {
    type: DOWNLOAD_COMPENSATED_MATCHES,
    payload: { clientCode, dateTo, totals, generatePDF },
  }
}

export const creditLimitIncreaseRequest = (params) => {
  return {
    type: CREDIT_LIMIT_INCREASE_REQUEST,
    payload: params,
  }
}

export const creditLimitIncreaseSuccess = () => {
  return {
    type: CREDIT_LIMIT_INCREASE_SUCCESS,
  }
}

export const getCreditLimitRequests = (params) => {
  return {
    type: GET_CREDIT_LIMIT_REQUESTS,
    payload: params,
  }
}

export const getCreditLimitRequestsSuccess = (data) => {
  return {
    type: GET_CREDIT_LIMIT_REQUESTS_SUCCESS,
    payload: data,
  }
}
