import { all, call, fork, put, takeEvery, throttle, select } from 'redux-saga/effects';

import {
    SEARCH_SELLERS,
    AUTOCOMPLETE_SELLERS,
    SAVE_SELLER
} from '../constants/ActionsTypes';

import {
    searchSellers,
    saveSeller
} from '../api/Sellers'

import {
    searchSellersSuccess,
    autocompleteSellersSuccess,
    saveSellerSuccess,
    saveSellerError
} from '../actions/Sellers';

export const getCommonState = (state) => state.common

function* searchSellersRequest({payload}) {
    const params = yield select(getCommonState)
    const { AdminActionBar } = params

    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove
    }

    try {
        const sellers = yield call(searchSellers, payload);
        yield put(searchSellersSuccess(sellers));
    } catch (error) {
    }
}

function* autocompleteSellersRequest({payload}) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;

    const query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(AdminActionBar.showRemove){
        query.borrado = AdminActionBar.showRemove;
    }

    try {
        const clients = yield call(searchSellers, query);
        yield put(autocompleteSellersSuccess(clients));
    } catch (error) {
    }
}

function* saveSellerRequest({payload}) {
    try {
        yield call(saveSeller, payload);
        yield put(saveSellerSuccess());
    } catch (error) {
        yield put(saveSellerError(error));
    }
}

export function* searchSellersSaga() {
    yield takeEvery(SEARCH_SELLERS, searchSellersRequest);
}

export function* autocompleteSellersSaga() {
    yield throttle(1000, AUTOCOMPLETE_SELLERS, autocompleteSellersRequest);
}

export function* saveSellerSaga() {
    yield takeEvery(SAVE_SELLER, saveSellerRequest);
}

export default function* rootSaga() {
    yield all([
        fork(searchSellersSaga),
        fork(autocompleteSellersSaga),
        fork(saveSellerSaga)
    ]);
}
