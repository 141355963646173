import Axios from 'axios';

export const searchClients = async (searchParams) => {
    const response = await Axios.post('/clientes/search', searchParams);
    return response.data;
};

export const searchMyClients = async (searchParams) => {
    const response = await Axios.post('/clientes/mine', searchParams);
    return response.data;
};
