import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    testConnection
} from '../api/Connectivity';

import { CHECK_CONNECTION } from '../constants/ActionsTypes';
import { online, offline } from '../actions';

export const getCommonState = (state) => state.common;

function* checkConnectionRequest() {
    try {
        const response = yield call(testConnection);
        response ? yield put(online()) : yield put(offline());
    } catch (error) {
        yield put(offline());
    }
}

export function* checkConnectionSaga() {
    yield takeEvery(CHECK_CONNECTION, checkConnectionRequest);
}

export default function* rootSaga() {
    yield all([
        fork(checkConnectionSaga)
    ]);
}