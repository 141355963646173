import Axios from 'axios';

const BASE_URL_END_POINT = '/Usuarios';

export const getUser = async (userId) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}/getUserById?id=${userId}`);
    return response.data;
};

export const updateUser = async (values) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/updateUserById`, {
        values: values,
    });
    return response;
};

export const getClient = async (clientId) => {
    const response = await Axios.get(
        '/Clientes/' +
            clientId +
            '?filter={"include": [{"relation": "vendedor", "scope": { "include": { "relation": "sucursal"}}}, {"relation": "transporte"}]}'
    );
    return response;
};

export const createUser = async (values) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/createNewUsuario`, {
        values: values,
    });
    return response;
};

export const deleteUser = async (userId) => {
    const response = await Axios.delete(`${BASE_URL_END_POINT}/${userId}`);
    return response;
};

export const getUsers = async (token) => {
    const response = await Axios.get(`${BASE_URL_END_POINT}`, { token });
    return response;
};

export const getClients = async (searchText) => {
    const params = {
        filtro: searchText,
    };
    const response = await Axios.post('/Clientes/search', params);
    return response;
};

export const getSellers = async (searchText) => {
    const params = {
        filtro: searchText,
        telemarketer: false,
    };
    const response = await Axios.post('/Vendedores/search', params);
    return response;
};

export const getSellersTelemarketer = async (searchText) => {
    const params = {
        filtro: searchText,
        telemarketer: true,
    };
    const response = await Axios.post('/Vendedores/search', params);
    return response;
};

export const getSeller = async (sellerId, token) => {
    const response = await Axios.get(
        '/Vendedores/' + sellerId + '?filter={"include": {"relation": "sucursal"}}',
        {
            params: {
                access_token: token,
            },
        }
    );
    return response;
};

export const searchUsers = async (searchParams) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/search`, searchParams);
    return response;
};

export const getBrandsByUser = async (searchParams) => {
    const response = await Axios.post('/usuarios/getBrandsByUser', searchParams);
    return response.data;
};

export const changePassword = async (params) => {
    const response = await Axios.post('/usuarios/updatePassword', params);
    return response.data;
};

export const updateEmail = async (params) => {
    const response = await Axios.post('/usuarios/updateEmailByUser', params);
    return response.data;
};

export const sendPasswordRequestEmail = async (params) => {
    const response = await Axios.post('/usuarios/reset', params);
    return response.data;
};

export const setPassword = async (params, token) => {
    const response = await Axios.post(`/usuarios/setPassword?access_token=${token}`, params);
    return response.data;
};

export const verifyToken = async (token) => {
    const response = await Axios.post(`/usuarios/isValidToken?access_token=${token}`);
    return response.data;
};
