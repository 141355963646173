import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_BRANCHES,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_ERROR,
    GET_BRANCH,
    GET_BRANCH_SUCCESS,
    GET_BRANCH_ERROR,
    SEARCH_BRANCHES,
    AUTOCOMPLETE_BRANCHES,
    EDIT_CONTACT_LINES
} from '../constants/ActionsTypes';

import {
    getBranches,
    getBranch,
    searchBranches,
    editContactLines
} from '../api/Branches';


import {
    searchBranchesSuccess,
    autocompleteBranchesSuccess,
    editContactLinesSuccess
} from '../actions/Branches';

export const getCommonState = (state) => state.common;

function* callGetBranches() {
    try {
        const response = yield call(getBranches);
        yield put({ type: GET_BRANCHES_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_BRANCHES_ERROR, error });
    }
}

function* callGetBranch({ payload }) {
    const { branchId} = payload;
    try {
        const response = yield call(getBranch, branchId);
        yield put({ type: GET_BRANCH_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_BRANCH_ERROR, error });
    }
}

function* searchBranchesRequest({payload}) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;
    
    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove;
    }

    try {
      const branches = yield call(searchBranches, payload);
      yield put(searchBranchesSuccess(branches));
    } catch (error) {
    }
}

function* editContactLinesRequest({ payload }) {
    try {
        const {data} = yield call(editContactLines, payload);
        yield put(editContactLinesSuccess(data));
    } catch (error) {
    }
}

function* autocompleteBranchesRequest({payload}) {
    const params = yield select(getCommonState)
    const { AdminActionBar } = params ;
    
    const query = {
      'filtro': payload,
      'page': 1,
      'pageSize': 10
    }
    
    if(AdminActionBar.showRemove){
        query.borrado = AdminActionBar.showRemove;
    } 
  
    try {
      const branches = yield call(searchBranches, query);
      yield put(autocompleteBranchesSuccess(branches));
    } catch (error) {
    }
}

export function* getBranchesSaga() {
    yield takeLatest(GET_BRANCHES, callGetBranches);
}

export function* getBranchSaga() {
    yield takeLatest(GET_BRANCH, callGetBranch);
}

export function* searchBranchesSaga() {
    yield takeEvery(SEARCH_BRANCHES, searchBranchesRequest);
}

export function* autocompleteBranchesSaga() {
    yield throttle(1000, AUTOCOMPLETE_BRANCHES, autocompleteBranchesRequest);
}

export function* editContactLinesSaga() {
    yield takeLatest(EDIT_CONTACT_LINES, editContactLinesRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getBranchesSaga),
        fork(getBranchSaga),
        fork(searchBranchesSaga),
        fork(autocompleteBranchesSaga),
        fork(editContactLinesSaga),
    ]);
}