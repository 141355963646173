import {
    AUTOCOMPLETE_BRANCHES_SUCCESS,
    AUTOCOMPLETE_BRANCHES,
    EDIT_CONTACT_LINES,
    EDIT_CONTACT_LINES_SUCCESS,
    GET_BRANCH,
    GET_BRANCHES,
    SEARCH_BRANCHES_SUCCESS,
    SEARCH_BRANCHES,
} from '../constants/ActionsTypes';

export const getBranches = () => {
    return {
        type: GET_BRANCHES,
    };
};

export const getBranch = (branchId) => {
    return {
        type: GET_BRANCH,
        payload: { branchId },
    };
};

export const searchBranches = (params) => {
    return {
        type: SEARCH_BRANCHES,
        payload: params,
    };
};

export const searchBranchesSuccess = (data) => {
    return {
        type: SEARCH_BRANCHES_SUCCESS,
        payload: data,
    };
};

export const autocompleteBranches = (params) => {
    return {
        type: AUTOCOMPLETE_BRANCHES,
        payload: params,
    };
};

export const autocompleteBranchesSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_BRANCHES_SUCCESS,
        payload: data,
    };
};



export const editContactLines = params => {
    return {
        type: EDIT_CONTACT_LINES,
        payload: params
    };
};

export const editContactLinesSuccess = data => {
    return {
        type: EDIT_CONTACT_LINES_SUCCESS,
        payload: data
    }
};
