import {
    GET_TRANSPORT,  
    GET_TRANSPORTS_SUCCESS,
    GET_TRANSPORTS_ERROR,
    GET_TRANSPORT_SUCCESS,
    GET_TRANSPORT_ERROR,
    SEARCH_TRANSPORTS,
    SEARCH_TRANSPORTS_SUCCESS,
    AUTOCOMPLETE_TRANSPORTS,
    AUTOCOMPLETE_TRANSPORTS_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
    transportList: [],
    transport: undefined,
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
      case GET_TRANSPORT:
            return { ...state, transport: null }
        case GET_TRANSPORT_SUCCESS:
            return { ...state, transport: action.data }
        case GET_TRANSPORT_ERROR:
            return { ...state, transport: action }
        case GET_TRANSPORTS_SUCCESS:
            return { ...state, transportList: action.data }
        case GET_TRANSPORTS_ERROR:
            return { ...state, transports: action }
        case SEARCH_TRANSPORTS:
            return { ...state, searchRequest: action.payload }
        case SEARCH_TRANSPORTS_SUCCESS:
            return { ...state, search: action.payload.response }
        case AUTOCOMPLETE_TRANSPORTS:
            return { ...state, autocompleteData: null }
        case AUTOCOMPLETE_TRANSPORTS_SUCCESS:
            return { ...state, autocompleteData: action.payload }
        default:
            return state
    }
}

export default rootReducer
