import { buffers } from 'redux-saga'
import { all, call, fork, put, takeLatest, throttle, select, take, actionChannel } from 'redux-saga/effects';

import {
  ORDER_REQUEST,
  GET_CURRENT_ORDER,
  GET_CURRENT_ORDER_SUCCESS,
  GET_CURRENT_ORDER_ERROR,
  ADD_ITEM_TO_ORDER,
  ADD_ITEM_TO_ORDER_SUCCESS,
  ADD_ITEM_TO_ORDER_ERROR,
  REMOVE_ITEM_FROM_ORDER,
  REMOVE_ITEM_FROM_ORDER_SUCCESS,
  REMOVE_ITEM_FROM_ORDER_ERROR,
  CHANGE_ITEM_QUANTITY_FROM_ORDER,
  CHANGE_ITEM_QUANTITY_FROM_ORDER_SUCCESS,
  CHANGE_ITEM_QUANTITY_FROM_ORDER_ERROR,
  SET_DATA_TO_ORDER,
  SET_DATA_TO_ORDER_SUCCESS,
  SET_DATA_TO_ORDER_ERROR,
  SAVE_ORDER,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_ERROR,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  CONFIRM_ORDER,
  CONFIRM_ORDER_AS_ADMIN,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_ORDER_ERROR,
  ADD_CLIENT_TO_ORDER,
  ADD_CLIENT_TO_ORDER_SUCCESS,
  ADD_CLIENT_TO_ORDER_ERROR,
  ADD_ITEM_TO_ORDER_FROM_CODE,
  ADD_ITEM_TO_ORDER_FROM_CODE_SUCCESS,
  ADD_ITEM_TO_ORDER_FROM_CODE_ERROR,
  ADD_TRANSPORT_TO_ORDER,
  ADD_TRANSPORT_TO_ORDER_SUCCESS,
  ADD_TRANSPORT_TO_ORDER_ERROR,
  APPLY_DISCOUNTS_TO_PRODUCTS,
  APPLY_DISCOUNTS_TO_PRODUCTS_LOADING,
  APPLY_DISCOUNTS_TO_PRODUCTS_SUCCESS,
  APPLY_DISCOUNTS_TO_PRODUCTS_ERROR,
  GET_ORDER_BY_USER,
  GET_ORDERS_BY_USER_PENDING,
  GET_ORDERS_BY_DATE,
  GET_ORDERS_BY_DATE_PENDING,
  GET_ORDER_BY_ID,
  ADD_PRODUCTS_TO_CURRENT,
  CHANGE_CURRENT_ORDER,
  GET_REFUND_CODES,
  GET_INVOICES, GET_INVOICE, CREATE_CREDIT_NOTE, GET_CN_TYPES
} from '../constants/ActionsTypes';

import {
  getCurrentOrder,
  addItemToOrder,
  removeItemFromOrder,
  changeItemQuantityFromOrder,
  setDataToOrder,
  saveOrder,
  cancelOrder,
  confirmOrder,
  addClientToOrder,
  addItemToOrderFromCode,
  addTransportToOrder,
  applyDiscountsToProducts,
  getOrdersByUser,
  getOrdersByDate,
  getOrderById,
  addProductsToCurrent,
  changeCurrentOrder,
  getRefundCodes,
  getInvoices, getInvoice, createCN, getCNTypes
} from '../api/Orders';

import {
  getOrdersByUserSuccess,
  getOrdersByDateSuccess,
  getOrderByIdSuccess,
  addProductsToCurrentSuccess,
  changeCurrentOrderSuccess,
  getRefundCodesSuccess,
  getInvoicesSuccess, getInvoiceSuccess, createCNSuccess, getCNTypesSuccess
} from '../actions';

import {
  updateElectronOrder,
  confirmElectronOrder
} from '../electron/tools'

//Selectors
const getOfflineStatus = (state) => state.catalog.offline
const getCurrentOrderFromState = (state) => state.orders.current

function* getCurrentOrderRequest() {
  try {
    // Get current order id from LS
    const currentOrderId = localStorage.getItem('currentOrderId')
    const response = yield call(getCurrentOrder, currentOrderId);
    const { data } = response.data;
    yield put({ type: GET_CURRENT_ORDER_SUCCESS, data });

    // Update offline order
    updateElectronOrder(data, yield select(getOfflineStatus));

    // Save order id to LS
    localStorage.setItem('currentOrderId', data.id)

  } catch (error) {
    yield put({ type: GET_CURRENT_ORDER_ERROR, error });
  }
}

//Cuando el user es admin no hay order en el state, por lo que es necesario pasar la orden seleccionada
function* getCurrentOrderFromStateOrRequest() {
  let currentOrder = yield select(getCurrentOrderFromState)
  if (!currentOrder) {
    yield getCurrentOrderRequest();
    currentOrder = yield select(getCurrentOrderFromState)
  }
  return currentOrder;
}

function* addItemToOrderRequest({ payload }) {
  const { item } = payload;
  try {
    // Get current order
    const currentOrder = yield call(getCurrentOrderFromStateOrRequest)
    const response = yield call(addItemToOrder, currentOrder.id, { ...item });
    const { data } = response.data;
    yield put({ type: ADD_ITEM_TO_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_ITEM_TO_ORDER_ERROR, error });
  }
}

function* removeItemFromOrderRequest({ payload }) {
  const { orderId, itemCode } = payload;
  try {
    const response = yield call(removeItemFromOrder, orderId, itemCode);
    const { data } = response.data;
    yield put({ type: REMOVE_ITEM_FROM_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: REMOVE_ITEM_FROM_ORDER_ERROR, error });
  }
}

function* changeItemQuantityFromOrderRequest({ payload }) {
  const { orderId, itemCode, quantity } = payload;
  try {
    const response = yield call(changeItemQuantityFromOrder, orderId, itemCode, quantity);
    const { data } = response.data;
    yield put({ type: CHANGE_ITEM_QUANTITY_FROM_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: CHANGE_ITEM_QUANTITY_FROM_ORDER_ERROR, error });
  }
}

function* setDataToOrderRequest({ payload }) {
  const { orderId, type, value } = payload;
  try {
    const response = yield call(setDataToOrder, orderId, type, value);
    const { data } = response.data;
    yield put({ type: SET_DATA_TO_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: SET_DATA_TO_ORDER_ERROR, error });
  }
}

function* saveOrderRequest({ payload }) {
  const { orderId } = payload;
  try {
    const response = yield call(saveOrder, orderId);
    const { data } = response.data;
    yield put({ type: SAVE_ORDER_SUCCESS });
    updateElectronOrder(data, yield select(getOfflineStatus));

    // Remove current order id from LS
    localStorage.removeItem('currentOrderId')
  } catch (error) {
    yield put({ type: SAVE_ORDER_ERROR, error });
  }
}

function* cancelOrderRequest({ payload }) {
  const { orderId } = payload;
  try {
    const response = yield call(cancelOrder, orderId);
    const { data } = response.data;
    yield put({ type: CANCEL_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));

    // Remove current order id from LS
    localStorage.removeItem('currentOrderId')

    //Get current order
    yield put({ type: GET_CURRENT_ORDER });

  } catch (error) {
    yield put({ type: CANCEL_ORDER_ERROR, error });
  }
}

function* confirmOrderAsAdminRequest({ payload }) {
  const { orderId, appointment } = payload;
  try {
    const response = yield call(confirmOrder, orderId, appointment);
    const { data } = response;
    if (data && data.success) {
      yield put({ type: CONFIRM_ORDER_SUCCESS, data });
      confirmElectronOrder(data.idPedido, yield select(getOfflineStatus));

    } else {
      yield put({ type: CONFIRM_ORDER_ERROR, data });
    }
  } catch (error) {
    yield put({ type: CONFIRM_ORDER_ERROR });
  }
}

function* confirmOrderRequest({ payload }) {
  const { orderId, appointment } = payload;
  try {
    const response = yield call(confirmOrder, orderId, appointment);
    const { data } = response;
    if (data && data.success) {
      yield put({ type: CONFIRM_ORDER_SUCCESS, data });
      confirmElectronOrder(data.idPedido, yield select(getOfflineStatus));

      // Remove current order id from LS
      localStorage.removeItem('currentOrderId')

    } else {
      yield put({ type: CONFIRM_ORDER_ERROR, data });
    }
  } catch (error) {
    yield put({ type: CONFIRM_ORDER_ERROR });
  }
}

function* addClientToOrderRequest({ payload }) {

  const { orderId, clientId } = payload;
  try {
    const response = yield call(addClientToOrder, orderId, clientId);
    const { data } = response.data;
    yield put({ type: ADD_CLIENT_TO_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_CLIENT_TO_ORDER_ERROR, error });
  }
}

function* addItemToOrderFromCodeRequest({ payload }) {
  const { orderId, code, quantity } = payload;
  try {
    const response = yield call(addItemToOrderFromCode, orderId, {code, quantity});
    const { data } = response.data;
    yield put({ type: ADD_ITEM_TO_ORDER_FROM_CODE_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_ITEM_TO_ORDER_FROM_CODE_ERROR, error });
  }
}

function* addTransportToOrderRequest({ payload }) {
  const { orderId, transportId } = payload;
  try {
    const response = yield call(addTransportToOrder, orderId, transportId);
    const { data } = response.data;
    yield put({ type: ADD_TRANSPORT_TO_ORDER_SUCCESS, data });
    updateElectronOrder(data, yield select(getOfflineStatus));
  } catch (error) {
    yield put({ type: ADD_TRANSPORT_TO_ORDER_ERROR, error });
  }
}

function* applyDiscountsToProductsRequest({ payload }) {
  const { clientCode, products } = payload;

    try {
      yield put({ type: APPLY_DISCOUNTS_TO_PRODUCTS_LOADING });

      const response = yield call(applyDiscountsToProducts, clientCode, products);
      const { data } = response;

      if (data && data.response && data.response.productos && data.response.productos.length > 0) {
        yield put({ type: APPLY_DISCOUNTS_TO_PRODUCTS_SUCCESS, payload: data.response.productos[0] });
      }

    } catch (error) {
      yield put({ type: APPLY_DISCOUNTS_TO_PRODUCTS_ERROR, error });
    }
}

function* getOrdersByUserRequest({ payload }) {
  try {
      yield put({type: GET_ORDERS_BY_USER_PENDING});

      const orders = yield call(getOrdersByUser, payload);
      yield put(getOrdersByUserSuccess(orders));
  } catch (error) {
  }
}

function* getOrdersByDateRequest({ payload }) {
  try{
    yield put({type: GET_ORDERS_BY_DATE_PENDING});

    const orders = yield call(getOrdersByDate, payload);
    yield put(getOrdersByDateSuccess(orders));
  } catch (error) {
  }
}

function* getOrderByIdRequest({ payload }) {

  try {
      const order = yield call(getOrderById, payload);
      yield put(getOrderByIdSuccess(order));
  } catch (error) {
  }
}

function* addProductsToCurrentRequest({ payload }) {
  try {
      const current = yield call(addProductsToCurrent, payload.orderId);
      yield put(addProductsToCurrentSuccess(current));
  } catch (error) {
    console.log(error);

  }
}

function* changeCurrentOrderRequest({ payload }) {
  try {
      const current = yield call(changeCurrentOrder, payload.orderId);
      yield put(changeCurrentOrderSuccess(current));
  } catch (error) {
    console.log(error);

  }
}

function* getRefundCodesRequest() {
  try {
    const {data} = yield call(getRefundCodes);
    yield put(getRefundCodesSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* getCNTypesRequest() {
  try {
    const {data} = yield call(getCNTypes);
    yield put(getCNTypesSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* getInvoicesRequest({payload}) {
  try {
    const {data} = yield call(getInvoices, payload);
    yield put(getInvoicesSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* getInvoiceRequest({payload}) {
  try {
    const {data} = yield call(getInvoice, payload);
    yield put(getInvoiceSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* createCNRequest({payload}) {
  try {
    const {data} = yield call(createCN, payload);
    yield put(createCNSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* getCurrentOrderSaga() {
  yield takeLatest(GET_CURRENT_ORDER, getCurrentOrderRequest);
}

export function* changeItemQuantityFromOrderSaga() {
  // Channel as queue, using buffers.sliding to take latest request
  const requestChan = yield actionChannel(CHANGE_ITEM_QUANTITY_FROM_ORDER, buffers.sliding(1));

  while (true) {
    const action = yield take(requestChan)
    yield call(changeItemQuantityFromOrderRequest, action);
  }
}

export function* saveOrderSaga() {
  yield takeLatest(SAVE_ORDER, saveOrderRequest);
}

export function* cancelOrderSaga() {
  yield takeLatest(CANCEL_ORDER, cancelOrderRequest);
}

export function* addClientToOrderSaga() {
  yield takeLatest(ADD_CLIENT_TO_ORDER, addClientToOrderRequest);
}

export function* addItemToOrderFromCodeSaga() {
  // Channel as queue
  const requestChan = yield actionChannel(ADD_ITEM_TO_ORDER_FROM_CODE)
  while (true) {
    const action = yield take(requestChan)
    yield call(addItemToOrderFromCodeRequest, action)
  }
}

export function* addTransportToOrderSaga() {
  yield takeLatest(ADD_TRANSPORT_TO_ORDER, addTransportToOrderRequest);
}

export function* applyDiscountsToProductsSaga() {
  yield takeLatest(APPLY_DISCOUNTS_TO_PRODUCTS, applyDiscountsToProductsRequest);
}

export function* getOrdersByUserSaga() {
  yield takeLatest(GET_ORDER_BY_USER, getOrdersByUserRequest);
}

export function* getOrdersByDateSaga() {
  yield takeLatest(GET_ORDERS_BY_DATE, getOrdersByDateRequest);
}

export function* getOrderByIdSaga() {
  yield takeLatest(GET_ORDER_BY_ID, getOrderByIdRequest);
}

export function* addProductsToCurrentSaga() {
  yield takeLatest(ADD_PRODUCTS_TO_CURRENT, addProductsToCurrentRequest);
}

export function* changeCurrentOrderSaga() {
  yield takeLatest( CHANGE_CURRENT_ORDER , changeCurrentOrderRequest);
}

export function* getRefundCodesSaga() {
  yield takeLatest( GET_REFUND_CODES , getRefundCodesRequest);
}

export function* getCNTypesSaga() {
  yield takeLatest( GET_CN_TYPES , getCNTypesRequest);
}

export function* getInvoicesSaga() {
  yield takeLatest( GET_INVOICES , getInvoicesRequest);
}

export function* getInvoiceSaga() {
  yield takeLatest( GET_INVOICE , getInvoiceRequest);
}

export function* createCNSaga() {
  yield takeLatest( CREATE_CREDIT_NOTE , createCNRequest);
}

export function* handelOrderRequestsSaga() {
  const requestChan = yield actionChannel(ORDER_REQUEST);
  while (true) {
    const action = yield take(requestChan);
    switch (action.meta) {
      case ADD_ITEM_TO_ORDER: {
        yield call(addItemToOrderRequest, action);
        break
      }
      case REMOVE_ITEM_FROM_ORDER: {
        yield call(removeItemFromOrderRequest, action);
        break;
      }
      case CONFIRM_ORDER: {
        yield call(confirmOrderRequest, action);
        break;
      }
      case CONFIRM_ORDER_AS_ADMIN: {
        yield call(confirmOrderAsAdminRequest, action);
        break;
      }
      case SET_DATA_TO_ORDER: {
        yield call(setDataToOrderRequest, action);
        break;
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentOrderSaga),
    fork(changeItemQuantityFromOrderSaga),
    fork(saveOrderSaga),
    fork(cancelOrderSaga),
    fork(addClientToOrderSaga),
    fork(addItemToOrderFromCodeSaga),
    fork(addTransportToOrderSaga),
    fork(applyDiscountsToProductsSaga),
    fork(getOrdersByUserSaga),
    fork(getOrdersByDateSaga),
    fork(getOrderByIdSaga),
    fork(addProductsToCurrentSaga),
    fork(changeCurrentOrderSaga),
    fork(handelOrderRequestsSaga),
    fork(getRefundCodesSaga),
    fork(getCNTypesSaga),
    fork(getInvoicesSaga),
    fork(getInvoiceSaga),
    fork(createCNSaga),
  ]);
}
