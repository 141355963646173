import { all, call, fork, put, takeEvery, throttle, select } from 'redux-saga/effects';

import {
  SEARCH_CLIENTS,
  AUTOCOMPLETE_CLIENTS,
  AUTOCOMPLETE_MY_CLIENTS
} from '../constants/ActionsTypes';

import {
  searchClients,
  searchMyClients,
} from '../api/Clients';

import {
  searchClientsSuccess,
  autocompleteClientsSuccess,
  autocompleteMyClientsSuccess,
} from '../actions/Clients';

export const getCommonState = (state) => state.common;


function* searchClientsRequest({ payload }) {

  const params = yield select(getCommonState);
  const { AdminActionBar } = params;
    
  if(AdminActionBar.showRemove){
      payload.borrado = AdminActionBar.showRemove;
  }    

  try {
    const clients = yield call(searchClients, payload);
    yield put(searchClientsSuccess(clients));
  } catch (error) {
  }
}


function* autocompleteClientsRequest({ payload }) {
  const params = yield select(getCommonState);
  const { AdminActionBar } = params;

  const query = {
    'filtro': payload,
    'page': 1,
    'pageSize': 10
  }

  if(AdminActionBar.showRemove){
    query.borrado = AdminActionBar.showRemove;
  }  

  try {
    const clients = yield call(searchClients, query);
    yield put(autocompleteClientsSuccess(clients));
  } catch (error) {
  }
}

function* autocompleteMyClientsRequest({ payload }) {

  let filtro = payload;
  let include = null;
  if (payload instanceof Object) {
    filtro = payload.filtro
    include = payload.include
  }

  const query = {
    'filtro': filtro,
    'page': 1,
    'pageSize': 10,
    'include': include
  }

  try {
    const response = yield call(searchMyClients, query);
    yield put(autocompleteMyClientsSuccess(response));
  } catch (error) {
  }
}

export function* searchClientsSaga() {
  yield takeEvery(SEARCH_CLIENTS, searchClientsRequest);
}

export function* autocompleteClientsSaga() {
  yield throttle(1000, AUTOCOMPLETE_CLIENTS, autocompleteClientsRequest);
}

export function* autocompleteMyClientsSaga() {
  yield throttle(1000, AUTOCOMPLETE_MY_CLIENTS, autocompleteMyClientsRequest);
}


export default function* rootSaga() {
  yield all([
    fork(searchClientsSaga),
    fork(autocompleteClientsSaga),
    fork(autocompleteMyClientsSaga),
  ]);
}