import {
    AUTOCOMPLETE_CHARACTERISTICS_SUCCESS,
    AUTOCOMPLETE_CHARACTERISTICS,
    GET_CHARACTERISTIC_SUCCESS,
    GET_CHARACTERISTIC,
    SAVE_CHARACTERISTIC_SUCCESS,
    SAVE_CHARACTERISTIC,
    SEARCH_CHARACTERISTICS_SUCCESS,
    SEARCH_CHARACTERISTICS,
    UPDATE_CHARACTERISTIC_SUCCESS,
    CLEAR_CHARACTERISTIC_SUCCESS,
    UPDATE_CHARACTERISTIC,
} from '../constants/ActionsTypes';

export const searchCharacteristics = (params) => {
    return {
        type: SEARCH_CHARACTERISTICS,
        payload: params,
    };
};

export const searchCharacteristicsSuccess = (data) => {
    return {
        type: SEARCH_CHARACTERISTICS_SUCCESS,
        payload: data,
    };
};

export const autocompleteCharacteristics = (params) => {
    return {
        type: AUTOCOMPLETE_CHARACTERISTICS,
        payload: params,
    };
};

export const autocompleteCharacteristicsSuccess = (response) => {
    return {
        type: AUTOCOMPLETE_CHARACTERISTICS_SUCCESS,
        payload: response,
    };
};

export const getCharacteristic = (params) => {
    return {
        type: GET_CHARACTERISTIC,
        payload: params,
    };
};

export const getCharacteristicSuccess = (data) => {
    return {
        type: GET_CHARACTERISTIC_SUCCESS,
        payload: data,
    };
};

export const saveCharacteristic = (characteristic) => {
    return {
        type: SAVE_CHARACTERISTIC,
        payload: characteristic,
    };
};

export const saveCharacteristicSuccess = () => {
    return {
        type: SAVE_CHARACTERISTIC_SUCCESS,
    };
};

export const updateCharacteristic = (characteristic) => {
    return {
        type: UPDATE_CHARACTERISTIC,
        payload: characteristic,
    };
};

export const updateCharacteristicSuccess = () => {
    return {
        type: UPDATE_CHARACTERISTIC_SUCCESS,
    };
};

export const clearCharacteristicSuccess = () => {
    return {
        type: CLEAR_CHARACTERISTIC_SUCCESS,
    };
};
