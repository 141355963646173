import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  getUserConfigError,
  getUserConfigSuccess,
  setUserConfigError,
  setUserConfigSuccess,
  addProductToFavoritesSuccess,
  addProductToFavoritesError,
  addObservationToFavoriteSuccess,
  addObservationToFavoriteError,
  removeProductToFavoritesSuccess,
  removeProductToFavoritesError,
  getMyFavoriteProductsError,
  getMyFavoriteProductsSuccess,
} from '../actions'
import {
  GET_USER_CONFIGURATIONS,
  SET_USER_CONFIGURATIONS,
  ADD_PRODUCT_TO_FAVORITES,
  ADD_OBSERVATION_TO_FAVORITE,
  REMOVE_PRODUCT_TO_FAVORITES,
  GET_MY_FAVORITE_PRODUCTS,
} from '../constants/ActionsTypes'
import { getUserConfig, setUserConfig, getMyFavoriteProducts } from '../api/UserConfig'

function* getUserConfigRequest() {
  try {
    const { data } = yield call(getUserConfig)
    yield put(getUserConfigSuccess(data))
  } catch (err) {
    yield put(getUserConfigError(err))
  }
}

function* setUserConfigRequest({ payload }) {
  try {
    const { data } = yield call(setUserConfig, payload)
    yield put(setUserConfigSuccess(data))
  } catch (err) {
    yield put(setUserConfigError(err))
  }
}

function* addProductToFavoritesRequest({ payload }) {
  try {
    const { data } = yield call(setUserConfig, payload)
    yield put(addProductToFavoritesSuccess(data))
  } catch (err) {
    yield put(addProductToFavoritesError(err))
  }
}

function* addObservationToFavoriteRequest({ payload }) {
  const { favoriteProductId, observation } = payload

  try {
    const { data } = yield call(setUserConfig, { favoriteProductId, observation })
    yield put(addObservationToFavoriteSuccess(data))
  } catch (err) {
    yield put(addObservationToFavoriteError(err))
  }
}

function* removeProductToFavoritesRequest({ payload }) {
  try {
    const { data } = yield call(setUserConfig, payload)
    yield put(removeProductToFavoritesSuccess(data))
  } catch (err) {
    yield put(removeProductToFavoritesError(err))
  }
}

function* getMyFavoriteProductsRequest({ payload }) {
  try {
    const data = yield call(getMyFavoriteProducts, payload)
    yield put(getMyFavoriteProductsSuccess(data))
  } catch (err) {
    yield put(getMyFavoriteProductsError(err))
  }
}

export function* getUserConfigSaga() {
  yield takeEvery(GET_USER_CONFIGURATIONS, getUserConfigRequest)
}

export function* setUserConfigSaga() {
  yield takeEvery(SET_USER_CONFIGURATIONS, setUserConfigRequest)
}

export function* addProductToFavoritesSaga() {
  yield takeEvery(ADD_PRODUCT_TO_FAVORITES, addProductToFavoritesRequest)
}

export function* addObservationToFavoriteSaga() {
  yield takeEvery(ADD_OBSERVATION_TO_FAVORITE, addObservationToFavoriteRequest)
}

export function* removeProductToFavoritesSaga() {
  yield takeEvery(REMOVE_PRODUCT_TO_FAVORITES, removeProductToFavoritesRequest)
}

export function* getMyFavoriteProductsSaga() {
  yield takeEvery(GET_MY_FAVORITE_PRODUCTS, getMyFavoriteProductsRequest)
}

export default function* rootSaga() {
  yield all([
    fork(getUserConfigSaga),
    fork(setUserConfigSaga),
    fork(addProductToFavoritesSaga),
    fork(removeProductToFavoritesSaga),
    fork(getMyFavoriteProductsSaga),
    fork(addObservationToFavoriteSaga),
  ])
}
