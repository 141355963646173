import {
    AUTOCOMPLETE_SUB_GROUPS_SUCCESS,
    AUTOCOMPLETE_SUB_GROUPS,
    GET_SUB_GROUP_BY_CODE,
    GET_SUB_GROUP,
    SEARCH_SUB_GROUPS_SUCCESS,
    SEARCH_SUB_GROUPS,
} from '../constants/ActionsTypes';

export const getSubGroup = (subGroupId) => {
    return {
        type: GET_SUB_GROUP,
        payload: { subGroupId },
    };
};

export const getSubGroupByCode = (code, token) => {
    return {
        type: GET_SUB_GROUP_BY_CODE,
        payload: { code, token },
    };
};

export const searchSubGroups = (params) => {
    return {
        type: SEARCH_SUB_GROUPS,
        payload: params,
    };
};

export const searchSubGroupsSuccess = (data) => {
    return {
        type: SEARCH_SUB_GROUPS_SUCCESS,
        payload: data,
    };
};

export const autocompleteSubGroups = (params) => {
    return {
        type: AUTOCOMPLETE_SUB_GROUPS,
        payload: params,
    };
};

export const autocompleteSubGroupsSuccess = (data) => {
    return {
        type: AUTOCOMPLETE_SUB_GROUPS_SUCCESS,
        payload: data,
    };
};
