import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_SEGMENT,
    GET_SEGMENTS,
    GET_ACTIVE_SEGMENTS,
    GET_SEGMENT_SUCCESS,
    GET_SEGMENT_ERROR,
    GET_SEGMENTS_SUCCESS,
    GET_SEGMENTS_ERROR,
    SEARCH_SEGMENTS,
    ADD_UPDATE_SEGMENT,
    AUTOCOMPLETE_SEGMENTS,
    GET_BRANDS_BY_SEGMENTS,
    GET_BRANDS_BY_SEGMENTS_ERROR,
    GET_BRANDS_BY_SEGMENTS_SUCCESS,
} from '../constants/ActionsTypes';

import {
    getSegment,
    getSegments,
    getActiveSegments,
    getBrandsBySegments,
    getBrandsByUsersSegments,
    searchSegments,
    addSegment
} from '../api/Segments';

import {
    searchSegmentsSuccess,
    addSegmentSuccess,
    autocompleteSegmentsSuccess
} from '../actions/Segments';

export const getCommonState = (state) => state.common;


function* callGetSegments({ payload }) {
    try {
        const response = yield call(getSegments);
        yield put({ type: GET_SEGMENTS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_SEGMENTS_ERROR, error });
    }
}

function* callGetActiveSegments({ payload }) {
    try {
        const response = yield call(getActiveSegments);
        yield put({ type: GET_SEGMENTS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_SEGMENTS_ERROR, error });
    }
}


function* callGetSegment({ payload }) {
    const { segmentId } = payload;
    try {
        const response = yield call(getSegment, segmentId);
        yield put({ type: GET_SEGMENT_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_SEGMENT_ERROR, error });
    }
}

function* callGetBrandsBySegmentsSaga({ payload }) {
    const { segmentIds } = payload;
    try {
        //Check if Paylod has one ore more selected Segment Ids, otherwise pick them up from the User.
        if (segmentIds && segmentIds.length > 0) {
            const response = yield call(getBrandsBySegments, segmentIds);
            yield put({ type: GET_BRANDS_BY_SEGMENTS_SUCCESS, data: response.response.data });
        } else {
            //We get the SegmentIds from the user.
            const response = yield call(getBrandsByUsersSegments);
            yield put({ type: GET_BRANDS_BY_SEGMENTS_SUCCESS, data: response.response.data });
        }
    } catch (error) {
        yield put({ type: GET_BRANDS_BY_SEGMENTS_ERROR, error });
    }
}


function* searchSegmentsRequest({payload}) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if(showRemove){
        payload = { ...payload, borrado: showRemove };
    }

    try {
        const segments = yield call(searchSegments, payload);
        yield put(searchSegmentsSuccess(segments));
    } catch (error) {
    }
}

function* addSegmentsRequest({payload}) {
    try {
        const segments = yield call(addSegment, payload.params);
        yield put(addSegmentSuccess(segments));
    } catch (error) {
        yield put({ type: GET_SEGMENT_ERROR, error });

    }
}

function* autocompleteSegmentsRequest({payload}) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(showRemove){
        query.borrado = showRemove;
    }

    try {
        const transports = yield call(searchSegments, query);
        yield put(autocompleteSegmentsSuccess(transports));
    } catch (error) {
    }
}

export function* searchSegmentsSaga() {
    yield takeEvery(SEARCH_SEGMENTS, searchSegmentsRequest);
}

export function* addSegmentSaga() {
    yield takeEvery(ADD_UPDATE_SEGMENT, addSegmentsRequest);
}

export function* getSegmentsSaga() {
    yield takeLatest(GET_SEGMENTS, callGetSegments);
}

export function* getActiveSegmentsSaga() {
    yield takeLatest(GET_ACTIVE_SEGMENTS, callGetActiveSegments);
}

export function* getSegmentSaga() {
    yield takeLatest(GET_SEGMENT, callGetSegment);
}

export function* getBrandsBySegmentsSaga() {
    yield takeLatest(GET_BRANDS_BY_SEGMENTS, callGetBrandsBySegmentsSaga);
}

export function* autocompleteSegmentsSaga() {
    yield throttle(1000, AUTOCOMPLETE_SEGMENTS, autocompleteSegmentsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getSegmentSaga),
        fork(getSegmentsSaga),
        fork(getActiveSegmentsSaga),
        fork(getBrandsBySegmentsSaga),
        fork(searchSegmentsSaga),
        fork(autocompleteSegmentsSaga),
        fork(addSegmentSaga),
    ]);
}
