import { isElectron } from './tools'

export const electronLogin = () => {

  if (isElectron()){

    const info = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId')
    }

    if (window.require){
      const { ipcRenderer } = window.require('electron')
      ipcRenderer.send('user-login', info)
    }
  }

}

export const electronLogout = () => {
  if (isElectron()){
    if (window.require){
      const { ipcRenderer } = window.require('electron')
      ipcRenderer.send('user-logout')
    }
  }

}
