import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    GET_CHARTS_INFO, GET_DETAIL_SELLE_INFO
} from '../constants/ActionsTypes';

import { getChartsData, getDetailInfoBySeller } from '../api/Dashboard';

import {
    getChartsDataError,
    getChartsDataSuccess,
    getDetailInfoBySellerError,
    getDetailInfoBySellerSuccess
} from "../actions";

function* getChartInfoRequest({payload}) {
    try {
        const { from, to } = payload;
        const response = yield call(getChartsData, from, to);
        yield put(getChartsDataSuccess(response))
    }
    catch (err) {
        yield put(getChartsDataError(err));
    }
}

function* getDetailInfoBySellerRequest({payload}) {
    try {
        const { sellerId, from, to, pagination } = payload;
        const response = yield call(getDetailInfoBySeller, sellerId, from, to, pagination);
        yield put(getDetailInfoBySellerSuccess(response))
    }
    catch (err) {
        yield put(getDetailInfoBySellerError(err));
    }
}

export function* getChartInfoSaga() {
    yield takeEvery(GET_CHARTS_INFO, getChartInfoRequest);
}

export function* getDetailInfoBySellerSaga() {
    yield takeEvery(GET_DETAIL_SELLE_INFO, getDetailInfoBySellerRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getChartInfoSaga),
        fork(getDetailInfoBySellerSaga)
    ]);
}
