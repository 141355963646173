import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import getConfig from "next/config";
const { publicRuntimeConfig: { cooldownForCheckFavoritesChanges } } = getConfig();

import {
    DOWNLOAD_PRICES_LIST,
    GET_LINEAS_CATALOGOS,
    GET_ONLINE_ORDER_TO_MERGE,
    GET_ONLINE_QUOTATION_TO_MERGE,
    GET_NEWS_FOR_FAVORITES
} from '../constants/ActionsTypes';

import {
    getLineasCatalogosSuccess,
    getOnlineOrderToMergeSuccess,
    getOnlineQuotationToMergeSuccess,
    getNewsForFavoritesSuccess,
    downloadPricesListSuccess,
    downloadPricesListError
} from '../actions';


import {
    downloadPricesList,
    getLineasCatalogos
} from '../api/Catalog';

import {
    getNewsForFavorites
} from '../api/UserConfig';

import {
    getOrderById,
} from '../api/Orders';

import {
    getQuotationById
} from '../api/Quotation';

function* downloadPricesListRequest({ payload: { filter, format } }) {
    try {
        yield call(downloadPricesList, { filter, format });
        yield put(downloadPricesListSuccess());
    } catch (error) {
        yield put(downloadPricesListError(error));
    }
}

function* getLineasCatalogosRequest() {
    try {
        const lineas = yield call(getLineasCatalogos);
        yield put(getLineasCatalogosSuccess(lineas));
    } catch (error) {
    }
}

function* getOnlineOrderToMergeRequest({ payload}) {
    try {
        const params = { orderId: payload }
        const response = yield call(getOrderById, params);
        const { data } = response;
        const order = data.response.data
        yield put(getOnlineOrderToMergeSuccess(order));
    } catch (error) {
    }
}

function* getOnlineQuotationToMergeRequest({ payload }) {
    try {
        const params = { quotationId: payload }
        const response = yield call(getQuotationById, params);
        const { data } = response;
        const quotation = data.data
        yield put(getOnlineQuotationToMergeSuccess(quotation));
    } catch (error) {
    }
}

const setLastRequestTimestamp = (timestamp) => {
    localStorage.setItem('lastRequestTimestamp', timestamp);
  };

const getLastRequestTimestamp = () => {
return localStorage.getItem('lastRequestTimestamp');
};

function* getNewsForFavoritesRequest() {
  // Check if has been more than 5 minutes from the last request
  const shouldFetchData = () => {
    const lastRequestTimestamp = getLastRequestTimestamp();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastRequestTimestamp;
    const Minutes = cooldownForCheckFavoritesChanges * 60 * 1000; // minuts in miliseconds
    return !lastRequestTimestamp || timeDifference > Minutes;
  };

  try {
    const shouldFetch = yield call(shouldFetchData);
    if (shouldFetch) {
      const response = yield call(getNewsForFavorites);

      if (response) {
        if (response.data.existDifferences === false) {
          // Update the timestamp in localStorage
          const timestamp = new Date().getTime();
          yield call(setLastRequestTimestamp, timestamp);
        } else {
          yield put(getNewsForFavoritesSuccess(response.data));
        }
      }
    }
  } catch (error) {}
}

// Watchers
export function* getDownloadPricesListSaga() {
    yield takeLatest(DOWNLOAD_PRICES_LIST, downloadPricesListRequest);
}

export function* getLineasCatalogosSaga() {
    yield takeLatest(GET_LINEAS_CATALOGOS, getLineasCatalogosRequest);
}

export function* getOnlineOrderToMergeSaga() {
    yield takeLatest(GET_ONLINE_ORDER_TO_MERGE, getOnlineOrderToMergeRequest);
}

export function* getOnlineQuotationToMergeSaga() {
    yield takeLatest(GET_ONLINE_QUOTATION_TO_MERGE, getOnlineQuotationToMergeRequest);
}

export function* getNewsForFavoritesSaga() {
    yield takeLatest(GET_NEWS_FOR_FAVORITES, getNewsForFavoritesRequest);
}


export default function* rootSaga() {
    yield all([
        fork(getDownloadPricesListSaga),
        fork(getLineasCatalogosSaga),
        fork(getOnlineOrderToMergeSaga),
        fork(getOnlineQuotationToMergeSaga),
        fork(getNewsForFavoritesSaga)
    ]);
}