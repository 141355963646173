import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SAVE_FILE_APPLICATIONS, EXPORT_APPLICATIONS } from '../constants/ActionsTypes';
import { saveApplications, exportApplications } from '../api/Applications';

import {
    saveApplicationsSuccess,
    saveApplicationsError,
    exportApplicationsSuccess,
    exportApplicationsError
} from "../actions";

function* saveApplicationsRequest({payload}) {
    try {
        yield call(saveApplications, payload);
        yield put(saveApplicationsSuccess())
    }
    catch (err) {
        yield put(saveApplicationsError())
    }
}

function* exportApplicationsRequest({payload}) {
    try {
        yield call(exportApplications, payload);
        yield put(exportApplicationsSuccess())
    }
    catch (err) {
        yield put(exportApplicationsError())
    }
}

export function* saveApplicationsSaga() {
    yield takeEvery(SAVE_FILE_APPLICATIONS, saveApplicationsRequest);
}

export function* exportApplicationsSaga() {
    yield takeEvery(EXPORT_APPLICATIONS, exportApplicationsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(saveApplicationsSaga),
        fork(exportApplicationsSaga)
    ]);
}
