import Axios from 'axios';
import moment from 'moment-business-days';
const FileSaver = require('file-saver');

const BASE_URL_END_POINT = '/productos';

export const saveApplications = async (file) => {
    const formData = new FormData();
    formData.append('applications', file, file.name);
    const response = await Axios.post(`${BASE_URL_END_POINT}/updateApplicationFromFile`, formData);
    return response.data;
};

export const exportApplications = async (params) => {
    const response = await Axios.post(`${BASE_URL_END_POINT}/exportApplications`, params, {
        responseType: 'arraybuffer',
    });
    if (response && response.data) {
        const defaultName = `Aplicaciones-${moment().format('YYYY-MM-DD')}.xlsx`,
            serverName = response.headers['content-disposition'].split('filename=')[1];
        const fileName = serverName ? serverName : defaultName;
        FileSaver.saveAs(new Blob([response.data]), fileName, { autoBOM: true });
    }
};
