import {
    GO_OFFLINE,
    GO_ONLINE,
    SET_MERGE_ORDERS,
    SHOW_OFFLINE_MERGE,
} from '../constants/ActionsTypes';

export const goOffline = () => {
    return {
        type: GO_OFFLINE,
    };
};

export const goOnline = () => {
    return {
        type: GO_ONLINE,
    };
};

export const showOfflineMerge = () => {
    return {
        type: SHOW_OFFLINE_MERGE,
        payload: true,
    };
};

export const hideOfflineMerge = () => {
    return {
        type: SHOW_OFFLINE_MERGE,
        payload: false,
    };
};

export const setMergeOrders = (data) => {
    return {
        type: SET_MERGE_ORDERS,
        payload: data,
    };
};
